import { toBlobPolyfill } from './polyfill.js';
toBlobPolyfill();

import { Container } from '../Container.js';
import { Point, Vector2d } from '@repo/drawing';

export * from './InputEventHandlers.js';
export * from './InputEventNormalizer.js';
export * from './NormalizedInputEventHandler.js';

export interface EventModifiers {
  altKey: boolean;
  ctrlKey: boolean;
  metaKey: boolean;
  shiftKey: boolean;
}

export type TouchPoint = {
  point: Point;
};

export type TouchPoints = {
  touches: Array<Touch & TouchPoint>;
  changedTouches: Array<Touch & TouchPoint>;
  // targetTouches: Array<TouchPoint>,
};

export type PositionEventData = {
  point: Point;
};

export type DragEventData = {
  point: Point;
  delta: Vector2d;
};

export type ZoomEventData = {
  delta: Vector2d;
  distance: number;
  scale: number;
  center: Point;
};

export type CursorEvent = (TouchEvent | MouseEvent) & UIEvent & Event;

export type CursorDragEvent = (TouchEvent | MouseEvent) & UIEvent;

export type CursorZoomEvent = (TouchEvent | MouseEvent) & UIEvent;

/**
 * Async handler which indicates a need to redraw upon completion, defaults to true
 */

export type DragHandler = (
  event: DragEvent,
  ext: PositionEventData & EventModifiers,
) => Promise<boolean | void>;

export type TouchHandler = (
  event: TouchEvent,
  ext: TouchPoints & EventModifiers,
) => Promise<boolean | void>;

export type MouseHandler = (
  event: MouseEvent,
  ext: PositionEventData & EventModifiers,
) => Promise<boolean | void>;

export type WheelHandler = (
  event: WheelEvent,
  ext: PositionEventData & EventModifiers,
) => Promise<boolean | void>;

export type FocusHandler = (event: FocusEvent) => Promise<boolean | void>;

export type KeyboardHandler = (
  event: KeyboardEvent,
  ext: EventModifiers,
) => Promise<boolean | void>;

// Synthetic event handlers

export type CursorHandler = (
  event: CursorEvent,
  ext: PositionEventData & EventModifiers,
) => Promise<boolean | void>;

export type CursorDragHandler = (
  event: CursorDragEvent,
  ext: DragEventData & EventModifiers,
) => Promise<boolean | void>;

export type CursorZoomHandler = (
  event: CursorZoomEvent,
  ext: ZoomEventData & EventModifiers,
) => Promise<boolean | void>;

// Render related types

export type RendererCallback = (
  container: Container,
  ctx: CanvasRenderingContext2D,
) => Promise<void>;

export const NOOP_RENDERER_CALLBACK: RendererCallback = async (
  _c: Container,
  _ctx: CanvasRenderingContext2D,
) => {
  /* noop */
};

/**
 * Extracts the color data from the pixel at the specified x and y coordinates.
 *
 * @param x Horizontal position of pixel to check
 * @param y Vertical position of pixel to check
 * @param target Canvas element to observe
 * @returns [r, g, b, a] number values
 */
// public getColorIndicesForCoord = (x: number, y: number, target: HTMLCanvasElement): [number, number, number, number] => {
//     const red = y * (target.width * 4) + x * 4;
//     return [red, red + 1, red + 2, red + 3];
// };

export const orientations = [
  'portrait',
  'landscape',
  'portrait-flip',
  'landscape-flip',
] as const;

export type Orientation = (typeof orientations)[number];

export function getOrientation(value?: string | null): Orientation | undefined {
  if (!value) return undefined;
  if (orientations.includes(value as Orientation)) {
    return value as Orientation;
  }
  return undefined;
}

/**
 * Partial signature of the global window object to restrict surface to only
 * needed properties
 */
export interface WindowRef {
  innerWidth: number;
  innerHeight: number;
  get devicePixelRatio(): number;
  onresize: ((this: any, ev: any) => any) | null;
  getComputedStyle: (target: any) => any;
  performance: { now(): number };
  requestAnimationFrame(cb: FrameRequestCallback): number;
  document: {
    hidden: boolean;
    addEventListener(
      type: 'visibilitychange',
      listener: (this: Document, ev: Event) => any,
      options?: boolean | AddEventListenerOptions,
    ): void;
    removeEventListener(
      type: 'visibilitychange',
      listener: (this: Document, ev: Event) => any,
      options?: boolean | AddEventListenerOptions,
    ): void;
  };
  screen?: {
    orientation?: {
      type: OrientationType;
      angle: number;
      addEventListener(
        type: 'change',
        listener: (this: ScreenOrientation, ev: Event) => any,
        options?: boolean | AddEventListenerOptions,
      ): void;
      removeEventListener(
        type: 'change',
        listener: (this: ScreenOrientation, ev: Event) => any,
        options?: boolean | AddEventListenerOptions,
      ): void;
      lock(orientation: OrientationType): Promise<void>;
      unlock(): void;
    };
  };
  addEventListener(
    type: 'resize',
    listener: (this: Window, ev: UIEvent) => any,
    options?: boolean | AddEventListenerOptions,
  ): void;
  removeEventListener(
    type: 'resize',
    listener: (this: Window, ev: UIEvent) => any,
    options?: boolean | AddEventListenerOptions,
  ): void;
  // ontouchend:
  //   | (((this: GlobalEventHandlers, ev: TouchEvent) => any) &
  //       ((this: Window, ev: TouchEvent) => any))
  //   | null
  //   | undefined;
  // addEventListener(
  //   type: 'deviceorientation',
  //   listener: (this: Window, ev: DeviceOrientationEvent) => any,
  //   options?: boolean | AddEventListenerOptions,
  // ): void;

  // // Add any additional properties you need, but make touch events optional
  // ontouchstart?: ((this: GlobalEventHandlers, ev: TouchEvent) => any) | null;
  // ontouchend?: ((this: GlobalEventHandlers, ev: TouchEvent) => any) | null;
  // ontouchmove?: ((this: GlobalEventHandlers, ev: TouchEvent) => any) | null;
  // ontouchcancel?: ((this: GlobalEventHandlers, ev: TouchEvent) => any) | null;
}

function scaleToMaxCanvas(
  size: { width: number; height: number },
  maximumPixels: number,
) {
  const { width, height } = size;

  const requiredPixels = width * height;
  if (requiredPixels <= maximumPixels) return { width, height };

  const scalar = Math.sqrt(maximumPixels) / Math.sqrt(requiredPixels);
  return {
    width: Math.floor(width * scalar),
    height: Math.floor(height * scalar),
  };
}

// export async function orient(windowRef: WindowRef) {
//   if (true) return;

//   windowRef.ontouchend = async (ev) => {
//     // DeviceMotionEvent
//     if (
//       typeof DeviceOrientationEvent != 'undefined' &&
//       typeof (DeviceOrientationEvent as any).requestPermission === 'function'
//     ) {
//       // IOs 13+
//       try {
//         const permission = await (
//           DeviceOrientationEvent as any
//         ).requestPermission();
//         console.log('[DeviceOrientation] Permission:', permission);
//         if (permission === 'granted') {
//           // window.addEventListener('deviceorientation', (e) => console.log('[DeviceOrientation] From IOs 13+', e));
//         }
//       } catch (err) {
//         console.error(err);
//       }
//     } else if ('DeviceOrientationEvent' in window) {
//       // Not ios 13+
//       // window.addEventListener('deviceorientation', (e) => console.log('[DeviceOrientation] From Window:', e));
//       windowRef.addEventListener('deviceorientation', (e) =>
//         console.log('[DeviceOrientation] From Window:', e),
//       );
//     } else {
//       console.error('[DeviceOrientation] Not Supported');
//     }
//   };
// }

// export function arrayBufferToHex(buffer: ArrayBuffer): string {
//     const byteArray = new Uint8Array(buffer);
//     let hexString = '';
//     for (const byte of byteArray) {
//         hexString += byte.toString(16).padStart(2, '0');
//     }
//     return hexString;
// }

// export async function sha256HashFromByteArray(byteArray: Uint8Array): Promise<string> {
//     const buffer: ArrayBuffer = await crypto.subtle.digest('SHA-256', byteArray);
//     return arrayBufferToHex(buffer);
// }

// export function formatBytes(bytes: number): string {
//     const units = ['B', 'KB', 'MB', 'GB', 'TB'];
//     let size = bytes;
//     let unitIndex = 0;

//     while (size >= 1024 && unitIndex < units.length - 1) {
//       size /= 1024;
//       unitIndex++;
//     }

//     return `${size.toFixed(2)} ${units[unitIndex]}`;
// }

// export async function fetchImageAsArrayBuffer(imageUrl: string): Promise<ArrayBuffer> {
//     const response = await fetch(imageUrl);
//     if (!response.ok) {
//         throw new Error(`Failed to fetch image: ${response.status}`);
//     }
//     return await response.arrayBuffer();
// }

// export async function imageFromBuffer(
//     buffer: ArrayBuffer,
//     type: string,
// ): Promise<HTMLImageElement> {
//     // Create a blob from the buffer
//     const blob = new Blob([buffer], { type });

//     // Create an image element
//     const img = document.createElement('img') as HTMLImageElement;
//     const blobUrl = URL.createObjectURL(blob);
//     try {
//         // Load the image
//         await new Promise<void>((resolve, reject) => {
//             img.onload = (e) => {
//                 resolve();
//             },
//             img.onerror = reject;
//             img.src = blobUrl;
//         });
//     } catch(ex) {
//         throw ex;
//     } finally {
//         URL.revokeObjectURL(blobUrl);
//     }
//     return img;
// }

// TODO(pbirch): this should be detected... which requires basically scanning :(
// const MAX_CANVAS_DIMENSIONS = 16777216;

// /**
//  * [Option for finding Canvas Size](https://jhildenbiddle.github.io/canvas-size/#/)
//  * @param buffer
//  * @param type
//  * @param compressionQuality
//  * @returns
//  */
// export async function compressImage(
//     buffer: ArrayBuffer,
//     type: string,
//     compressionQuality: number,
// ): Promise<{ img: HTMLImageElement, blob: Blob } | undefined> {
//     try {
//         const img = await imageFromBuffer(buffer, type);

//         // Create a canvas and compress
//         const canvas = document.createElement('canvas');
//         canvas.width = img.width;
//         canvas.height = img.height;

//         const ctx = canvas.getContext('2d');
//         if (!ctx) throw new Error('Could not get canvas context');

//         console.log(`[Cache] compressImage: ${img.width}x${img.height}`);

//         try { // Try to draw the image on the canvas but device canvas size max is hard to predict
//             ctx.drawImage(img, 0, 0, img.width, img.height);
//         } catch(ex) {
//             if (img.width * img.height > MAX_CANVAS_DIMENSIONS) {
//                 return undefined;
//             }
//             console.error('[CompressImage]', ex);
//         }

//         // Convert to compressed blob
//         const blob = await new Promise<Blob>((resolve) => {
//             canvas.toBlob(
//                 (blob) => resolve(blob!),
//                 type,
//                 compressionQuality
//             );
//         });
//         // compressedBlob.size
//         // compressedBlob.type

//         // Convert blob to array buffer
//         // const compressedBuffer = await compressedBlob.arrayBuffer();

//         return {
//             img,
//             blob,
//             // compressedSize: compressedBuffer.byteLength
//         };
//     } catch(ex) {
//         throw new Error(`[Cache] compressImage: ${ex}`);
//     }
// }
