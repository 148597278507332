import type { Point } from './point.js';

export interface EventModifiers {
  altKey: boolean;
  ctrlKey: boolean;
  metaKey: boolean;
  shiftKey: boolean;
}

// Handler type definitions
export type MouseHandler = (
  event: {
    point: Point;
    button: number;
    buttons: number;
  } & EventModifiers,
) => void;

export type WheelHandler = (
  event: {
    point: Point;
    deltaX: number;
    deltaY: number;
    deltaZ: number;
    deltaMode: number;
  } & EventModifiers,
) => void;

export type TouchHandler = (event: {
  touches: Array<{
    identifier: number;
    point: Point;
  }>;
  changedTouches: Array<{
    identifier: number;
    point: Point;
  }>;
}) => void;

export type DragHandler = (
  event: {
    point: Point;
    dataTransfer: DataTransfer | null;
  } & EventModifiers,
) => void;

export type FocusHandler = (event: {
  relatedTarget: EventTarget | null;
}) => void;

export type KeyboardHandler = (
  event: {
    key: string;
    code: string;
    repeat: boolean;
  } & EventModifiers,
) => void;

/**
 * Defines a type as something that can draw on a surface
 */
export interface IRenderable {
  /**
   * Performs a set of canvas draw operations on the provided context 2d
   *
   * @param ctx Canvas context to rneder this object onto
   */
  renderTo(ctx: CanvasRenderingContext2D): void;
}

export function rgbToHex(r: number, g: number, b: number) {
  function componentToHex(c: number) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function radiansToDegrees(radians: number) {
  return (radians * 180) / Math.PI;
}

export function degreesToRadians(degrees: number) {
  return (Math.PI * degrees) / 180;
}

export function setTransform(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  sx: number,
  sy: number,
  rotate: number,
) {
  const xdx = Math.cos(rotate); // create the x axis
  const xdy = Math.sin(rotate);
  ctx.setTransform(xdx * sx, xdy * sx, -xdy * sy, xdx * sy, x, y);
}

// export function canvasToJpgArrayBuffer2(
//   canvas: HTMLCanvasElement,
// ): Promise<ArrayBuffer> {
//   return new Promise<ArrayBuffer>((resolve, reject) => {
//     canvas.toBlob((blob) => {
//       if (blob) {
//         blob
//           .arrayBuffer()
//           .then(resolve)
//           .catch(() =>
//             reject(new Error('Failed to convert blob to ArrayBuffer')),
//           );
//       } else {
//         reject(new Error('Failed to create blob from canvas'));
//       }
//     }, 'image/jpeg');
//   });
// }

// export function canvasToJpgArrayBuffer(
//     canvas: HTMLCanvasElement,
// ): Promise<ArrayBuffer> {
//     return new Promise<ArrayBuffer>((resolve, reject) => {
//         const cb: BlobCallback = (blob: Blob | null) => {
//             if (blob) {
//                 const reader = new FileReader();
//                 reader.onload = () => {
//                     resolve(reader.result as ArrayBuffer);
//                 };
//                 reader.onerror = () => {
//                     reject(new Error('Failed to convert blob to ArrayBuffer'));
//                 };
//                 reader.readAsArrayBuffer(blob);
//             } else {
//                 reject(new Error('Failed to create blob from canvas'));
//             }
//         };
//         canvas.toBlob(cb, 'image/jpeg');
//     });
// }

// export async function getCanvasDataAsWebP(canvas: HTMLCanvasElement): Promise<ArrayBuffer> {
//     // Get the annotated base64 data for webp
//     const base64Data = canvas.toDataURL('image/webp');
//     // Remove the data URL prefix
//     const base64_string = base64Data.replace(/^data:image\/webp;base64,/, '');
//     return decodeBase64IntoArrayBuffer(base64_string);
// }

// var uintArray = Base64Binary.decode(base64_string);
// var byteArray = Base64Binary.decodeArrayBuffer(base64_string);

// Convert base64 to ArrayBuffer
// const binaryString = atob(base64);
// const len = binaryString.length;
// const bytes = new Uint8Array(len);
// for (let i = 0; i < len; i++) {
//     bytes[i] = binaryString.charCodeAt(i);
// }

// return bytes.buffer;
