// Add type augmentation for Performance
declare global {
  interface Performance {
    memory?: PerformanceMemory;
  }
}

export interface PerformanceMemory {
  jsHeapSizeLimit: number;
  totalJSHeapSize: number;
  usedJSHeapSize: number;
}

const getPerformance = async () => {
  if (typeof window !== 'undefined') {
    return window.performance;
  } else {
    const { performance } = await import('node:perf_hooks');
    return performance;
  }
};

export interface PerformanceMetrics {
  fps: number;
  frameTime: number;
  memoryUsage?: {
    usedJSHeapSize: number;
    totalJSHeapSize: number;
  };
  operations: Map<string, OperationMetrics>;
}

interface OperationMetrics {
  count: number;
  totalTime: number;
  averageTime: number;
  lastTime: number;
}

export class PerformanceMonitor {
  static #instance: PerformanceMonitor;
  #metrics: PerformanceMetrics;
  #frameCount: number = 0;
  #lastFrameTime: number = 0;
  #frameInterval: number = 1000; // 1 second
  #performance: any | undefined;

  private constructor() {
    this.#metrics = {
      fps: 0,
      frameTime: 0,
      memoryUsage: undefined,
      operations: new Map(),
    };
    this.#startMonitoring();
  }

  static getInstance(): PerformanceMonitor {
    if (!PerformanceMonitor.#instance) {
      PerformanceMonitor.#instance = new PerformanceMonitor();
    }
    return PerformanceMonitor.#instance;
  }

  #startMonitoring() {
    // Check if memory API is available
    getPerformance().then((performance) => {
      this.#performance = performance;
      // Only track memory if the API is available
      if (
        this.#performance.memory &&
        this.#performance.memory.usedJSHeapSize &&
        this.#performance.memory.totalJSHeapSize
      ) {
        this.#metrics.memoryUsage = {
          usedJSHeapSize: this.#performance.memory.usedJSHeapSize,
          totalJSHeapSize: this.#performance.memory.totalJSHeapSize,
        };
      }

      this.#lastFrameTime = performance.now();
    });
  }

  updateFrame() {
    if (!this.#performance === undefined) return;
    const currentTime = performance.now();
    this.#frameCount++;

    if (currentTime - this.#lastFrameTime >= this.#frameInterval) {
      this.#metrics.fps = Math.round(
        (this.#frameCount * 1000) / (currentTime - this.#lastFrameTime),
      );
      this.#frameCount = 0;
      this.#lastFrameTime = currentTime;

      if (performance.memory) {
        this.#metrics.memoryUsage = {
          usedJSHeapSize: performance.memory.usedJSHeapSize,
          totalJSHeapSize: performance.memory.totalJSHeapSize,
        };
      }
    }
  }

  async measureOperation<T>(
    name: string,
    operation: () => Promise<T>,
  ): Promise<T> {
    const start = performance.now();
    try {
      return await operation();
    } finally {
      const duration = performance.now() - start;
      this.recordOperation(name, duration);
    }
  }

  private recordOperation(name: string, duration: number) {
    const existing = this.#metrics.operations.get(name) || {
      count: 0,
      totalTime: 0,
      averageTime: 0,
      lastTime: 0,
    };

    existing.count++;
    existing.totalTime += duration;
    existing.averageTime = existing.totalTime / existing.count;
    existing.lastTime = duration;

    this.#metrics.operations.set(name, existing);
  }

  // Add method to check if memory monitoring is available
  public isMemoryMonitoringAvailable(): boolean {
    // return this.#hasMemoryAPI;
    return this.#performance !== undefined;
  }

  // Add method to get memory metrics directly
  public getMemoryMetrics(): PerformanceMetrics['memoryUsage'] | undefined {
    if (this.#performance === undefined) return;
    return this.#metrics.memoryUsage;
  }

  getMetrics(): PerformanceMetrics {
    return { ...this.#metrics };
  }
}
