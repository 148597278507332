import { Point, Rect, Vector2d } from '@repo/drawing';
import { InputEventHandlers, NormalizedInputEventHandlers } from './index.js';
import { EventModifiers, DragEventData, ZoomEventData } from './index.js';

export class InputEventNormalizer {
  #bandSize: Rect;
  #inputEventHandlers: InputEventHandlers;
  #normalizedEventHandlers: NormalizedInputEventHandlers;

  #isDragging: boolean = false;
  #lastTouchDelta: Vector2d = new Vector2d(0, 0);
  #lastTouchDistance: number = 0;
  #lastTouch: Point = new Point(0, 0);

  // Constants for better configuration
  static readonly WHEEL_JUMP_DISTANCE = 100;
  static readonly ZOOM_SCALE_FACTOR = 0.1; // 10% zoom per wheel tick

  constructor(params: {
    bandSize: Rect;
    normalizedEventHandlers: NormalizedInputEventHandlers;
  }) {
    this.#bandSize = params.bandSize;
    this.#normalizedEventHandlers = params.normalizedEventHandlers;
    this.#inputEventHandlers = this.#buildInputEventHandlers();
  }

  get isDragging(): boolean {
    return this.#isDragging;
  }

  set isDragging(value: boolean) {
    this.#isDragging = value;
  }

  get lastTouchDelta(): Vector2d {
    return this.#lastTouchDelta;
  }

  set lastTouchDelta(value: Vector2d) {
    this.#lastTouchDelta = value;
  }

  get lastTouchDistance(): number {
    return this.#lastTouchDistance;
  }

  set lastTouchDistance(value: number) {
    this.#lastTouchDistance = value;
  }

  get lastTouch(): Point {
    return this.#lastTouch;
  }

  set lastTouch(value: Point) {
    this.#lastTouch = value;
  }

  get inputEventHandlers(): InputEventHandlers {
    return this.#inputEventHandlers;
  }

  get normalizedHandlers(): NormalizedInputEventHandlers {
    return this.#normalizedEventHandlers;
  }

  #calculateZoomScale(deltaY: number): number {
    return (
      1 +
      (deltaY < 0
        ? InputEventNormalizer.ZOOM_SCALE_FACTOR
        : -InputEventNormalizer.ZOOM_SCALE_FACTOR)
    );
  }

  #calculateTouchZoomCenter(touch1: Point, touch2: Point): Point {
    return new Point((touch1.x + touch2.x) / 2, (touch1.y + touch2.y) / 2);
  }

  #calculateTouchDelta(touch1: Point, touch2: Point): Vector2d {
    return new Vector2d(touch2.x - touch1.x, touch2.y - touch1.y);
  }

  #buildInputEventHandlers(): InputEventHandlers {
    return new InputEventHandlers({
      onMouseEnterHandler: async (event, ext) => {
        // console.log('onMouseEnterHandler', ext.point);
        // TODO(pbirch): Send event instead of just point
        this.#normalizedEventHandlers.handleHover(event, ext);
      },

      onMouseLeaveHandler: async (event, ext) => {
        // console.log('onMouseLeaveHandler', ext.point);
        this.#isDragging = false;
        this.#normalizedEventHandlers.handleLeave(event, ext);
      },

      onMouseMoveHandler: async (event, ext) => {
        // console.log('onMouseMoveHandler', ext.point);
        this.#lastTouch = ext.point;

        if (this.#isDragging) {
          const delta = new Vector2d(
            ext.point.x - this.#lastTouch.x,
            ext.point.y - this.#lastTouch.y,
          );
          this.#lastTouchDelta = delta;

          const ext2: DragEventData & EventModifiers = {
            ...ext,
            delta,
          };

          return await this.#normalizedEventHandlers.handleMove(event, ext2);
        }
        return await this.#normalizedEventHandlers.handleHover(event, ext);
      },

      onMouseDownHandler: async (event, ext) => {
        // console.log('onMouseDownHandler', ext.point);
        this.#isDragging = true;
        this.#lastTouch = ext.point;
        this.#normalizedEventHandlers.handleSelect(event, ext);
      },

      onMouseUpHandler: async (event, ext) => {
        // console.log('onMouseUpHandler', ext.point);
        this.#isDragging = false;
      },

      onWheelHandler: async (event, ext) => {
        // console.log('onWheelHandler', ext.point);
        if (!event?.preventDefault) {
          console.error('[InputEventNormalizer] onWheelHandler');
        }
        event.preventDefault();

        const delta = new Vector2d(0, InputEventNormalizer.WHEEL_JUMP_DISTANCE);
        const scale = this.#calculateZoomScale(event.deltaY);

        const ext2: ZoomEventData & EventModifiers = {
          ...ext,
          delta,
          distance: InputEventNormalizer.WHEEL_JUMP_DISTANCE,
          scale,
          center: ext.point, // Use the cursor position as zoom center
        };

        return await this.#normalizedEventHandlers.handleZoom(event, ext2);
      },

      onTouchStartHandler: async (event, ext) => {
        // console.log('onTouchStartHandler', event.touches, event.changedTouches);
        if (!event?.preventDefault) {
          console.error('[InputEventNormalizer] onToucheStartHandler');
        }
        event.preventDefault();

        // if (!event.touches.length) return;
        if (ext.touches.length === 0) return;
        const touch1 = ext.touches[0]!;
        // TODO(pbirch): this needs to have a delay before toggling
        this.#isDragging = true;
        this.#lastTouch = touch1.point;

        const ext2 = {
          ...ext,
          point: touch1.point,
          // delta,
        };
        this.#normalizedEventHandlers.handleHover(event, ext2);

        if (event.touches.length === 2) {
          const touch2 = ext.touches[1]!;
          const delta = this.#calculateTouchDelta(touch1.point, touch2.point);
          this.#lastTouchDelta = delta;
          this.#lastTouchDistance = Math.hypot(delta.x, delta.y);
        }
      },

      onTouchMoveHandler: async (event, ext) => {
        // console.log('onTouchMoveHandler', event.touches, event.changedTouches);
        if (!event?.preventDefault) {
          console.error('[InputEventNormalizer] onToucheMoveHandler');
        }
        event.preventDefault();

        if (!event.touches.length) return;

        if (ext.touches.length === 0) return;
        const touch1 = ext.touches[0]!;

        if (event.touches.length === 2) {
          const touch2 = ext.touches[1]!;
          const delta = this.#calculateTouchDelta(touch1.point, touch2.point);
          const distance = Math.hypot(delta.x, delta.y);
          const scale = distance / this.#lastTouchDistance;
          const center = this.#calculateTouchZoomCenter(
            touch1.point,
            touch2.point,
          );

          this.#lastTouchDistance = distance;

          const ext2 = {
            ...ext,
            delta,
            distance: InputEventNormalizer.WHEEL_JUMP_DISTANCE,
            scale,
            center, // Use the cursor position as zoom center
          };
          this.#normalizedEventHandlers.handleZoom(event, ext2);
        } else if (this.#isDragging) {
          const delta = new Vector2d(
            touch1.point.x - this.#lastTouch.x,
            touch1.point.y - this.#lastTouch.y,
          );
          this.#lastTouch = touch1.point;

          const ext2 = {
            ...ext,
            point: touch1.point,
            delta,
          };
          this.#normalizedEventHandlers.handleHover(event, ext2);
        }
      },

      onTouchEndHandler: async (event, ext) => {
        // console.log('onTouchEndHandler', ext.changedTouches[0].toString());
        if (!event?.preventDefault) {
          console.error('[InputEventNormalizer] onToucheEndHandler');
        }
        event.preventDefault();

        if (event.touches.length === 0) {
          this.#isDragging = false;
          if (ext.changedTouches.length === 0) return;
          const touch1 = ext.changedTouches[0]!;

          const ext2 = {
            ...ext,
            point: touch1.point,
          };
          // check rect... if (touch1.point >=)
          const contained = this.#bandSize.containsPoint(touch1.point);
          // console.log('[InputEventNormalizer] Contained:', contained, touch1.point);
          if (contained) {
            this.#normalizedEventHandlers.handleSelect(event, ext2);
          } else {
            this.#normalizedEventHandlers.handleLeave(event, ext2);
          }

          // this.#normalizedEventHandlers.handleLeave(event, ext2);
          // return;
        }

        // console.log(`[InputEventNormalizer] Touch End with Touches Len: ${event.touches.length} - Change Len: ${event.changedTouches.length}`);
        // const touch1 = ext.changedTouches[0];

        // const ext2 = {
        //     ...ext,
        //     point: touch1.point,
        // };
        // this.#normalizedEventHandlers.handleSelect(event, ext2);
      },
      // onTouchCancelHandler
    });
  }
}
