import { IRenderable, Point, Rect, Bounds } from '@repo/drawing';
import {
  SnapPoint,
  SnapResult,
  SnapGuide,
  SpatialHashGrid,
} from '@repo/snap-engine';

interface SnapVisualizerConfig {
  /**
   * Whether to show all visualization elements
   */
  enabled: boolean;
  /**
   * The area to visualize within
   */
  bounds: Rect;
  /**
   * Visual styles configuration
   */
  style?: {
    snapPointColors?: {
      corner: string;
      edge: string;
      center: string;
      grid: string;
      intersection: string;
      path: string;
      spacing: string;
      golden: string;
      custom: string;
    };
    gridColor?: string;
    guideColor?: string;
    textColor?: string;
    fontSize?: number;
  };
  /**
   * Toggle specific visualizations
   */
  show?: {
    points?: boolean;
    grid?: boolean;
    guides?: boolean;
    spatialHash?: boolean;
    labels?: boolean;
    cursor?: boolean;
    stats?: boolean;
  };
}

interface VisualizerData {
  snapPoints: SnapPoint[];
  spatialHash: SpatialHashGrid;
  currentResult?: SnapResult;
  cursorPosition?: Point;
}

export class SnapEngineVisualizer implements IRenderable {
  private config: Required<SnapVisualizerConfig>;
  private data: VisualizerData;

  constructor(config: SnapVisualizerConfig) {
    // Set defaults for all config options
    this.config = {
      enabled: config.enabled,
      bounds: config.bounds,
      style: {
        snapPointColors: {
          corner: 'rgba(255, 0, 0, 0.6)',
          edge: 'rgba(0, 255, 0, 0.6)',
          center: 'rgba(0, 0, 255, 0.6)',
          grid: 'rgba(255, 165, 0, 0.6)',
          intersection: 'rgba(128, 0, 128, 0.6)',
          path: 'rgba(165, 42, 42, 0.6)',
          spacing: 'rgba(0, 128, 128, 0.6)',
          golden: 'rgba(218, 165, 32, 0.6)',
          custom: 'rgba(128, 128, 128, 0.6)',
          ...config.style?.snapPointColors,
        },
        gridColor: config.style?.gridColor ?? 'rgba(200, 200, 200, 0.3)',
        guideColor: config.style?.guideColor ?? 'rgba(0, 120, 255, 0.5)',
        textColor: config.style?.textColor ?? 'rgba(60, 60, 60, 0.8)',
        fontSize: config.style?.fontSize ?? 22,
      },
      show: {
        points: true,
        grid: true,
        guides: true,
        spatialHash: true,
        labels: true,
        cursor: true,
        stats: true,
        ...config.show,
      },
    };

    // Convert Rect to Bounds for spatialHash
    const bounds: Bounds = {
      x: config.bounds.left,
      y: config.bounds.top,
      width: config.bounds.width,
      height: config.bounds.height,
    };

    this.data = {
      snapPoints: [],
      spatialHash: {
        cellSize: 0,
        bounds,
        points: new Map(),
      },
    };
  }

  updateData(data: Partial<VisualizerData>): void {
    this.data = { ...this.data, ...data };
  }

  renderTo(ctx: CanvasRenderingContext2D): void {
    if (!this.config.enabled) return;

    ctx.save();

    // Draw spatial hash grid
    if (this.config.show.spatialHash) {
      this.renderSpatialHash(ctx);
    }

    // Draw snap points
    if (this.config.show.points) {
      this.renderSnapPoints(ctx);
    }

    // Draw current guides
    if (this.config.show.guides && this.data.currentResult?.guides) {
      this.renderGuides(ctx, this.data.currentResult.guides);
    }

    // Draw cursor info
    if (this.config.show.cursor && this.data.cursorPosition) {
      this.renderCursorInfo(ctx);
    }

    // Draw stats
    if (this.config.show.stats) {
      this.renderStats(ctx);
    }

    ctx.restore();
  }

  private renderSpatialHash(ctx: CanvasRenderingContext2D): void {
    // const { cellSize, bounds } = this.data.spatialHash;
    // ctx.strokeStyle = this.config.style.gridColor ?? '#f00';
    // ctx.lineWidth = 0.5;
    // // Draw vertical lines
    // for (let x = 0; x <= bounds.width; x += cellSize) {
    //   ctx.beginPath();
    //   ctx.moveTo(x, 0);
    //   ctx.lineTo(x, bounds.height);
    //   ctx.stroke();
    // }
    // // Draw horizontal lines
    // for (let y = 0; y <= bounds.height; y += cellSize) {
    //   ctx.beginPath();
    //   ctx.moveTo(0, y);
    //   ctx.lineTo(bounds.width, y);
    //   ctx.stroke();
    // }
    // // Draw cell occupancy counts
    // if (this.config.show.labels) {
    //   ctx.font = `${this.config.style.fontSize}px monospace`;
    //   ctx.fillStyle = this.config.style.textColor ?? '#f00';
    //   ctx.textAlign = 'center';
    //   ctx.textBaseline = 'middle';
    //   this.data.spatialHash.points.forEach((points, key) => {
    //     const [cellX, cellY] = key.split(',').map(Number);
    //     const x = (cellX! + 0.5) * cellSize;
    //     const y = (cellY! + 0.5) * cellSize;
    //     ctx.fillText(points.length.toString(), x, y);
    //   });
    // }
  }

  private renderSnapPoints(ctx: CanvasRenderingContext2D): void {
    const colors = this.config.style.snapPointColors!;

    this.data.snapPoints.forEach((point) => {
      const color = colors[point.type];
      const radius = 6 * point.strength;

      ctx.fillStyle = color;
      ctx.beginPath();
      ctx.arc(point.position.x, point.position.y, radius, 0, Math.PI * 2);
      ctx.fill();

      if (this.config.show.labels) {
        ctx.font = `${this.config.style.fontSize}px monospace`;
        ctx.fillStyle = this.config.style.textColor ?? '#f00';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'top';
        ctx.fillText(
          `${point.type} (${point.strength})`,
          point.position.x + radius + 2,
          point.position.y + radius + 2,
        );
      }
    });
  }

  private renderGuides(
    ctx: CanvasRenderingContext2D,
    guides: SnapGuide[],
  ): void {
    guides.forEach((guide) => {
      const opacity = guide.strength * 0.5;
      const guideColor = this.config.style.guideColor ?? '#f00';
      ctx.strokeStyle = guideColor.replace(
        /rgba\((\d+),\s*(\d+),\s*(\d+),\s*[\d.]+\)/,
        `rgba($1, $2, $3, ${opacity})`,
      );
      ctx.lineWidth = 8;

      ctx.beginPath();
      if (guide.type === 'horizontal') {
        ctx.moveTo(guide.start, guide.position);
        ctx.lineTo(guide.end, guide.position);
      } else if (guide.type === 'vertical') {
        ctx.moveTo(guide.position, guide.start);
        ctx.lineTo(guide.position, guide.end);
      }
      ctx.stroke();

      if (this.config.show.labels) {
        ctx.font = `${this.config.style.fontSize}px monospace`;
        ctx.fillStyle = this.config.style.textColor ?? '#fff';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'top';
        const label = `${guide.type} (${guide.strength.toFixed(2)})`;
        if (guide.type === 'horizontal') {
          ctx.fillText(label, guide.start + 2, guide.position + 2);
        } else {
          ctx.fillText(label, guide.position + 2, guide.start + 2);
        }
      }
    });
  }

  private renderCursorInfo(ctx: CanvasRenderingContext2D): void {
    if (!this.data.cursorPosition || !this.data.currentResult) return;

    const { cursorPosition } = this.data;
    const result = this.data.currentResult;

    // Draw cursor position
    ctx.beginPath();
    ctx.arc(cursorPosition.x, cursorPosition.y, 4, 0, Math.PI * 2);
    ctx.fillStyle = 'rgba(255, 0, 0, 0.8)';
    ctx.fill();

    // Draw info box
    const info = [
      `Cursor: (${cursorPosition.x.toFixed(1)}, ${cursorPosition.y.toFixed(1)})`,
      `Snapped: ${result.snapped}`,
      `Confidence: ${result.metadata?.confidence?.toFixed(2) ?? 'N/A'}`,
      `Distance: ${result.metadata?.snapDistance?.toFixed(2) ?? 'N/A'}`,
      `Guides: ${result.guides.length}`,
    ];

    const boxPadding = 5;
    const lineHeight = (this.config.style.fontSize ?? 10) + 2;
    const boxWidth = 200;
    const boxHeight = (info.length + 1) * lineHeight + 2 * boxPadding;

    let boxX = cursorPosition.x + 10;
    let boxY = cursorPosition.y + 10;

    // Adjust box position if it would go off screen
    if (boxX + boxWidth > this.config.bounds.width) {
      boxX = cursorPosition.x - boxWidth - 10;
    }
    if (boxY + boxHeight > this.config.bounds.height) {
      boxY = cursorPosition.y - boxHeight - 10;
    }

    // Draw box background
    ctx.fillStyle = 'rgba(255, 255, 255, 0.9)';
    ctx.strokeStyle = 'rgba(0, 0, 0, 0.2)';
    ctx.lineWidth = 4;
    ctx.fillRect(boxX, boxY, boxWidth, boxHeight);
    ctx.strokeRect(boxX, boxY, boxWidth, boxHeight);

    // Draw info text
    ctx.font = `${this.config.style.fontSize}px monospace`;
    ctx.fillStyle = this.config.style.textColor || '#fff';
    ctx.textAlign = 'left';
    ctx.textBaseline = 'top';

    info.forEach((text, i) => {
      ctx.fillText(text, boxX + boxPadding, boxY + boxPadding + i * lineHeight);
    });
  }

  private renderStats(ctx: CanvasRenderingContext2D): void {
    const stats = [
      `Total Points: ${this.data.snapPoints.length}`,
      `Grid Cells: ${this.data.spatialHash.points.size}`,
      `Cell Size: ${this.data.spatialHash.cellSize}`,
    ];

    ctx.font = `${this.config.style.fontSize}px monospace`;
    ctx.fillStyle = this.config.style.textColor || '#fff';
    ctx.textAlign = 'left';
    ctx.textBaseline = 'top';

    stats.forEach((text, i) => {
      ctx.fillText(text, 10, 10 + i * ((this.config.style.fontSize ?? 10) + 2));
    });
  }
}
