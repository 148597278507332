import { IRenderable, Point, Rect } from '@repo/drawing';

export class Icon implements IRenderable {
  #position: Point;
  #size: Rect;
  #image: HTMLImageElement;
  #loading: boolean;
  #error: string | undefined;
  // #notified: boolean;
  #visible: boolean;

  constructor(
    params: {
      size: Rect;
      src: string;
    },
    options?: {
      position?: Point;
      visible?: boolean;
    },
  ) {
    this.#size = params.size;
    this.#loading = true;
    this.#error = undefined;
    // this.#notified = false;

    this.#position = options?.position ?? new Point(0, 0);
    this.#visible = options?.visible ?? true;

    const img = new Image();
    img.onload = () => {
      this.#loading = false;
    };
    img.onerror = (err) => {
      this.#error = err.toString();
      console.error(`[Icon] Error:`, err);
    };
    img.src = params.src;
    this.#image = img;
  }

  get size(): Rect {
    return this.#size;
  }

  get image(): HTMLImageElement {
    return this.#image;
  }

  get loading(): boolean {
    return this.#loading;
  }

  get position(): Point {
    return this.#position;
  }

  set position(value: Point) {
    this.#position = value;
  }

  get visible(): boolean {
    return this.#visible;
  }

  set visible(value: boolean) {
    this.#visible = value;
  }

  renderTo(ctx: CanvasRenderingContext2D): void {
    if (!this.visible || this.#error !== undefined) return;

    const p = this.#position;
    const s = this.#size;
    const img = this.#image;

    ctx.save();
    if (this.#loading != undefined && !this.#loading) {
      ctx.drawImage(
        img,
        p.x - s.width / 2,
        p.y - s.height / 2,
        s.width,
        s.height,
      );
      // ctx.drawImage(i, 0, 0, i.width, i.height, p.left, p.top, p.width, p.height);
    }
    ctx.restore();
  }
}

// await this.#loadingPromise;
// if (this.#loading && !this.#notified) {
//     console.warn(`[Icon] Image loading [${this.#image.src}]`);
//     this.#notified = true;
//     return;
// }
// if (this.#error != undefined && !this.#notified) {
//     console.error(`[Icon] Error: ${this.#error}`);
//     this.#notified = true;
//     return;
// }
// if (!this.#notified) {
//     console.warn('[Icon] Image loaded!');
//     this.#notified = true;
//     // return;
// }
// if (!this.#visible) return;
// console.log('Render Icon:', this.#loading);

// ctx.save();

//     console.error('[Icon] Error:', this.#error);
//     this.#error = undefined;
//     return;
// }

// // ctx.drawImage(i, 0, 0, i.width, i.height, p.left, p.top, p.width, p.height);
// ctx.strokeStyle = '#F00';
// ctx.fillStyle = '#0F0';
// // ctx.strokeRect(p.left, p.top, p.width, p.height);
// ctx.fillRect(p.left, p.top, p.width, p.height);

// this.#loadingPromise = loadImage(params.src, this.#image)
//     .then(() => {
//         console.log('Image Loaded in promise then');
//         this.#loading = false;
//         this.#notified = false;
//     })
//     .catch((ex) => {
//         console.log('Image Error in promise then', ex);
//         this.#error = ex;
//     })
//     .finally(() => {
//         console.warn('[Icon] Finally image load?');
//     });

// this.#image.addEventListener('load', (e: Event) => {
//     this.#loading = false;
// });
// this.#image.addEventListener('error', (e: ErrorEvent) => {
//     console.error(`[Icon] Error: ${e}`);
// });
// this.#loading = true;
// this.#image.src = params.src;
