import { createSignal } from 'solid-js';
import { IDB, imagecache, imagestore } from '@repo/database';
import { Database, StateManager, StateManagerImpl } from '@repo/state-manager';
import {
  ImageProcessingResult,
  ImageBufferUtils,
  IMAGE_CONSTANTS,
} from '@repo/shared';
// import { ClientApis } from '@repo/state-manager';
import {
  EnhancedImage,
  SerializedLayout,
} from '../../database/src/imagestore/v1-types.js';
import { FileDetails } from '../../database/src/imagestore/Imagestore';
import { logger } from './index.js';

const baseDelay = 0;

// Default delay settings (in milliseconds)
const DEFAULT_DELAYS = {
  preprocessing: baseDelay + 0,
  caching: baseDelay + 0,
  thumbnails: baseDelay + 0,
  variants: baseDelay + 0,
  uploading: baseDelay + 0,
} as const;

export type ProcessStage =
  | 'initial'
  | 'preprocessing'
  | 'caching'
  | 'generating-thumbnails'
  | 'generating-variants'
  | 'uploading'
  | 'complete'
  | 'error';

export interface ImageWorkflow {
  id: string;
  // file?: File;
  stage: ProcessStage;
  progress: number;
  error?: Error;

  // Image variants as they become available
  original?: imagestore.EnhancedImage;
  thumbnail?: imagestore.LoadableImage;
  preview?: imagestore.LoadableImage;
  bandImage?: imagestore.LoadableImage;

  // Cache keys for loading
  originalKey?: string;
  thumbnailKey?: string;
  previewKey?: string;
  bandKey?: string;

  // Upload status
  uploadId?: string;
  uploadUrl?: string;

  // Square crop region for band
  cropRegion?: {
    x: number;
    y: number;
    size: number;
  };
}

export interface ProcessingDelays {
  preprocessing?: number;
  caching?: number;
  thumbnails?: number;
  variants?: number;
  uploading?: number;
}

export class ImageWorkflowManager {
  // private api: ClientApis;
  private delays: Required<ProcessingDelays>;

  constructor(
    // private imageStore: imagestore.ImageStore,
    // private stateManager: StateManager,
    delays: ProcessingDelays = {},
  ) {
    // this.api = new ClientApis();
    this.delays = {
      preprocessing: delays.preprocessing ?? DEFAULT_DELAYS.preprocessing,
      caching: delays.caching ?? DEFAULT_DELAYS.caching,
      thumbnails: delays.thumbnails ?? DEFAULT_DELAYS.thumbnails,
      variants: delays.variants ?? DEFAULT_DELAYS.variants,
      uploading: delays.uploading ?? DEFAULT_DELAYS.uploading,
    };
  }

  async processFile(
    file: File,
    workflowId: string,
    onUpdate: (workflow: ImageWorkflow) => void,
    selectedIndex?: number,
  ): Promise<ImageWorkflow> {
    const imageBuffer = await ImageBufferUtils.readFileAsBuffer(file);
    // return this.process(file, workflowId, onUpdate, selectedIndex);
    return this.processBuffer(
      workflowId,
      imageBuffer,
      file,
      onUpdate,
      selectedIndex,
    );
  }

  async processBuffer(
    workflowId: string,
    imageBuffer: ArrayBuffer,
    file: FileDetails,
    onUpdate: (workflow: ImageWorkflow) => void,
    selectedIndex?: number,
  ): Promise<ImageWorkflow> {
    const workflow: ImageWorkflow = {
      id: workflowId,
      stage: 'initial',
      progress: 0,
    };

    try {
      const db = Database.getInstance();
      await db.initialize();

      // Start preprocessing
      workflow.stage = 'preprocessing';
      onUpdate(workflow);
      const hash = await crypto.subtle.digest('SHA-256', imageBuffer);
      const imageElement = await ImageBufferUtils.imageFromBuffer(
        imageBuffer,
        file.type,
      );
      // Cache original image
      workflow.stage = 'caching';
      workflow.progress = 0;
      onUpdate(workflow);

      const stateManager = StateManagerImpl.getInstance();
      const imageStore = await stateManager.database.imageStore();
      // Create enhanced image metadata
      const storeResult = await imageStore.storeImage(file, imageBuffer, {
        caption: file.name,
        quality: 0.9,
        mimeType: file.type,
        displayName: file.name,
        isPrivate: true,
        status: 'active',
        needsSync: true,
        tags: [],
        metadata: {
          created_by: 'designer_v1',
        },
        onProgress: async (progress: number) => {
          workflow.progress = progress;
          onUpdate(workflow);
        },
      });

      workflow.original = storeResult.image;
      // Should this be cacheKey or image.id?
      workflow.originalKey = storeResult.image.id;
      logger.info('Image cached', {
        original: workflow.original,
        originalKey: workflow.originalKey,
      });

      // Generate thumbnails
      workflow.stage = 'generating-thumbnails';
      workflow.progress = 0;
      onUpdate(workflow);
      await this.simulateProgress(workflow, this.delays.thumbnails, onUpdate);
      const cacheKey = storeResult.cacheKey;

      const thumbnailVariant = await this.generateThumbnail(storeResult.image);
      // const thumbnail = await db.imageStore.storeImage(thumbnailVariant
      //   storeResult.image,
      //   thumbnailVariant,
      // );
      // workflow.thumbnailKey = th
      // workflow.thumbnail = thumbnail;
      workflow.thumbnailKey = cacheKey; // Reuse original for now
      workflow.thumbnail = await this.generateThumbnail(storeResult.image);

      // Generate previews and variants
      workflow.stage = 'generating-variants';
      workflow.progress = 0;
      onUpdate(workflow);
      await this.simulateProgress(workflow, this.delays.variants, onUpdate);

      workflow.previewKey = cacheKey; // Reuse original for now
      workflow.preview = await this.generatePreview(workflow.original);

      // Calculate and generate band crop
      const cropRegion = this.calculateOptimalCrop(workflow.original);
      workflow.cropRegion = cropRegion;
      workflow.bandKey = storeResult.cacheKey;
      workflow.bandImage = await this.generateBandImage(
        workflow.original,
        cropRegion,
      );

      // Simulate upload (commented out for now)
      // workflow.stage = 'uploading';
      // workflow.progress = 0;
      // onUpdate(workflow);
      // await this.simulateProgress(workflow, this.delays.uploading, onUpdate);

      /* Actual upload would go here
            const uploadResult = await this.api.uploadImage({
                data: await db.imageCache.getData(cacheKey).then(r => r.buffer),
                mimetype: file.type
            });
            workflow.uploadId = uploadResult.id;
            workflow.uploadUrl = uploadResult.url.toString();
            */

      // Use placeholder upload data for now
      // workflow.uploadId = crypto.randomUUID();
      // workflow.uploadUrl = `https://example.com/images/${workflow.uploadId}`;

      // Mark complete
      workflow.stage = 'complete';
      workflow.progress = 100;

      // logger.info('Image processing complete', { workflow });
      // // Update the band layout based on the type
      // if (selectedIndex !== undefined) {
      //   logger.info('Updating band layout with new image', { selectedIndex });
      //   this.
      //   const bandInstance = await db.imageStore.getBand(
      //     workflow.bandImage.id, //.originalId,
      //   );
      //   if (bandInstance) {
      //     const layout: SerializedLayout | undefined =
      //       await db.imageStore.getBandData(bandInstance.id);
      //     logger.info('Got band layout', { layout });
      //     if (layout) {
      //       if (layout.type === 'og7') {
      //         const og7Layout = layout as imagestore.OG7LayoutV1;
      //         // Update the specific slot with the new image
      //         og7Layout.data[selectedIndex] = workflow.bandImage;
      //         await db.imageStore.storeBandData(bandInstance.id, og7Layout);
      //       } else if (layout.type === 'banner') {
      //         const bannerLayout = layout as imagestore.BannerLayoutV1;
      //         // Update the banner image
      //         bannerLayout.data = workflow.bandImage;
      //         await db.imageStore.storeBandData(bandInstance.id, bannerLayout);
      //       }
      //       logger.info('Updated band layout', { layout });
      //     }
      //   }
      // }

      onUpdate(workflow);

      return workflow;
    } catch (error) {
      console.error('[ImageWorkflowProcessor] Error processing image:', error);
      workflow.stage = 'error';
      workflow.error =
        error instanceof Error ? error : new Error('Unknown error');
      onUpdate(workflow);
      throw error;
    }
  }

  private async simulateProgress(
    workflow: ImageWorkflow,
    duration: number,
    onUpdate: (workflow: ImageWorkflow) => void,
  ): Promise<void> {
    const startTime = Date.now();
    const updateInterval = 50; // Smoother updates at 50ms intervals

    return new Promise((resolve) => {
      const updateProgress = () => {
        const elapsed = Date.now() - startTime;
        const progress = Math.min((elapsed / duration) * 100, 100);

        workflow.progress = Math.round(progress);
        onUpdate(workflow);

        if (elapsed < duration) {
          setTimeout(updateProgress, updateInterval);
        } else {
          resolve();
        }
      };

      updateProgress();
    });
  }

  private async generateThumbnail(
    image: imagestore.EnhancedImage,
  ): Promise<imagestore.ImageVariant> {
    // Generate a 150x150 thumbnail
    return {
      id: crypto.randomUUID(),
      originalId: image.id,
      description: 'Thumbnail',
      created: Date.now(),
      status: 'active',
      format: image.format,
      quality: 80,
      fileSize: Math.floor(image.fileSize * 0.1),
      aspectRatio: image.aspectRatio,
      width: 150,
      height: Math.round(150 / image.aspectRatio),
      mimeType: image.mimeType,
      cacheKey: image.cacheKey,
    } as imagestore.ImageVariant;
  }

  private async generatePreview(
    image: imagestore.EnhancedImage,
  ): Promise<imagestore.ImageVariant> {
    // Generate an 800px max dimension preview
    const scaleFactor = Math.min(800 / image.width, 800 / image.height);
    const width = Math.round(image.width * scaleFactor);
    const height = Math.round(image.height * scaleFactor);

    return {
      id: crypto.randomUUID(),
      originalId: image.id,
      description: 'Preview',
      created: Date.now(),
      status: 'active',
      format: image.format,
      quality: 85,
      fileSize: Math.floor(image.fileSize * 0.4),
      aspectRatio: image.aspectRatio,
      width,
      height,
      mimeType: image.mimeType,
      cacheKey: image.cacheKey,
    } as imagestore.ImageVariant;
  }

  private calculateOptimalCrop(image: imagestore.EnhancedImage) {
    const size = Math.min(image.width, image.height);
    const x = Math.round((image.width - size) / 2);
    const y = Math.round((image.height - size) / 2);
    return { x, y, size };
  }

  private async generateBandImage(
    image: imagestore.EnhancedImage,
    crop: NonNullable<ImageWorkflow['cropRegion']>,
  ): Promise<imagestore.ImageVariant> {
    return {
      id: crypto.randomUUID(),
      originalId: image.id,
      description: 'Band Image',
      created: Date.now(),
      status: 'active',
      format: image.format,
      quality: 90,
      fileSize: Math.floor(image.fileSize * 0.3),
      aspectRatio: 1,
      width: crop.size,
      height: crop.size,
      mimeType: image.mimeType,
      cacheKey: image.cacheKey,
    } as imagestore.ImageVariant;
  }

  // Method to update processing delays at runtime
  updateDelays(newDelays: ProcessingDelays): void {
    this.delays = {
      ...this.delays,
      ...newDelays,
    };
  }
}
