/**
 * Constants for image processing
 */
const MAX_CANVAS_DIMENSIONS = 16777216; // 4096 * 4096
const MIN_DIMENSION = 16;

/**
 * Type definitions for better type safety
 */
interface ImageProcessingResult {
  img: HTMLImageElement;
  blob: Blob;
  dimensions: {
    original: { width: number; height: number };
    final: { width: number; height: number };
  };
}

/**
 * Creates and loads an image from an ArrayBuffer
 */
export async function imageFromBuffer(
  buffer: ArrayBuffer,
  type: string,
): Promise<HTMLImageElement> {
  // Create a blob from the buffer
  const blob = new Blob([buffer], { type });

  // Create an image element
  const img = document.createElement('img') as HTMLImageElement;
  const blobUrl = URL.createObjectURL(blob);
  try {
    // Load the image
    await new Promise<void>((resolve, reject) => {
      (img.onload = (e) => {
        resolve();
      }),
        (img.onerror = reject);
      img.src = blobUrl;
    });
  } catch (ex) {
    throw ex;
  } finally {
    URL.revokeObjectURL(blobUrl);
  }
  return img;
}
// function imageFromBuffer(buffer: ArrayBuffer, type: string): Promise<HTMLImageElement> {
//   return new Promise((resolve, reject) => {
//     const blob = new Blob([buffer], { type });
//     const img = new Image();
//     img.onload = () => resolve(img);
//     img.onerror = () => reject(new Error('Failed to load image'));
//     img.src = URL.createObjectURL(blob);
//   });
// }

export async function fetchImageAsArrayBuffer(
  imageUrl: string,
): Promise<ArrayBuffer> {
  const response = await fetch(imageUrl);
  if (!response.ok) {
    throw new Error(`Failed to fetch image: ${response.status}`);
  }
  return await response.arrayBuffer();
}

interface Dimensions {
  width: number;
  height: number;
}

interface ResizeResult extends Dimensions {
  scale: number;
}

/**
 * Utility function to create dimensions object for better type safety
 */
export function createDimensions(width: number, height: number): Dimensions {
  return { width, height };
}

/**
 * Calculate optimal dimensions for resizing an image while maintaining aspect ratio
 * and staying under a maximum pixel count. Favors scaling ratios that minimize data loss.
 *
 * @param originalDimensions - Original image dimensions
 * @param maxPixels - Maximum total number of pixels allowed
 * @returns Optimal dimensions and scale factor
 */
export function calculateOptimalDimensions(
  // originalDimensions: Dimensions,
  originalWidth: number,
  originalHeight: number,
  maxPixels: number,
): ResizeResult {
  // const { width: originalWidth, height: originalHeight } = originalDimensions;

  // Input validation
  if (originalWidth <= 0 || originalHeight <= 0 || maxPixels <= 0) {
    throw new Error('Dimensions and maxPixels must be positive numbers');
  }

  // const aspectRatio = originalWidth / originalHeight;

  // Start with the theoretical maximum dimensions
  const theoreticalScale = Math.sqrt(
    maxPixels / (originalWidth * originalHeight),
  );

  // Common scaling factors (in descending order) that typically result in good quality
  const preferredScales = [1, 0.75, 0.5, 0.375, 0.25, 0.125];

  // Find the largest preferred scale that fits within max_pixels
  let bestResult: ResizeResult = {
    width: Math.floor(originalWidth * theoreticalScale),
    height: Math.floor(originalHeight * theoreticalScale),
    scale: theoreticalScale,
  };

  // Try preferred scales first
  for (const scale of preferredScales) {
    const width = Math.floor(originalWidth * scale);
    const height = Math.floor(originalHeight * scale);

    if (width * height <= maxPixels && width > 0 && height > 0) {
      bestResult = { width, height, scale };
      break;
    }
  }

  // If no preferred scale works, use binary search to find optimal dimensions
  if (!preferredScales.includes(bestResult.scale)) {
    let left = 0;
    let right = theoreticalScale;

    // Binary search with limited iterations for performance
    const MAX_ITERATIONS = 20;
    let iterations = 0;

    while (right - left > 0.001 && iterations < MAX_ITERATIONS) {
      const mid = (left + right) / 2;
      const width = Math.floor(originalWidth * mid);
      const height = Math.floor(originalHeight * mid);

      if (width * height <= maxPixels) {
        bestResult = { width, height, scale: mid };
        left = mid;
      } else {
        right = mid;
      }

      iterations++;
    }
  }

  // Ensure we never exceed max pixels due to rounding
  while (bestResult.width * bestResult.height > maxPixels) {
    bestResult.width = Math.floor(bestResult.width * 0.99);
    bestResult.height = Math.floor(bestResult.height * 0.99);
    bestResult.scale *= 0.99;
  }

  return bestResult;
}

/**
 * Creates a canvas with the specified dimensions and draws the image
 */
function createCanvasWithImage(
  img: HTMLImageElement,
  targetWidth: number,
  targetHeight: number,
): HTMLCanvasElement {
  const canvas = document.createElement('canvas');
  canvas.width = targetWidth;
  canvas.height = targetHeight;

  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('Failed to get canvas context');
  }

  // Clear the canvas and set background to white if needed
  ctx.fillStyle = '#FFFFFF';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Use better image smoothing
  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = 'high';

  // Draw image with proper scaling
  ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

  return canvas;
}

/**
 * Progressively resize an image to avoid quality loss and corruption
 */
export async function compressImage(
  buffer: ArrayBuffer,
  type: string,
  compressionQuality: number,
): Promise<ImageProcessingResult | undefined> {
  try {
    // Load the image
    const img = await imageFromBuffer(buffer, type);

    // Record original dimensions
    const originalDimensions = {
      width: img.width,
      height: img.height,
    };

    // If the image doesn't need resizing, just compress it
    if (img.width * img.height <= MAX_CANVAS_DIMENSIONS) {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      if (!ctx) throw new Error('Failed to get canvas context');

      ctx.drawImage(img, 0, 0);

      const blob = await new Promise<Blob>((resolve, reject) => {
        canvas.toBlob(
          (blob) =>
            blob ? resolve(blob) : reject(new Error('Failed to create blob')),
          type,
          compressionQuality,
        );
      });

      return {
        img,
        blob,
        dimensions: {
          original: originalDimensions,
          final: { width: img.width, height: img.height },
        },
      };
    }

    // Calculate target dimensions
    const { width: targetWidth, height: targetHeight } =
      calculateOptimalDimensions(img.width, img.height, MAX_CANVAS_DIMENSIONS);

    // Create temporary canvas for progressive scaling
    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');
    if (!tempCtx) throw new Error('Failed to get temporary canvas context');

    // Progressive scaling
    let currentWidth = img.width;
    let currentHeight = img.height;

    // Use multiple steps if the scale factor is less than 0.5
    const scaleFactor = Math.min(
      targetWidth / img.width,
      targetHeight / img.height,
    );
    const numSteps = scaleFactor < 0.5 ? 2 : 1;

    // Intermediate size for each step
    const stepScaleFactor = scaleFactor ** (1 / numSteps);

    let currentImg: HTMLImageElement = img;

    for (let step = 0; step < numSteps; step++) {
      const stepWidth = Math.round(currentWidth * stepScaleFactor);
      const stepHeight = Math.round(currentHeight * stepScaleFactor);

      // Resize to intermediate size
      tempCanvas.width = stepWidth;
      tempCanvas.height = stepHeight;

      // Clear canvas and set properties
      tempCtx.clearRect(0, 0, stepWidth, stepHeight);
      tempCtx.imageSmoothingEnabled = true;
      tempCtx.imageSmoothingQuality = 'high';

      // Draw at intermediate size
      tempCtx.drawImage(currentImg, 0, 0, stepWidth, stepHeight);

      // Create new image from intermediate canvas
      if (step < numSteps - 1) {
        const stepBlob = await new Promise<Blob>((resolve, reject) => {
          tempCanvas.toBlob(
            (blob) =>
              blob
                ? resolve(blob)
                : reject(new Error('Failed to create intermediate blob')),
            type,
            1.0, // Use maximum quality for intermediate steps
          );
        });

        const stepImg = new Image();
        await new Promise<void>((resolve, reject) => {
          stepImg.onload = () => resolve();
          stepImg.onerror = reject;
          stepImg.src = URL.createObjectURL(stepBlob);
        });

        currentImg = stepImg;
        currentWidth = stepWidth;
        currentHeight = stepHeight;
      }
    }

    // Final compression
    const finalBlob = await new Promise<Blob>((resolve, reject) => {
      tempCanvas.toBlob(
        (blob) =>
          blob
            ? resolve(blob)
            : reject(new Error('Failed to create final blob')),
        type,
        compressionQuality,
      );
    });

    return {
      img,
      blob: finalBlob,
      dimensions: {
        original: originalDimensions,
        final: { width: tempCanvas.width, height: tempCanvas.height },
      },
    };
  } catch (error) {
    console.error('[CompressImage] Error:', error);
    return undefined;
  }
}

// /**
//  * Compress image from ArrayBuffer with proper error handling and dimension calculations
//  */
// export async function compressImage(
//   buffer: ArrayBuffer,
//   type: string,
//   compressionQuality: number
// ): Promise<ImageProcessingResult | undefined> {
//   try {
//     // Load the image
//     const img = await imageFromBuffer(buffer, type);

//     // Record original dimensions
//     const originalDimensions = {
//       width: img.width,
//       height: img.height
//     };

//     // Calculate safe dimensions that won't exceed canvas limits
//     // const { width, height } = calculateSafeDimensions(img.width, img.height);
//     const { width, height, scale } = calculateOptimalDimensions(img.width, img.height, MAX_CANVAS_DIMENSIONS);

//     // Create canvas and draw the image
//     const canvas = createCanvasWithImage(img, width, height);

//     // Convert to blob with compression
//     const blob = await new Promise<Blob>((resolve, reject) => {
//       canvas.toBlob(
//         (blob) => {
//           if (blob) {
//             resolve(blob);
//           } else {
//             reject(new Error('Failed to create blob'));
//           }
//         },
//         type,
//         compressionQuality
//       );
//     });

//     // Clean up
//     URL.revokeObjectURL(img.src);

//     return {
//       img,
//       blob,
//       dimensions: {
//         original: originalDimensions,
//         final: { width, height }
//       }
//     };
//   } catch (error) {
//     console.error('[CompressImage] Error:', error);
//     return undefined;
//   }
// }

// /**
//  * Helper function to check if the compression was successful
//  */
// export function validateCompressedImage(result: ImageProcessingResult | undefined): boolean {
//   if (!result) return false;

//   const { width, height } = result.dimensions.final;
//   return (
//     width >= MIN_DIMENSION &&
//     height >= MIN_DIMENSION &&
//     width * height <= MAX_CANVAS_DIMENSIONS &&
//     result.blob.size > 0
//   );
// }

// /**
//  * Calculate dimensions that maintain aspect ratio and fit within canvas limits
//  */
// function calculateSafeDimensions(originalWidth: number, originalHeight: number): { width: number; height: number } {
//   const aspectRatio = originalWidth / originalHeight;

//   let width = originalWidth;
//   let height = originalHeight;

//   // If image exceeds maximum canvas area, scale it down
//   if (width * height > MAX_CANVAS_DIMENSIONS) {
//     const scale = Math.sqrt(MAX_CANVAS_DIMENSIONS / (width * height));
//     width = Math.floor(width * scale);
//     height = Math.floor(height * scale);
//   }

//   // Ensure dimensions aren't too small
//   if (width < MIN_DIMENSION) {
//     width = MIN_DIMENSION;
//     height = Math.floor(width / aspectRatio);
//   }
//   if (height < MIN_DIMENSION) {
//     height = MIN_DIMENSION;
//     width = Math.floor(height * aspectRatio);
//   }

//   // Final safety check for canvas dimensions
//   if (width * height > MAX_CANVAS_DIMENSIONS) {
//     const scale = Math.sqrt(MAX_CANVAS_DIMENSIONS / (width * height));
//     width = Math.floor(width * scale);
//     height = Math.floor(height * scale);
//   }

//   return { width, height };
// }
