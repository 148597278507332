import { Point, Rect, Transform, Bounds } from '@repo/drawing';

// Snap system types
export type SnapPointType =
  | 'corner' // Corner of an object or canvas
  | 'edge' // Midpoint of an edge
  | 'center' // Center point of an object
  | 'grid' // Point on the grid
  | 'intersection' // Intersection of guides
  | 'path' // Point on a path
  | 'spacing' // Points for equal spacing
  | 'golden' // Golden ratio points
  | 'custom'; // User-defined points

export type GuideType =
  | 'horizontal'
  | 'vertical'
  | 'diagonal'
  | 'radial'
  | 'circular';

export interface SnapPoint {
  type: SnapPointType;
  position: Point;
  strength: number;
  metadata?: {
    sourceId?: string;
    label?: string;
    group?: string;
  };
}

export interface SnapGuide {
  type: GuideType;
  position: number;
  start: number;
  end: number;
  strength: number;
  metadata?: {
    sourceId?: string;
    label?: string;
    color?: string;
    dashPattern?: number[];
  };
}

export interface SnapResult {
  snapped: boolean;
  position: Point;
  guides: SnapGuide[];
  metadata?: {
    snapDistance?: number;
    snapPoints?: SnapPoint[];
    confidence?: number;
  };
}

// Configuration types
export interface GridConfig {
  spacing: Point;
  subdivisions: number;
  enabled: boolean;
  snapThreshold: number;
  color?: string;
  opacity?: number;
  showSubdivisions?: boolean;
}

export interface SnapBehviorPoints {
  enabled: boolean;
  types: SnapPointType[];
  strength: number;
  threshold?: number;
  excludeTypes?: SnapPointType[];
}

export interface SnapBehaviorGuides {
  enabled: boolean;
  types: GuideType[];
  strength: number;
  showWhileDrawing?: boolean;
  persistence?: number;
  style?: GuideStyle;
}

export interface SnapBehaviorSpacing {
  enabled: boolean;
  preferredGaps: number[];
  strength: number;
  tolerance?: number;
  enforceEqual?: boolean;
}

export interface SnapBehaviorDistribution {
  enabled: boolean;
  types: Array<'horizontal' | 'vertical' | 'radial'>;
  strength: number;
  enforceEqual?: boolean;
}

export interface SnapBehavior {
  points: SnapBehviorPoints;
  guides: SnapBehaviorGuides;
  spacing: SnapBehaviorSpacing;
  distribution: SnapBehaviorDistribution;
}

export interface GuideStyle {
  color: string;
  thickness: number;
  dashPattern?: number[];
  opacity: number;
  showLabels: boolean;
  labelFormat?: (value: number) => string;
}

// Interactive handle types
export interface DragHandle {
  id: string;
  type: 'move' | 'rotate' | 'scale' | 'skew';
  position: Point;
  cursor: string;
  visible: boolean;
  enabled: boolean;
  style?: HandleStyle;
}

export interface HandleStyle {
  size: number;
  shape: 'circle' | 'square' | 'diamond';
  fillColor: string;
  strokeColor: string;
  strokeWidth: number;
  hoverColor: string;
  activeColor: string;
}

// Constraint types
export interface SnapConstraints {
  lockAxis?: 'x' | 'y' | 'none';
  boundingBox?: Rect;
  angleSnap?: number;
  minimumSpacing?: number;
  maximumSpacing?: number;
  aspectRatio?: number | 'maintain';
  gridAlignment?: 'none' | 'center' | 'corners' | 'edges';
}

export interface SnapZone {
  bounds: Bounds;
  strength: number;
  type: 'attract' | 'repel';
  influence: 'linear' | 'quadratic' | 'gaussian';
  radius: number;
}

// Event types
export interface SnapEvent {
  type: 'snapStart' | 'snapUpdate' | 'snapEnd';
  point: Point;
  result: SnapResult;
  timestamp: number;
  sourceId?: string;
}

// Performance optimization types
export interface SpatialHashGrid {
  cellSize: number;
  bounds: Bounds;
  points: Map<string, SnapPoint[]>;
}

export interface SnapCache {
  key: string;
  result: SnapResult;
  timestamp: number;
  validityDuration: number;
}

// Configuration builder
export class SnapConfigBuilder {
  private config: Partial<SnapBehavior> = {};

  withPoints(types: SnapPointType[], strength = 1): this {
    this.config.points = {
      enabled: true,
      types,
      strength,
    };
    return this;
  }

  withGuides(types: GuideType[], strength = 1): this {
    this.config.guides = {
      enabled: true,
      types,
      strength,
    };
    return this;
  }

  withSpacing(gaps: number[], strength = 1): this {
    this.config.spacing = {
      enabled: true,
      preferredGaps: gaps,
      strength,
    };
    return this;
  }

  withDistribution(
    types: Array<'horizontal' | 'vertical' | 'radial'>,
    strength = 1,
  ): this {
    this.config.distribution = {
      enabled: true,
      types,
      strength,
    };
    return this;
  }

  build(): SnapBehavior {
    return {
      points: {
        enabled: false,
        types: [],
        strength: 1,
        ...this.config.points,
      },
      guides: {
        enabled: false,
        types: [],
        strength: 1,
        ...this.config.guides,
      },
      spacing: {
        enabled: false,
        preferredGaps: [],
        strength: 1,
        ...this.config.spacing,
      },
      distribution: {
        enabled: false,
        types: [],
        strength: 1,
        ...this.config.distribution,
      },
    };
  }
}

// Extension types for specific use cases
export interface PathSnapConfig {
  tangentPoints: boolean;
  intersectionPoints: boolean;
  controlPoints: boolean;
  pathSegments: boolean;
  curvature: {
    enabled: boolean;
    threshold: number;
    strength: number;
  };
}

export interface SmartSnapConfig {
  objectRecognition: boolean;
  symmetryDetection: boolean;
  alignmentInference: boolean;
  patternRecognition: boolean;
  confidenceThreshold: number;
}

export interface AnimationSnapConfig {
  timelineSnap: boolean;
  keyframeSnap: boolean;
  easePoints: boolean;
  frameRate: number;
  timeUnit: 'frames' | 'seconds';
}

// Usage example:
const config = new SnapConfigBuilder()
  .withPoints(['corner', 'center'], 1.5)
  .withGuides(['horizontal', 'vertical'])
  .withSpacing([10, 20, 50])
  .withDistribution(['horizontal', 'vertical'], 2)
  .build();
