/**
 * IndexedDB databases are persistent on the device and shared between browser tabs.
 *
 * Care must be taken to avoid conflicts between and/or handle updates from across instances.
 *
 * [Detailed IndexedDB Tutorial](https://youtu.be/yZ26CXny3iI?si=Bez4mIbKhS889ZwJ)
 *
 *
 * TODO(pbirch): Provide use with with and/or default error mechanism to just explode and rebuild the entire cache db.
 */

export * from './IndexedDBManager.js';
export * as filecache from './filecache/FileCache.js';
export * as imagecache from './imagecache/ImageCache.js';
export * as imagestore from './imagestore/Imagestore.js';
export * from './table.js';
export * from './types.js';

import { IDBConfig, IDBInstance, IDBPlugin, IDBState } from './types.js';
import {
  TableSchema,
  SingleTableCallback,
  StoreRecord,
  TableNameOf,
  TransactionType,
  TransactionCallback,
} from './types.js';
import { TableNameType } from './types.js';
import { IDBRecovery } from './IDBRecovery.js';

export const DB_UNINITIALIZED_ERROR = new Error('[IDB] Uninitiazlied');

export class IDB implements IDBInstance {
  readonly #config: IDBConfig;
  #state: IDBState = 'uninitialized';
  #openRequest: IDBOpenDBRequest | undefined;
  #error: Error | string | undefined = DB_UNINITIALIZED_ERROR;
  #dbState: IDBDatabase | undefined;
  #recovery: IDBRecovery;
  #plugins: IDBPlugin[] = [];
  #lastModified: number = 0;

  // Main initialization promise
  #initPromise: Promise<void>;
  #initResolver!: (value: void) => void;
  #initRejecter!: (reason: any) => void;

  constructor(config: IDBConfig, plugins: IDBPlugin[] = []) {
    if (config.version == undefined || config.version <= 0)
      throw new Error('version is required');
    if (config.dbName == undefined || config.dbName.length == 0)
      throw new Error('dbName is required');
    this.#config = config;
    this.#recovery = new IDBRecovery();
    console.log('[IDB] constructor()', plugins);
    this.#plugins = plugins;

    // Create initialization promise
    this.#initPromise = new Promise((resolve, reject) => {
      this.#initResolver = resolve;
      this.#initRejecter = reject;
    });

    // Start initialization
    this.init();
  }

  get config(): IDBConfig {
    return this.#config;
  }

  get version(): number {
    return this.#config.version;
  }

  get dbName(): string {
    return this.#config.dbName;
  }

  get state(): IDBState {
    return this.#state;
  }

  get lastModified(): number {
    return this.#lastModified;
  }

  /**
   * Returns a promise that resolves when the cache is ready for use
   */
  async whenReady(): Promise<void> {
    return this.#initPromise;
  }

  get db(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      if (this.#openRequest == undefined || this.#dbState == undefined) {
        reject(DB_UNINITIALIZED_ERROR);
        return;
      }
      if (this.#error != undefined) {
        reject(this.#error);
        return;
      }

      resolve(this.#dbState);
    });
  }

  init(): void {
    try {
      const innerInit = () => {
        if (this.#openRequest != undefined) {
          // TODO(pbirch): Clean up listeners if needed
        }
        const req = indexedDB.open(this.dbName, this.version);

        req.onerror = (ev: Event) => {
          console.error('[IDB] init - Error:', req.error);
          const error = new Error(
            `Failed to initialize cache: ${this.#openRequest?.error}`,
          );
          this.#error = error;
          this.#initRejecter?.(error);
        };

        req.onsuccess = (ev: Event) => {
          this.#error = undefined;
          this.#dbState = req.result;

          console.log('[IDB] init()', this.#plugins);
          for (const plugin of this.#plugins) {
            plugin.init(this);
          }

          this.#initResolver?.();
          this.#state = 'ready';
        };

        req.onblocked = (ev: IDBVersionChangeEvent) => {
          this.#state = 'blocked';
          console.warn('[IDB] init - Blocked:', ev);
        };

        req.onupgradeneeded = (ev: IDBVersionChangeEvent) => {
          console.warn('[IDB] init - Upgrade:', ev);
          this.#upgradeSchema(ev);
        };

        this.#openRequest = req;
        // return req;
      };

      // Reset if requested then initialize
      if (this.#config.resetOnInit) {
        this.deleteDB().then(innerInit);
      } else {
        innerInit();
      }
    } catch (error) {
      this.#error = error as Error;
      this.#initRejecter?.(error);
    }
  }

  #upgradeSchema(ev: IDBVersionChangeEvent) {
    const req = ev.target as IDBOpenDBRequest;
    const db = req.result;
    const prevVersion = ev.oldVersion;
    const nextVersion = ev.newVersion;
    const ctx = {
      ev,
      db,
      prevVersion,
      nextVersion,
    };
    for (const plugin of this.#plugins) {
      plugin.upgradeSchema(ctx);
    }
  }

  private deleteDB(): Promise<void> {
    return new Promise((resolve, reject) => {
      console.log('[IDB] deleteDB()');
      const req = indexedDB.deleteDatabase(this.dbName);
      req.onsuccess = (ev) => {
        this.#error = DB_UNINITIALIZED_ERROR;
        resolve();
      };
      req.onerror = (ev) => {
        console.error('[FileCacheDB] Error:', ev);
        reject(req.error);
      };
      req.onupgradeneeded = (ev) => {
        console.error('[FileCacheDB] OnUpgradeNeeded:', ev);
        reject();
      };
      req.onblocked = (ev) => {
        console.error('[FileCacheDB] OnBlocked:', ev);
        reject();
      };
    });
  }

  async resetDB(): Promise<void> {
    try {
      console.warn('[IDB] resetDB()');
      await this.deleteDB();
      this.init();
    } catch (error) {
      console.warn('[IDB] BEGIN RECOVERY resetDB() - Error:', error);
      const db = await this.#recovery.recoverDatabase(this.#config);
      this.#dbState = db;
    }
    // return this.deleteDB().then(() => {
    //   this.init();
    // });
  }

  /**
   * Execute a readonly transaction
   */
  readonly<Tables extends readonly TableSchema<any, any>[], R>(
    tables: [...Tables],
    callback: TransactionCallback<'readonly', Tables, R>,
  ): Promise<R> {
    return this.#executeTransaction(tables, 'readonly', callback);
  }

  /**
   * Execute a readonly operation on a single table
   */
  readonlyTable<Table extends TableSchema<any, V>, V, R>(
    table: Table,
    callback: SingleTableCallback<'readonly', Table, R>,
  ): Promise<R> {
    return this.readonly([table], (stores) => {
      type StoreName = TableNameOf<Table>;
      return callback(stores[table.name as StoreName] as IDBObjectStore);
    });
  }

  /**
   * Execute a readwrite transaction
   */
  readwrite<Tables extends readonly TableSchema<any, any>[], R>(
    tables: [...Tables],
    callback: TransactionCallback<'readwrite', Tables, R>,
  ): Promise<R> {
    return this.#executeTransaction(tables, 'readwrite', callback);
  }

  /**
   * Execute a readwrite operation on a single table
   */
  readwriteTable<Table extends TableSchema<any, V>, V, R>(
    table: Table,
    callback: SingleTableCallback<'readwrite', Table, R>,
  ): Promise<R> {
    return this.readwrite([table], (stores) => {
      type StoreName = TableNameOf<Table>;
      return callback(stores[table.name as StoreName] as IDBObjectStore);
    });
  }

  /**
   * Internal transaction execution
   */
  #executeTransaction<
    Type extends TransactionType,
    Tables extends readonly TableSchema<any, any>[],
    R,
  >(
    tables: [...Tables],
    mode: Type,
    callback: TransactionCallback<Type, Tables, R>,
  ): Promise<R> {
    return new Promise((resolve, reject) => {
      if (!this.#dbState) {
        reject(new Error('Database not initialized'));
        return;
      }

      const tableNames: string[] = tables.map((t) => t.postfix);
      // console.log('[IDB] #executeTransaction()', tableNames, mode);
      const trx = this.#dbState.transaction(tableNames, mode);

      // Create type-safe store mapping
      const stores = tables.reduce(
        (acc, table) => {
          type TableKey = TableNameType<typeof table>;
          acc[table.name as TableKey] = trx.objectStore(table.postfix);
          return acc;
        },
        {} as StoreRecord<Type, Tables>,
      );

      let result: R;

      Promise.resolve(callback(stores))
        .then((callbackResult) => {
          result = callbackResult;
          if (mode === 'readwrite') {
            this.#lastModified = Date.now();
          }
          // if (result !== undefined) {
          //   this.#lastModified = Date.now();
          // }
        })
        .catch((error) => {
          try {
            trx.abort();
          } catch (error) {
            console.error('[IDB] #executeTransaction() - Error:', error);
          } finally {
            reject(error);
          }
        });

      trx.oncomplete = () => resolve(result);
      trx.onerror = (ev) => reject(ev);
    });
  }
}
