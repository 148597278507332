// import { IRenderable, Point } from '@drawing/index';
import { IRenderable, Point } from '@repo/drawing';

export class SemiCircleCursor implements IRenderable {
  #visible: boolean;
  #position: Point;
  #size: number;
  #color: string;

  constructor(options?: {
    visible?: boolean;
    initialPosition?: Point;
    size?: number;
    // width?: number,
    // height?: number,
    color?: string;
  }) {
    // console.log('Made Cursor');
    this.#visible = options?.visible ?? true;
    this.#position = options?.initialPosition ?? new Point(0, 0);
    this.#size = options?.size ?? 20;
    this.#color = options?.color ?? '#000';
  }

  get visible(): boolean {
    return this.#visible;
  }

  set visible(value: boolean) {
    this.#visible = value;
  }

  get position(): Point {
    return this.#position;
  }

  set position(value: Point) {
    this.#position = value;
  }

  get size(): number {
    return this.#size;
  }

  set size(value: number) {
    this.#size = value;
  }

  get color(): string {
    return this.#color;
  }

  set color(value: string) {
    this.#color = value;
  }

  renderTo(ctx: CanvasRenderingContext2D): void {
    // console.log(`Render Cursor: ${this.#visible}`);
    if (!this.#visible) return;
    const l = this.#position;
    // Radius of the half arc
    const s = this.#size;
    // Crosshair size relative to the arc
    const h = s / 4;
    // console.log(`Render Cursor: ${l.toString()} - ${s}`);
    {
      ctx.save();
      ctx.globalAlpha = 1;
      ctx.lineWidth = 6;
      ctx.strokeStyle = this.#color;
      ctx.beginPath();
      ctx.arc(l.x, l.y, s, 0, Math.PI);
      ctx.stroke();
      ctx.beginPath();
      ctx.moveTo(l.x - h, l.y - h);
      ctx.lineTo(l.x + h, l.y + h);
      ctx.stroke();
      ctx.beginPath();
      ctx.moveTo(l.x + h, l.y - h);
      ctx.lineTo(l.x - h, l.y + h);
      ctx.stroke();
      ctx.restore();
    }
  }
}
