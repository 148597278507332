export type IDBState = string | 'uninitialized' | 'blocked';

export type IDBTableKey = string;

/**
 * Callback types based on transaction type
 */
export type TransactionCallback<
  Type extends TransactionType,
  Tables extends readonly TableSchema<any, any>[],
  R = void,
> = (stores: StoreRecord<Type, Tables>) => Promise<R> | R;

type SingleTableStore<
  Type extends TransactionType,
  Table extends TableSchema<any, any>,
> = StoreRecord<Type, [Table]>[TableNameOf<Table>] & IDBObjectStore;

/**
 * Callback type for single table operations
 */
export type SingleTableCallback<
  Type extends TransactionType,
  Table extends TableSchema<any, any>,
  R = void,
> = (store: SingleTableStore<Type, Table>) => Promise<R> | R;

export interface IDBInstance {
  db: Promise<IDBDatabase>;

  readonly<Tables extends readonly TableSchema<any, any>[], R>(
    tables: [...Tables],
    callback: TransactionCallback<'readonly', Tables, R>,
  ): Promise<R>;

  readwrite<Tables extends readonly TableSchema<any, any>[], R>(
    tables: [...Tables],
    callback: TransactionCallback<'readwrite', Tables, R>,
  ): Promise<R>;
}

export type UpgradeSchemaContext = {
  ev: IDBVersionChangeEvent;
  db: IDBDatabase;
  prevVersion: number;
  nextVersion: number | null;
};

export interface IDBPlugin {
  init(instance: IDBInstance): void;
  upgradeSchema(ctx: UpgradeSchemaContext): void;
}

export interface IDBConfig {
  /**
   * IMPORTANT:
   *
   * This version must always move forward AND have a valid upgradeSchema handler
   */
  version: number;
  dbName: string;
  resetOnInit: boolean | undefined;
}

// Branded type for table names to ensure type safety
export declare const TABLE_NAME_BRAND: unique symbol;
export type TableName<N extends string> = N & {
  readonly [TABLE_NAME_BRAND]: never;
};

/**
 * Creates a type-safe table name
 * This helper ensures table names are unique at the type level
 */
export function tableName<N extends string>(name: N): TableName<N> {
  return name as TableName<N>;
}

// Type to represent the table schema with branded name
export interface TableSchema<N extends string, T> {
  name: TableName<N>;
  postfix: string;
  type: T;
}

// Helper type to extract the data type from a TableSchema
export type TableType<T> = T extends TableSchema<any, infer U> ? U : never;

// Helper type to extract the name from a TableSchema
export type TableNameType<T> =
  T extends TableSchema<infer N, any> ? TableName<N> : never;

// Helper type to build a record of object stores from table schemas
export type StoresRecord<Tables extends readonly TableSchema<any, any>[]> = {
  [T in Tables[number] as TableNameType<T>]: IDBObjectStore;
};

/**
 * Transaction modes with type inference
 */
export type TransactionType = 'readonly' | 'readwrite';

/**
 * Type-safe store for readonly transactions
 */
export type ReadonlyStore = Pick<IDBObjectStore, keyof ReadonlyOperations>;

/**
 * Type-safe store for readwrite transactions
 */
export type ReadWriteStore = Pick<IDBObjectStore, keyof ReadWriteOperations>;

/**
 * Helper type to extract table name from schema
 */
export type TableNameOf<T extends TableSchema<any, any>> = T['name'];

// Helper to extract literal string type from TableName
export type TableNameToString<T> = T extends TableName<infer S> ? S : never;

/**
 * Store record types based on transaction type for table arrays
 */
export type StoreRecord<
  Type extends TransactionType,
  Tables extends readonly TableSchema<any, any>[],
> = {
  [T in Tables[number] as
    | TableNameToString<T['name']>
    | T['name']]: Type extends 'readwrite'
    ? IDBObjectStore & ReadWriteOperations
    : IDBObjectStore & ReadonlyOperations;
};

/**
 * Operations allowed in readonly transactions
 */
export interface ReadonlyOperations {
  get: IDBObjectStore['get'];
  getAll: IDBObjectStore['getAll'];
  getAllKeys: IDBObjectStore['getAllKeys'];
  count: IDBObjectStore['count'];
  openCursor: IDBObjectStore['openCursor'];
  index: IDBObjectStore['index'];
}

/**
 * Operations allowed in readwrite transactions
 */
export interface ReadWriteOperations extends ReadonlyOperations {
  put: IDBObjectStore['put'];
  add: IDBObjectStore['add'];
  delete: IDBObjectStore['delete'];
  clear: IDBObjectStore['clear'];
}

export async function asyncReq<T>(req: IDBRequest<T>): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    req.onsuccess = (ev) => {
      resolve(req.result);
    };
    req.onerror = (ev) => {
      reject(ev);
    };
  });
}
