import { EnhancedImage } from '../../../database/src/imagestore/v1-types.js';
import { BaseBandLayout } from './BaseBandLayout';
import { ReadonlyAppState } from '../AppState';
import { ImagePreview, ImageWorkflowOverlay, TileBorder } from '../shapes';
import { StateManagerImpl } from '@repo/state-manager';

export class BannerLayout extends BaseBandLayout {
  private readonly PREVIEW_COUNT = 1;

  getPreviewCount(): number {
    return this.PREVIEW_COUNT;
  }

  async updatePreviews(
    state: ReadonlyAppState,
    previews: ImagePreview[],
  ): Promise<void> {
    if (!state.bandLayout || state.bandLayout.type !== 'banner') {
      previews.forEach((preview) => preview.reset());
      return;
    }

    // Only update the first preview for banner layout
    const preview = previews[0];
    if (!preview) return;

    const workflow = this.getWorkflowForIndex(state, 0);

    if (workflow?.stage !== 'complete' && workflow?.bandImage?.cacheKey) {
      preview.setImage(workflow.bandImage.cacheKey);
    } else {
      const imageId = state.bandLayout.data;
      if (!imageId) {
        preview.reset();
        return;
      }
      const stateManager = StateManagerImpl.getInstance();
      const imageStore = await stateManager.database.imageStore();
      const image = await imageStore.getImage(imageId);
      if (!image) {
        preview.reset();
        return;
      }
      // const layoutImage = state.bandLayout.data as EnhancedImage;
      if (image?.cacheKey) {
        preview.setImage(image.cacheKey);
      } else {
        preview.reset();
      }
    }

    // Reset remaining previews
    previews.slice(this.PREVIEW_COUNT).forEach((preview) => preview.reset());
  }

  updateOverlays(
    state: ReadonlyAppState,
    overlays: ImageWorkflowOverlay[],
  ): void {
    // Only update the first overlay for banner layout
    const overlay = overlays[0];
    if (!overlay) return;

    const workflowId = state.selectedWorkflows[0];
    if (!workflowId) {
      overlay.applyState({
        ...state,
        selectedIndex: undefined,
      });
    } else {
      overlay.applyState({
        ...state,
        selectedIndex: 0,
      });
    }

    // Reset remaining overlays
    overlays.slice(this.PREVIEW_COUNT).forEach((overlay) => {
      overlay.applyState({
        ...state,
        selectedIndex: undefined,
      });
    });
  }

  updateBorders(state: ReadonlyAppState, borders: TileBorder[]): void {
    // Only update the first border for banner layout
    const border = borders[0];
    if (!border) return;

    border.selected = state.selectedIndex === 0;
    border.hovered = state.hoveredIndex === 0;
    border.visible = !state.previewMode;

    // Hide remaining borders
    borders.slice(this.PREVIEW_COUNT).forEach((border) => {
      border.visible = false;
      border.selected = false;
      border.hovered = false;
    });
  }
}
