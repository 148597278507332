import {
  MouseHandler,
  PositionEventData,
  InputEventNormalizer,
  NormalizedInputEventHandlers,
  InputEventHandlers,
} from './common/index.js';
import { createRoot } from 'solid-js';
// import { useAppStorage } from '@repo/storage';
import { fetchImageAsArrayBuffer, imageFromBuffer } from '@repo/drawing';
import { Point } from '@repo/drawing';
import {
  MutableAppState,
  PersistedAppState,
  ReadonlyAppState,
  toPersistedState,
} from './AppState.js';
import { Renderer } from './Renderer.js';
import { Container } from './Container.js';
import { showImageSelector } from './ImageSelector.js';
import { IDB, filecache, imagecache, imagestore } from '@repo/database';
import { SnapEngine, SnapBehavior, BandConfig } from '@repo/snap-engine';
import { StateManager, StateManagerImpl } from '@repo/state-manager';
import { DesignerError, DesignerErrorCode } from './DesignerError.js';
import {
  ImageWorkflow,
  ImageWorkflowManager,
  ProcessStage,
} from './ImageWorkflowManager.js';
// import { SerializedLayout } from './Layouts';
import {
  BandInstance,
  BandInstanceTypes,
  BannerLayoutV1,
  DesignLayouts,
  OG7LayoutV1,
  SerializedLayout,
} from '../../database/src/imagestore/v1-types.js';
import { Logger } from '@repo/logger';
import { sleep } from '@repo/shared';
import { useAppStorage } from '../../ui/docs/backup/ThemeProvider copy';
import { StorageRef } from '@repo/shared';

export interface ControllerProps {
  appState: MutableAppState;
  container: Container;
  renderer: Renderer;
  onControllerStateChange?: (state: PersistedAppState) => void;
}

export interface ControllerContext {
  appState?: PersistedAppState;
}

export class Controller {
  readonly #container: Container;
  readonly #renderer: Renderer;
  readonly #stateManager: StateManager;
  readonly #snapEngine: SnapEngine;
  readonly #workflowManager: ImageWorkflowManager;
  #state: MutableAppState;
  // #databaseReady: boolean = false;
  #lastCacheUpdate: number = -1;
  private readonly logger = new Logger('Controller');
  private onControllerStateChange?: (state: PersistedAppState) => void;
  private listners = new Map<string, Function>();
  // private storage = StorageRef<ControllerContext>;

  constructor(props: ControllerProps) {
    this.#container = props.container;
    this.#renderer = props.renderer;
    this.#stateManager = StateManagerImpl.getInstance();
    // this.#state = props.appState;

    const storeData = localStorage.getItem('controller.context');
    if (storeData) {
      const storeValue: PersistedAppState = JSON.parse(storeData);
      const paramsValue: MutableAppState = props.appState;
      // const {
      //   band,
      //   bandLayout }
      const appState: MutableAppState = {
        ...props.appState,
        ...storeValue,
      };
      this.#state = appState;
    } else {
      this.#state = props.appState;
    }
    localStorage.setItem(
      'controller.context',
      JSON.stringify(toPersistedState(this.#state)),
    );
    // this.storage = StorageRef.localStorage<ControllerContext>(
    //   'controller',
    //   'context',
    //   { appState: this.#state },
    // );

    if (props.onControllerStateChange) {
      this.listners.set('__inner__', props.onControllerStateChange);
    }
    this.onControllerStateChange = (state) => {
      // const persistenceState: PersistedAppState = {
      //   ...this.#state,
      // };
      localStorage.setItem(
        'controller.context',
        JSON.stringify(toPersistedState(this.#state)),
      );
      for (const [_key, listener] of this.listners.entries()) {
        listener(state);
      }
    };

    this.#snapEngine = this.createSnapEngine();
    this.setupEventHandlers();
    this.#stateManager.database.db.whenReady().then(() => {
      console.log('[Controller] Database ready');
      this.updateCacheStats();
    });
    const imageStore = this.#stateManager.database.imageStore();
    this.#workflowManager = new ImageWorkflowManager({
      preprocessing: 100, // Faster preprocessing
      uploading: 100, // Longer upload simulation
      caching: 100,
      thumbnails: 100,
      variants: 100,
    });
    // Initialize workflow state
    if (!this.#state.activeWorkflows) {
      this.#state.activeWorkflows = new Map();
    }
    if (!this.#state.band) {
      this.initializeNewBand();
    }
    // this.setupRenderCallback();
  }

  public registerListener(
    key: string,
    listener: (state: MutableAppState) => void,
  ): void {
    this.listners.set(key, listener);
  }

  get state(): MutableAppState {
    // this.logger.info('Getting state', {
    //   band: this.#state.band,
    //   layout: this.#state.bandLayout,
    // });
    return this.#state;
  }

  set state(value: MutableAppState) {
    this.#state = value;
  }

  private setupEventHandlers(): void {
    const rawHanlders = new InputEventHandlers({});
    const normalizedHandlers = new NormalizedInputEventHandlers({
      onHoverHandler: async (_: any, ext: PositionEventData) => {
        await this.updateCursorPosition(ext.point);
        this.render();
      },
      onSelectHandler: async (_: any, ext: PositionEventData) => {
        await this.updateCursorPosition(ext.point);
        // await this.handleSelection(ext);
        await this.setSelected(ext.point);
        this.render();
      },
      onLeaveHandler: async (_: any, ext: PositionEventData) => {
        await this.updateCursorPosition(ext.point);
        this.render();
      },
    });
    const normalizer = new InputEventNormalizer({
      bandSize: this.#container.bandSize,
      normalizedEventHandlers: normalizedHandlers,
    });
    this.#container.registerEventHandlers(rawHanlders);
    this.#container.registerEventHandlers(normalizer.inputEventHandlers);
    const renderCallback = async (
      c: Container,
      ctx: CanvasRenderingContext2D,
    ) => {
      // console.warn('[Controller] Render callback:', c.fps);
      // BEGIN - HOT LOOP
      this.#state.fps = c.fps;
      // Check and update cache if needed
      if (this.shouldUpdateCache()) {
        await this.updateCacheStats();
      }
      // Apply state and render - always render even if state hasn't changed
      this.#renderer.applyState(this);
      this.#renderer.renderTo(ctx);
      // END   - HOT LOOP
    };
    this.#container.registerRendererCallback(renderCallback);
  }

  private async updateCursorPosition(point: Point): Promise<void> {
    if (this.#container.bandSize.containsPoint(point)) {
      this.#state.cursorPosition = point;
      this.#state.hoveredIndex = this.#indexAt(point);

      // Expose snap engine for visualization
      this.#state.snapEngine = this.#snapEngine;
      // Add debugging for snap calculation
      const snapResult = this.#snapEngine.calculateSnap(point);

      this.#state.snapResult = snapResult;
    } else {
      this.#state.cursorPosition = undefined;
      this.#state.hoveredIndex = undefined;
      this.#state.snapResult = undefined;
    }
  }

  reset(): void {
    // createRoot(() => {
    // Reset selected workflows array
    for (var i = 0; i < this.state.selectedWorkflows.length; i++) {
      this.state.selectedWorkflows[i] = undefined;
    }

    // Clear workflows map
    this.state.activeWorkflows = new Map();

    // Clear interaction state
    this.state.previewMode = false;
    this.state.cursorPosition = undefined;
    this.state.hoveredIndex = undefined;
    this.state.selectedIndex = undefined;
    this.state.snapResult = undefined;

    // Clear error state
    this.state.lastError = undefined;

    // Build a new band instance to edit upon
    this.initializeNewBand();
  }

  public updateSnapSettings(
    config: Partial<{
      snapThreshold: number;
      behavior: Partial<SnapBehavior>;
      bandConfig: Partial<BandConfig>;
    }>,
  ): void {
    this.#snapEngine.setConfig(config);
    // Force a rerender
    this.render();
  }

  private shouldUpdateCache(): boolean {
    if (!this.#stateManager.database.isInitialized) return false;

    return (
      this.#lastCacheUpdate !== this.#stateManager.database.db.lastModified
    );
  }

  private async updateCacheStats(): Promise<void> {
    try {
      const stateManager = StateManagerImpl.getInstance();
      this.#lastCacheUpdate = stateManager.database.db.lastModified;
      const imageCache = await stateManager.database.imageCache();
      const stats = await imageCache.getStats();
      // this.#lastCacheUpdate = this.#stateManager.database.db.lastModified;
      // const stats = await this.#stateManager.database.imageCache.getStats();

      this.#state.cachedImages = stats.totalItems;
      this.#state.cacheSize = stats.totalSize;
    } catch (ex: unknown) {
      try {
        await this.#stateManager.database.db.resetDB();
      } catch (ex) {
        console.error('[Controller] Failed to update cache stats:', ex);
        throw new DesignerError(
          'Failed to update cache stats',
          DesignerErrorCode.CACHE_ERROR,
        );
      }
    }

    this.render();
  }

  // Move the internal update to a callback that can be attached to the store so
  // this can be moved onto the image cache or into drawing
  async cacheImageFromBuffer(
    data: ArrayBuffer,
    type: string,
  ): Promise<string | undefined> {
    try {
      const img = await imageFromBuffer(data, type);
      const config: imagecache.ImagePreprocessConfig = { image: img };
      const stateManager = StateManagerImpl.getInstance();
      const imageCache = await stateManager.database.imageCache();

      const key = await imageCache.store(data, type, config, {
        onProgress: async (progress: number) => {
          this.#state.cacheProgress = Math.round(progress * 100);
        },
      });

      await this.updateCacheStats();
      return key;
    } catch (error) {
      this.#state.lastError =
        error instanceof Error ? error : new Error('Cache operation failed');
      return undefined;
    }
  }

  // Helper methods moved to the bottom
  #indexAt(target: Point): number {
    return Number.parseInt(
      '' + ((target.y / this.#container.bandSize.width) % 7),
    );
  }

  render() {
    // createRoot(() => {
    this.#renderer.applyState(this);

    // Schedule cleanup of completed workflows
    if (this.#state.activeWorkflows.size > 0) {
      setTimeout(() => this.cleanupWorkflows(), 5000);
    }
    // });
  }

  get database(): IDB {
    return this.#stateManager.database.db;
  }

  async fileCache(): Promise<filecache.DefaultFileCache<undefined>> {
    const stateManager = StateManagerImpl.getInstance();
    return await stateManager.database.fileCache();
    // return this.#stateManager.database.fileCache;
  }

  async imageCache(): Promise<imagecache.ImageCache> {
    const stateManager = StateManagerImpl.getInstance();
    return await stateManager.database.imageCache();
    // return this.#stateManager.database.imageCache;
  }

  async imageStore(): Promise<imagestore.ImageStore> {
    const stateManager = StateManagerImpl.getInstance();
    return await stateManager.database.imageStore();
    // return this.#stateManager.database.imageStore;
  }

  private async saveBand(band: BandInstance): Promise<void> {
    const imageStore = await this.imageStore();
    await imageStore.createBand(band);
  }

  private async createBandOG7(): Promise<BandInstance> {
    const imageStore = await this.imageStore();
    const newBand = await imageStore.createBand({
      type: {
        layout: 'og7',
        version: 1,
      } as BandInstanceTypes,
      name: '',
      description: '',
      secret: '',
      isPrivate: true,
      tags: [],
      categories: [],
      metadata: {},
    });
    const bandLayout = {
      id: crypto.randomUUID(),
      type: 'og7',
      version: 1,
      bandId: newBand.id,
      data: Array(7).fill(undefined),
    } as OG7LayoutV1;
    await imageStore.storeBandData(bandLayout);
    this.state.band = newBand;
    this.state.bandLayout = bandLayout;
    this.onControllerStateChange?.(this.state);
    return newBand;
  }

  private async createBandBanner(): Promise<BandInstance> {
    const imageStore = await this.imageStore();
    const newBand = await imageStore.createBand({
      type: {
        layout: 'og7',
        version: 1,
      },
      name: '',
      description: '',
      secret: '',
      isPrivate: true,
      tags: [],
      categories: [],
      metadata: {},
    });
    const bandLayout = {
      id: crypto.randomUUID(),
      type: 'banner',
      version: 1,
      bandId: newBand.id,
      data: undefined,
    } as BannerLayoutV1;
    await imageStore.storeBandData(bandLayout);
    this.state.band = newBand;
    this.state.bandLayout = bandLayout;
    this.onControllerStateChange?.(this.state);
    return newBand;
  }

  async createNewBand(layout: DesignLayouts): Promise<BandInstance> {
    switch (layout) {
      case 'og7':
        return this.createBandOG7();
      case 'banner':
      default:
        return this.createBandBanner();
    }
  }

  async createBand(
    band: BandInstance,
    layout: SerializedLayout,
  ): Promise<void> {
    const imageStore = await this.imageStore();
    await this.saveBand(band);
    await imageStore.storeBandData(layout);
  }

  async getBand(bandId: string): Promise<BandInstance | undefined> {
    const imageStore = await this.imageStore();
    return imageStore.getBand(bandId);
  }

  async getBandLayout(bandId: string): Promise<SerializedLayout | undefined> {
    const imageStore = await this.imageStore();
    return imageStore.getBandData(bandId);
  }

  async saveCurrentBand(): Promise<BandInstance | undefined> {
    const imageStore = await this.imageStore();
    if (!this.state.band) return;
    if (!this.state.bandLayout) return;

    this.logger.info('Saving current band', {
      band: this.state.band,
      layout: this.state.bandLayout,
    });
    await this.saveBand(this.state.band);
    await imageStore.storeBandData(this.state.bandLayout);
    this.onControllerStateChange?.(this.state);
    return this.state.band;
  }

  private async setSelected(point: Point): Promise<void> {
    const index = this.#indexAt(point);
    if (this.#state.selectedIndex != index) {
      // Toggle selected for select position
      this.#state.selectedIndex = index;
    } else {
      // On the second click check overlay for actions including loading an image
      await this.updateCursorPosition(point);
      // TODO(pbirch): No overlays in yet...
      // await this.#onSelectImageHandler(ext.point, async (file?: File) => {
      //     console.warn('Loaded File:', file);
      // });
      const result = await this.selectLocalImage();
      if (result != undefined) {
        // this.#state.selectedImage = result;
      }
    }
  }

  async updateLayoutWithImage(
    index: number,
    image: imagestore.EnhancedImage,
    bandKey: string,
  ): Promise<void> {
    // Run inside of createRoot for reactivity

    // Update the workflow state
    const workflowId = this.#state.selectedWorkflows[index];
    if (workflowId) {
      const workflow = this.#state.activeWorkflows.get(workflowId);
      if (workflow) {
        // Update workflow with final image data
        workflow.stage = 'complete';
        workflow.original = image;
        workflow.bandKey = bandKey;

        // Trigger rerender
        this.render();
      }
    }
  }

  public getWorkflowForIndex(index: number): ImageWorkflow | undefined {
    if (index < 0 || index > 6) return undefined;
    const workflowId = this.#state.selectedWorkflows[index];
    if (!workflowId) return undefined;
    return this.#state.activeWorkflows.get(workflowId);
  }

  initWorkflow(
    id: string,
    args: { index?: number; workflow: ImageWorkflow },
  ): string {
    // const workflowId = args.workflow?.id ?? crypto.randomUUID();
    // Set up workflow tracking
    // this.#state.activeWorkflows.set(id, {
    //   id,
    //   // file,
    //   stage: args.workflow?.stage ?? 'initial',
    //   progress: args.workflow?.progress ?? 0,
    // });
    this.#state.activeWorkflows.set(id, args.workflow);
    // Link workflow to selected position
    if (args.index !== undefined) {
      this.#state.selectedWorkflows[args.index] = id;
    }
    return id;
  }

  private async selectLocalImage(): Promise<void> {
    const file = await showImageSelector({});
    if (!file) return;

    // let workflowId = crypto.randomUUID();
    const selectedIndex = this.#state.selectedIndex ?? 0;

    await this.processImage(file, selectedIndex);
  }

  // Add this to the existing cleanupWorkflows method
  public cleanupWorkflows(): void {
    createRoot(() => {
      const workflows = this.#state.activeWorkflows;
      const toDelete = new Set<string>();

      // Identify completed workflows that can be cleaned up
      for (const [id, workflow] of workflows) {
        if (workflow.stage === 'complete' || workflow.stage === 'error') {
          // Check if workflow is still referenced
          const isReferenced = this.#state.selectedWorkflows.includes(id);
          if (!isReferenced) {
            toDelete.add(id);
          }
        }
      }

      // Remove workflows and trigger rerender if needed
      if (toDelete.size > 0) {
        toDelete.forEach((id) => workflows.delete(id));
        this.render();
      }
    });
  }

  async cacheImageFromFile(file: File): Promise<string | undefined> {
    try {
      const buffer = await this.readFileAsBuffer(file);
      return await this.cacheImageFromBuffer(buffer, file.type);
    } catch (error) {
      await this.handleCacheError(error);
      return undefined;
    }
  }

  private async readFileAsBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as ArrayBuffer);
      reader.onerror = () => reject(reader.error);
      reader.readAsArrayBuffer(file);
    });
  }

  private async handleCacheError(error: unknown): Promise<void> {
    console.error('[Controller] Cache operation failed:', error);
    this.#state.lastError =
      error instanceof Error ? error : new Error('Cache operation failed');
    this.render();
  }

  private createSnapEngine(): SnapEngine {
    const bandSize = this.#container.bandSize;
    const snapEngine = new SnapEngine({
      snapThreshold: 20, // Increased for easier testing
      behavior: {
        points: {
          enabled: true,
          types: ['corner', 'edge', 'center', 'grid'],
          strength: 1,
          threshold: 20, // Match snapThreshold
        },
        guides: {
          enabled: true,
          types: ['horizontal', 'vertical'],
          strength: 1,
        },
        spacing: {
          enabled: true,
          preferredGaps: [bandSize.width / 7], // Snap to tile heights
          strength: 1,
        },
        distribution: {
          enabled: true,
          types: ['horizontal', 'vertical'],
          strength: 1,
        },
      },
      bandConfig: {
        width: bandSize.width,
        height: bandSize.height,
        orientation:
          bandSize.width > bandSize.height ? 'horizontal' : 'vertical',
        divisions: [2, 3, 4, 7], // Include common divisions and the 7 tile divisions
      },
    });

    // Debug log the initialization
    console.log('[Controller] SnapEngine initialized:', {
      bandSize,
      preCalculatedPoints: snapEngine['preCalculatedPoints'],
      behavior: snapEngine['behavior'],
    });

    return snapEngine;
  }

  private async updateLayoutWithWorkflow(
    // workflow: ImageWorkflow,
    imageId: string,
    selectedIndex: number,
  ): Promise<void> {
    if (imageId === '') {
      this.logger.warn('Missing required workflow data for layout update', {
        imageId,
        selectedIndex,
      });
      return;
    }
    if (!this.state.band || !this.state.bandLayout) {
      this.logger.warn('Missing required controller data for layout update', {
        imageId,
        selectedIndex,
      });
      return;
    }

    try {
      // Update state based on layout type
      if (this.state.bandLayout.type === 'og7') {
        await this.updateOG7Layout(imageId, selectedIndex);
      } else if (this.state.bandLayout.type === 'banner') {
        await this.updateBannerLayout(imageId);
      } else {
        this.logger.warn('Unknown layout type', {
          layout: this.state.bandLayout,
        });
        // Default to OG7 if layout type not recognized
        await this.initializeOG7Layout();
        await this.updateOG7Layout(imageId, selectedIndex);
      }

      // Save updated layout to database
      await this.saveCurrentLayout();

      // Trigger rerender
      this.render();
    } catch (error) {
      this.logger.error('Failed to update layout', {
        error,
        imageId,
        selectedIndex,
      });
      throw error;
    }
  }

  private async updateOG7Layout(
    // workflow: ImageWorkflow,
    imageId: string,
    selectedIndex: number,
  ): Promise<void> {
    if (!this.state.bandLayout) return;

    const og7Layout = this.state.bandLayout as imagestore.OG7LayoutV1;
    if (!Array.isArray(og7Layout.data)) {
      og7Layout.data = new Array(7).fill(undefined);
    }

    // Update the specific slot with the new image
    og7Layout.data[selectedIndex] = imageId;
    this.state.bandLayout = og7Layout;

    this.logger.info('Updated OG7 layout', {
      selectedIndex,
      imageId: imageId,
    });
    this.onControllerStateChange?.(this.state);
  }

  private async updateBannerLayout(imageId: string): Promise<void> {
    if (!this.state.bandLayout) return;

    const bannerLayout = this.state.bandLayout as imagestore.BannerLayoutV1;
    bannerLayout.data = imageId;
    this.state.bandLayout = bannerLayout;

    this.logger.info('Updated banner layout', {
      imageId: imageId,
    });
    this.onControllerStateChange?.(this.state);
  }

  private async saveCurrentLayout(): Promise<void> {
    if (!this.state.band || !this.state.bandLayout) {
      this.logger.warn('No current band or layout to save');
      return;
    }

    try {
      const imageStore = await this.imageStore();
      await imageStore.storeBandData(this.state.bandLayout);
      this.logger.info('Saved layout to database', {
        bandId: this.state.band.id,
        band: this.state.band,
        layoutType: this.state.bandLayout.id,
        layout: this.state.bandLayout,
      });
      this.onControllerStateChange?.(this.state);
    } catch (error) {
      this.logger.error('Failed to save layout', { error });
      throw error;
    }
  }

  private async initializeOG7Layout(): Promise<void> {
    if (!this.state.band) return;

    const og7Layout = {
      id: crypto.randomUUID(),
      type: 'og7',
      version: 1,
      bandId: this.state.band.id,
      data: new Array(7).fill(undefined),
      metadata: {},
    } as imagestore.OG7LayoutV1;

    this.state.bandLayout = og7Layout;
    await this.saveCurrentLayout();

    this.logger.info('Initialized new OG7 layout');
    this.onControllerStateChange?.(this.state);
  }

  // Update the image processing workflow to use the new layout management
  private async handleWorkflowComplete(
    workflow: ImageWorkflow,
    selectedIndex: number,
  ): Promise<void> {
    // workflow.bandImage.originalId is the actual image id
    this.logger.info('Image processing complete', {
      workflowId: workflow.id,
      workflow,
    });
    const newImageId = workflow.original?.id;

    if (workflow.stage === 'complete' && newImageId) {
      await this.updateLayoutWithWorkflow(newImageId, selectedIndex);
    }
  }

  // This would be called from your image processing code
  // public async processImage(file: File, selectedIndex: number): Promise<void> {
  //   const workflowId = crypto.randomUUID();

  //   try {
  //     // Initialize workflow
  //     this.initWorkflow(workflowId, {
  //       index: selectedIndex,
  //       workflow: {
  //         id: workflowId,
  //         stage: 'preprocessing',
  //         progress: 0,
  //       },
  //     });

  //     // Process the image
  //     const workflow = await this.#workflowProcessor.processFile(
  //       file,
  //       workflowId,
  //       (updatedWorkflow) => {
  //         // Update workflow state
  //         this.state.activeWorkflows.set(workflowId, updatedWorkflow);
  //         this.render();
  //       },
  //       selectedIndex,
  //     );

  //     // Handle completed workflow
  //     await this.handleWorkflowComplete(workflow, selectedIndex);
  //   } catch (error) {
  //     this.logger.error('Image processing failed', { error });
  //     this.state.lastError =
  //       error instanceof Error ? error : new Error('Image processing failed');
  //     this.render();
  //     throw error;
  //   }
  // }

  // async loadBand(id: string): Promise<void> {
  //   const band = await this.getBand(id);
  //   if (!band) {
  //     console.warn('Band not found:', id);
  //     return;
  //   }

  //   const layout = await this.imageStore.getBandData(id);
  //   if (!layout) {
  //     console.warn('Band layout not found:', id);
  //     return;
  //   }

  //   this.state.band = band;
  //   this.state.bandLayout = layout;
  // }

  public async loadBand(bandId: string): Promise<void> {
    this.logger.info('Loading band', { bandId });

    const imageStore = await this.imageStore();
    const band = await imageStore.getBand(bandId);
    if (!band) {
      this.logger.error('Band not found', { bandId });
      await this.initializeNewBand();
      return;
      // throw new Error(`Band not found: ${bandId}`);
    }

    const layout = await imageStore.getBandDataByBandId(band.id);
    if (!layout) {
      throw new Error(`Band layout not found: ${bandId}`);
    }

    this.state.band = band;
    this.state.bandLayout = layout;

    this.logger.info('Band loaded successfully', { band, layout });
    this.render();
    this.onControllerStateChange?.(this.state);
  }

  public async initializeNewBand(): Promise<void> {
    this.logger.info('Initializing new band');

    try {
      // Create new band instance
      // const band: imagestore.BandInstance = {
      //   id: crypto.randomUUID(),
      //   type: {
      //     layout: 'og7',
      //     version: 1,
      //   } as imagestore.BandInstanceTypes,
      //   name: '',
      //   description: '',
      //   secret: '',
      //   isPrivate: true,
      //   tags: [],
      //   categories: [],
      //   metadata: {},
      //   created: Date.now(),
      //   lastAccessed: Date.now(),
      //   lastUpdated: Date.now(),
      // };

      const imageStore = await this.imageStore();
      const newBand = await imageStore.createBand({
        // id: crypto.randomUUID(),
        type: {
          layout: 'og7',
          version: 1,
        } as imagestore.BandInstanceTypes,
        name: '',
        description: '',
        secret: '',
        isPrivate: true,
        tags: [],
        categories: [],
        metadata: {},
        // created: Date.now(),
        // lastAccessed: Date.now(),
        // lastUpdated: Date.now(),
      });

      // Create default OG7 layout
      const layout = {
        id: crypto.randomUUID(),
        type: 'og7',
        version: 1,
        bandId: newBand.id,
        data: new Array(7).fill(undefined),
        metadata: {},
      } as imagestore.OG7LayoutV1;

      // Save to database
      // await this.imageStore.whenReady();
      // createRoot(async () => {
      try {
        await imageStore.storeBandData(layout);
      } catch (error) {
        this.logger.error('Failed to initialize new band data', { error });
        throw error;
      }
      // });
      await sleep(0);

      // Update state
      this.state.band = newBand;
      this.state.bandLayout = layout;
      this.onControllerStateChange?.(this.state);

      this.logger.info('New band initialized', { newBand, layout });
      this.render();
      this.onControllerStateChange?.(this.state);
    } catch (error) {
      this.logger.error('Failed to initialize new band', { error });
      throw error;
    }
  }

  private async processImage(file: File, selectedIndex: number): Promise<void> {
    const workflowId = crypto.randomUUID();

    try {
      // Ensure we have a band and layout initialized
      if (!this.state.band || !this.state.bandLayout) {
        this.logger.info('No band initialized, creating new band');
        await this.initializeNewBand();
      }

      // Initialize workflow
      this.initWorkflow(workflowId, {
        index: selectedIndex,
        workflow: {
          id: workflowId,
          stage: 'preprocessing',
          progress: 0,
        },
      });

      this.logger.info('Processing image', {
        file,
        workflowId,
        selectedIndex,
      });
      // Process the image
      const workflow = await this.#workflowManager.processFile(
        file,
        workflowId,
        (updatedWorkflow) => {
          this.state.activeWorkflows.set(workflowId, updatedWorkflow);
          this.render();
        },
        selectedIndex,
      );

      // Handle completed workflow
      await this.handleWorkflowComplete(workflow, selectedIndex);
      this.onControllerStateChange?.(this.state);
    } catch (error) {
      this.logger.error('Image processing failed', { error });
      this.state.lastError =
        error instanceof Error ? error : new Error('Image processing failed');
      this.render();
      this.onControllerStateChange?.(this.state);
      throw error;
    }
  }
}
