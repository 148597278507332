import { IRenderable, Rect } from '@repo/drawing';

export class ClearRect implements IRenderable {
  #bandSize: Rect;

  constructor(params: { bandSize: Rect }) {
    this.#bandSize = params.bandSize;
  }

  renderTo(ctx: CanvasRenderingContext2D): void {
    const r = this.#bandSize;
    ctx.save();
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(r.left, r.top, r.width, r.height);
    ctx.restore();
  }
}
