import {
  Table,
  TableBuilders,
  SchemaTableDefinition,
  SchemaManager,
} from '../table.js';
import { TableName, UpgradeSchemaContext } from '../types.js';
import {
  BandInstance,
  EnhancedImage,
  ImageVariant,
  ImageRelationship,
  SerializedLayout,
} from './v1-types.js';

// Define literal string types for table names
export const TABLE_NAMES = {
  BANDS: 'bands',
  BAND_DATA: 'bandData',
  IMAGES: 'images',
  IMAGE_VARIANTS: 'imageVariants',
  IMAGE_RELATIONSHIPS: 'imageRelationships',
} as const;

export interface SchemaV1 {
  tables: {
    bands: Table<'bands', BandInstance>;
    bandData: Table<'bandData', SerializedLayout>;
    images: Table<'images', EnhancedImage>;
    imageVariants: Table<'imageVariants', ImageVariant>;
    imageRelationships: Table<'imageRelationships', ImageRelationship>;
  };
}

// Define the schema structure
const SCHEMA_DEFINITIONS = {
  bands: {
    name: TABLE_NAMES.BANDS as TableName<'bands'>,
    storeName: 'bands',
    keyPath: 'id',
    indexes: [
      { name: 'type', keyPath: 'type' },
      { name: 'name', keyPath: 'name', options: { unique: false } },
      { name: 'created', keyPath: 'created', options: { unique: false } },
      { name: 'lastAccessed', keyPath: 'lastAccessed' },
      { name: 'lastUpdated', keyPath: 'lastUpdated' },
      { name: 'isPrivate', keyPath: 'isPrivate' },
      { name: 'tags', keyPath: 'tags', options: { multiEntry: true } },
      {
        name: 'categories',
        keyPath: 'categories',
        options: { multiEntry: true },
      },
    ],
  } as SchemaTableDefinition<'bands', BandInstance>,

  bandData: {
    name: TABLE_NAMES.BAND_DATA as TableName<'bandData'>,
    storeName: 'band-data',
    keyPath: 'id',
    indexes: [
      // { name: 'id', keyPath: 'id' },
      { name: 'bandId', keyPath: 'bandId' },
      { name: 'type', keyPath: 'type' },
      { name: 'created', keyPath: 'created' },
    ],
  } as SchemaTableDefinition<'bandData', SerializedLayout>,

  images: {
    name: TABLE_NAMES.IMAGES as TableName<'images'>,
    storeName: 'images',
    keyPath: 'id',
    indexes: [
      { name: 'hash', keyPath: 'hash', options: { unique: true } },
      { name: 'created', keyPath: 'created' },
      { name: 'lastAccessed', keyPath: 'lastAccessed' },
      { name: 'status', keyPath: 'status' },
      { name: 'sourceType', keyPath: 'source.type' },
      { name: 'originalId', keyPath: 'originalId' },
      { name: 'needsSync', keyPath: 'needsSync' },
      { name: 'hasLocalCache', keyPath: 'hasLocalCache' },
      { name: 'hasThumbnails', keyPath: 'hasThumbnails' },
      { name: 'tags', keyPath: 'tags', options: { multiEntry: true } },
      {
        name: 'categories',
        keyPath: 'categories',
        options: { multiEntry: true },
      },
      { name: 'quality', keyPath: 'quality' },
    ],
  } as SchemaTableDefinition<'images', EnhancedImage>,

  imageVariants: {
    name: TABLE_NAMES.IMAGE_VARIANTS as TableName<'imageVariants'>,
    storeName: 'image-variants',
    keyPath: 'id',
    indexes: [
      { name: 'originalId', keyPath: 'originalId' },
      { name: 'type', keyPath: 'type' },
      { name: 'created', keyPath: 'created' },
    ],
  } as SchemaTableDefinition<'imageVariants', ImageVariant>,

  imageRelationships: {
    name: TABLE_NAMES.IMAGE_RELATIONSHIPS as TableName<'imageRelationships'>,
    storeName: 'image-relationships',
    keyPath: 'id',
    indexes: [
      { name: 'sourceId', keyPath: 'sourceId' },
      { name: 'targetId', keyPath: 'targetId' },
      { name: 'type', keyPath: 'type' },
    ],
  } as SchemaTableDefinition<'imageRelationships', ImageRelationship>,
};

export function createV1Tables(prefix: string): SchemaV1['tables'] {
  return {
    bands: Table.fromDefinition(prefix, SCHEMA_DEFINITIONS.bands),
    bandData: Table.fromDefinition(prefix, SCHEMA_DEFINITIONS.bandData),
    images: Table.fromDefinition(prefix, SCHEMA_DEFINITIONS.images),
    imageVariants: Table.fromDefinition(
      prefix,
      SCHEMA_DEFINITIONS.imageVariants,
    ),
    imageRelationships: Table.fromDefinition(
      prefix,
      SCHEMA_DEFINITIONS.imageRelationships,
    ),
  };
}

export const ImageSchema = {
  currentVersion: 1,

  upgradeSchema: (ctx: UpgradeSchemaContext, prefix: string) => {
    const { db, prevVersion } = ctx;
    console.log(
      `Upgrading image schema from v${prevVersion} to v${ImageSchema.currentVersion}`,
    );

    const tables = createV1Tables(prefix);
    SchemaManager.upgradeSchema(db, Object.values(tables));
  },

  validateSchema: (db: IDBDatabase, prefix: string): boolean => {
    const tables = createV1Tables(prefix);
    return SchemaManager.validateSchema(db, Object.values(tables));
  },
};
