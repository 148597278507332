// import { IDeepClone, IDeepEqual } from '@repo/utils';

export class Point {
  //  implements IDeepClone<Point>, IDeepEqual<Point>
  #x: number;
  #y: number;

  constructor(x: number, y: number) {
    this.#x = x;
    this.#y = y;
  }

  get x(): number {
    return this.#x;
  }

  get y(): number {
    return this.#y;
  }

  toString(): string {
    return `Point { x: ${this.#x}, y: ${this.#y} }`;
  }

  deepClone(): Point {
    return new Point(this.x, this.y);
  }

  deepEqual(other: Point | undefined): boolean {
    if (other == undefined) return false;
    return this.x == other.x && this.y == other.y;
  }
}
