export * from './v1-types.js';

import { FileCache, FileMetadata } from '../filecache/FileCache.js';
import { CacheConfig } from '../filecache/v1-types.js';
import { PreprocessStoreResult } from '../filecache/v1-types.js';
import { compressImage } from '@repo/drawing';
import { ImageFileExt, ImagePreprocessConfig } from './v1-types.js';

// ImageMetadata

const DEFAULT_TARGET_QUALITY = 0.9;
const DEFAULT_COMPRESSION_MIME_TYPE_TARGET = 'image/webp';

export type ImageMetadata = FileMetadata<ImageFileExt>;

export class ImageCache extends FileCache<
  undefined, // Config specific to image cache
  unknown,
  ImageFileExt,
  ImagePreprocessConfig
> {
  constructor(config: CacheConfig) {
    // TODO(pbirch): override default db name into base class somehow?
    super(config);
  }

  override async preprocessStore(
    data: ArrayBuffer,
    type: string,
    storeExt: ImagePreprocessConfig,
    options?: {
      expiration?: number;
      onProgress?: (progress: number) => Promise<undefined>;
    },
  ): Promise<PreprocessStoreResult<undefined, ImageFileExt>> {
    const preserveMimeType = storeExt.preserveMimeType ?? false;
    const allowInefficientOptimization =
      storeExt.allowInefficientOptimization ?? false;
    const targetMimeType = preserveMimeType
      ? type
      : (storeExt.targetMimeType ?? DEFAULT_COMPRESSION_MIME_TYPE_TARGET);
    const targetQuality = storeExt.targetQuality ?? DEFAULT_TARGET_QUALITY;
    const skipCompression = storeExt.skipCompression ?? false;

    let finalData = data,
      finalType = type;
    let w = storeExt.image.naturalWidth,
      h = storeExt.image.naturalHeight;
    let fileExtResult: ImageFileExt = {
      width: w,
      height: h,
      aspectRatio: w / h,
    };
    // Compress if needed
    if (!skipCompression && type.startsWith('image/')) {
      options?.onProgress?.(0.2);
      // Compression can fail for very large images but size limit depends on device
      // console.warn(`[ImageCache] Begin compression...`);
      const compressed = await compressImage(
        data,
        targetMimeType,
        targetQuality,
      );
      // console.warn(`[ImageCache] End compression...`, compressed);
      if (compressed != undefined) {
        // Check if compression failed to produce improved result
        if (
          compressed.blob.size < data.byteLength ||
          allowInefficientOptimization
        ) {
          // Compression process may override the desired target mime type depending on the device capabilities
          finalData = await compressed.blob.arrayBuffer();
          finalType = compressed.blob.type;
          console.warn(
            `[ImageCache] Compression outcome: ${finalType} - ${finalData.byteLength}b`,
          );
          w = compressed.img.naturalWidth;
          h = compressed.img.naturalHeight;
          fileExtResult = {
            width: w,
            height: h,
            aspectRatio: w / h,
          };
        }
      }
      options?.onProgress?.(0.4);
    }
    return {
      handler: 'ImageCache',
      data: finalData,
      type: finalType,
      cacheExt: undefined,
      fileExt: fileExtResult,
    };
  }
}
