import { IRenderable, Rect } from '@repo/drawing';

export class CenterTick implements IRenderable {
  #bandSize: Rect;
  #index: number;
  #lineWidth: number;
  #visible: boolean;
  // #hovered: boolean;
  // #selected: boolean;

  constructor(params: {
    bandSize: Rect;
    index: number;
    lineWidth?: number;
    visible?: boolean;
  }) {
    this.#bandSize = params.bandSize;
    this.#index = params.index;
    this.#lineWidth = params.lineWidth ?? 4;
    this.#visible = params.visible ?? false;
    // this.#hovered = false;
    // this.#selected = false;
  }

  get index(): number {
    return this.#index;
  }

  set index(value: number) {
    this.#index = value;
  }

  get lineWidth(): number {
    return this.#lineWidth;
  }

  get visible(): boolean {
    return this.#visible;
  }

  set visible(value: boolean) {
    this.#visible = value;
  }

  // get hovered(): boolean {
  //     return this.#hovered;
  // }

  // set hovered(value: boolean) {
  //     this.#hovered = value;
  // }

  // get selected(): boolean {
  //     return this.#selected;
  // }

  // set selected(value: boolean) {
  //     this.#selected = value;
  // }

  renderTo(ctx: CanvasRenderingContext2D): void {
    if (!this.#visible) return;
    const top = this.#index * this.#bandSize.width;
    const s = this.#bandSize.width / 2;
    // console.log('[CenterTick] render', top, s);
    ctx.save();
    // Moves to the center of the tile
    ctx.translate(s, top + s);
    // ctx.lineWidth = lineWidth;
    ctx.lineWidth = this.#lineWidth + 2;
    ctx.strokeStyle = '#00F'; // this.selected ? "#F00" : "#000";
    ctx.globalAlpha = 0.3; // this.#hovered || this.#selected ? 0.7 : 0.2;
    this.tick(ctx);
    // this.corners(ctx, s, lineWidth + 10);
    ctx.restore();
  }

  tick(ctx: CanvasRenderingContext2D) {
    //, s: number, w: number): void {
    const ds = 100;
    const de = 140;

    // const bw = this.#bandSize.width;
    ctx.beginPath();
    ctx.moveTo(-ds, 0);
    ctx.lineTo(-de, 0);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(ds, 0);
    ctx.lineTo(de, 0);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(0, -ds);
    ctx.lineTo(0, -de);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(0, ds);
    ctx.lineTo(0, de);
    ctx.stroke();
  }
}
