export * from './v1-schema.js';
export * from './v1-types.js';

import { readImageExif, ExifData } from '@repo/shared';
import { IDBPlugin, IDB } from '../index.js';
import { asyncReq, UpgradeSchemaContext } from '../types.js';
import { createV1Tables, ImageSchema, TABLE_NAMES } from './v1-schema.js';
import {
  BandInstance,
  ImageVariant,
  ImageStoreConfig,
  EnhancedImage,
  ImageRelationship,
  LocalImageSource,
  SerializedLayout,
  OG7LayoutV1,
  BannerLayoutV1,
} from './v1-types.js';
import { ImageCache } from '../imagecache/ImageCache.js';
import { sha256HashFromByteArray } from '@repo/shared'; // makeUuid
import { imageFromBuffer } from '@repo/drawing';
import { logger } from '../../../designer/src/index';

export const DEFAULT_PREFIX = 'imagestoredb';

const DEFAULT_ID_GENERATOR = async () => {
  return crypto.randomUUID();
};

const DEFAULT_TIMESTAMP_GENERATOR = () => {
  return Date.now();
};

export interface ImageProcessResult {
  image: EnhancedImage;
  cacheKey: string;
}

export type FileDetails = {
  name: string;
  size: number;
  type: string;
  lastModified: number;
  webkitRelativePath: string;
};

export class ImageStore implements IDBPlugin {
  readonly #config: ImageStoreConfig;
  readonly #prefix: string;
  readonly #tables: ReturnType<typeof createV1Tables>;
  #db!: IDB;
  #imageCache: ImageCache;

  constructor(config: ImageStoreConfig, imageCache: ImageCache) {
    this.#config = config;
    this.#prefix = config.prefix ?? DEFAULT_PREFIX;
    this.#tables = createV1Tables(this.#prefix);
    this.#imageCache = imageCache;
  }

  get prefix(): string {
    return this.#prefix;
  }

  init(instance: IDB): void {
    this.#db = instance;
  }

  upgradeSchema(ctx: UpgradeSchemaContext): void {
    ImageSchema.upgradeSchema(ctx, this.#prefix);
  }

  /**
   * Returns a promise that resolves when the cache is ready for use
   */
  async whenReady(): Promise<void> {
    return this.#db.whenReady();
  }

  /**
   * Calculate correct aspect ratio accounting for EXIF orientation
   */
  private calculateAspectRatio(
    width: number,
    height: number,
    orientation?: number,
  ): number {
    // EXIF orientation 5-8 swap width/height
    if (orientation && orientation >= 5 && orientation <= 8) {
      return height / width;
    }
    return width / height;
  }

  /**
   * Store a new image with automatic EXIF extraction
   */
  async storeImage(
    file: FileDetails,
    imageBuffer: ArrayBuffer,
    options?: {
      // Core metadata
      alt?: string;
      caption?: string;
      quality?: number;
      mimeType?: string;

      // File metadata
      displayName?: string;
      description?: string;

      // Status & flags
      isPrivate?: boolean;
      status?: EnhancedImage['status'];
      needsSync?: boolean;

      // Organization
      tags?: string[];
      categories?: string[];

      // Metadata overrides
      metadata?: Record<string, unknown>;
      exif?: Partial<ExifData>;

      // Progress tracking
      onProgress?: (progress: number) => Promise<void>;
    },
  ): Promise<ImageProcessResult> {
    // const buffer = await file.arrayBuffer();

    const byteArray = new Uint8Array(imageBuffer);
    const hash = await sha256HashFromByteArray(byteArray);

    // Check for existing image by hash
    const existing = await this.getImageByHash(hash);
    if (existing) {
      return { image: existing, cacheKey: existing.cacheKey! };
    }

    // Extract and process EXIF data
    let extractedExif: ExifData | undefined;
    try {
      if (file.type === 'image/jpg' || file.type === 'image/jpeg') {
        extractedExif = await readImageExif(imageBuffer);
      }
    } catch (err) {
      console.warn('EXIF extraction failed:', err);
    }

    // Merge with provided EXIF overrides
    const finalExif: ExifData | undefined = extractedExif && {
      ...extractedExif,
      ...options?.exif,
      raw: {
        ...extractedExif.raw,
        ...(options?.exif?.raw ?? {}),
      },
    };

    const img = await imageFromBuffer(imageBuffer, file.type);
    const timestamp = await this.getTimestamp();

    // Create source metadata
    const source: LocalImageSource = {
      type: 'local',
      filename: file.name,
      name: options?.displayName ?? file.name,
      size: file.size,
      mimetype: options?.mimeType ?? file.type,
      lastModified: file.lastModified,
      webkitRelativePath: file.webkitRelativePath,
      exif: finalExif,
    };

    // Build device metadata from EXIF
    const deviceInfo = finalExif && {
      make: finalExif.make,
      model: finalExif.model,
      software: finalExif.software,
      captureSettings: {
        exposureTime: finalExif.exposureTime,
        fNumber: finalExif.fNumber,
        iso: finalExif.iso,
        focalLength: finalExif.focalLength,
        meteringMode: finalExif.meteringMode,
        whiteBalance: finalExif.whiteBalance,
        flash: finalExif.flash,
        exposureProgram: finalExif.exposureProgram,
        exposureBiasValue: finalExif.exposureBiasValue,
      },
    };

    // Create complete image record
    const image: EnhancedImage = {
      id: await this.generateId(),
      hash,
      filename: options?.displayName ?? file.name,
      source,

      // Metadata
      alt: options?.alt,
      caption: options?.caption,
      mimeType: options?.mimeType ?? file.type,
      filesize: file.size,
      quality: options?.quality,

      // Image dimensions with orientation
      width: img.naturalWidth,
      height: img.naturalHeight,
      aspectRatio: this.calculateAspectRatio(
        img.naturalWidth,
        img.naturalHeight,
        finalExif?.orientation,
      ),

      // Core quality info
      format: options?.mimeType ?? file.type,
      fileSize: file.size,

      // Timestamps with EXIF date if available
      created: finalExif?.dateTaken ?? timestamp,
      lastAccessed: timestamp,
      lastModified: file.lastModified,

      // Storage info
      cacheKey: undefined,
      serverStorage: undefined,
      originalId: undefined,

      // Status
      status: options?.status ?? 'active',
      flags: {
        needsSync: options?.needsSync ?? false,
        hasLocalCache: true,
        isPrivate: options?.isPrivate ?? false,
      },

      // Organization with device-based tags
      tags: [
        ...(options?.tags ?? []),
        ...(finalExif?.make ? [`device:${finalExif.make}`] : []),
        ...(finalExif?.model ? [`model:${finalExif.model}`] : []),
      ],
      categories: options?.categories ?? [],

      // Combined metadata
      metadata: {
        uploadTimestamp: timestamp,
        deviceInfo: deviceInfo,
        location:
          finalExif?.gpsLatitude !== undefined
            ? {
                latitude: finalExif.gpsLatitude,
                longitude: finalExif.gpsLongitude,
              }
            : undefined,
        rights:
          finalExif && (finalExif.artist || finalExif.copyright)
            ? {
                artist: finalExif.artist,
                copyright: finalExif.copyright,
              }
            : undefined,
        description: options?.description,
        ...options?.metadata,
      },
    };

    // Store in cache with orientation
    const cacheKey = await this.#imageCache.store(
      imageBuffer,
      image.mimeType,
      {
        image: img,
        // orientation: finalExif?.orientation
      },
      { onProgress: options?.onProgress },
    );
    image.cacheKey = cacheKey;

    // Store in database
    await this.#db.readwrite([this.#tables.images], async (stores) => {
      await stores.images.put(image);
    });

    return { image, cacheKey };
  }

  /**
   * Create a relationship between two images
   */
  async createRelationship(
    sourceId: string,
    targetId: string,
    type: string,
    metadata?: {
      description?: string;
      [key: string]: any;
    },
  ): Promise<ImageRelationship> {
    const relationship: ImageRelationship = {
      id: await this.generateId(),
      sourceId,
      targetId,
      type,
      description: metadata?.description ?? '',
      created: await this.getTimestamp(),
      metadata,
    };

    await this.#db.readwrite(
      [this.#tables.imageRelationships],
      async (stores) => {
        await stores.imageRelationships.put(relationship);
      },
    );

    return relationship;
  }

  /**
   * Get all relationships for an image
   */
  async getRelationships(
    imageId: string,
    options?: {
      type?: string;
      asSource?: boolean;
      asTarget?: boolean;
    },
  ): Promise<ImageRelationship[]> {
    return await this.#db.readonly(
      [this.#tables.imageRelationships],
      async (stores) => {
        const store = stores.imageRelationships;
        let relationships: ImageRelationship[] = [];

        if (!options?.asTarget) {
          const sourceIdx = store.index('sourceId');
          const sourceRels = await asyncReq(sourceIdx.getAll(imageId));
          relationships = relationships.concat(sourceRels);
        }

        if (!options?.asSource) {
          const targetIdx = store.index('targetId');
          const targetRels = await asyncReq(targetIdx.getAll(imageId));
          relationships = relationships.concat(targetRels);
        }

        if (options?.type) {
          relationships = relationships.filter(
            (rel) => rel.type === options.type,
          );
        }

        return relationships;
      },
    );
  }

  /**
   * Get all related images for an image
   */
  async getRelatedImages(
    imageId: string,
    options?: {
      type?: string;
      asSource?: boolean;
      asTarget?: boolean;
    },
  ): Promise<{
    images: EnhancedImage[];
    relationships: ImageRelationship[];
  }> {
    const relationships = await this.getRelationships(imageId, options);
    const relatedIds = new Set(
      relationships.flatMap((rel) => [rel.sourceId, rel.targetId]),
    );
    relatedIds.delete(imageId); // Remove self

    const images = await Promise.all(
      Array.from(relatedIds).map((id) => this.getImage(id)),
    );

    return {
      images: images.filter((img): img is EnhancedImage => img !== undefined),
      relationships,
    };
  }

  /**
   * Delete relationships between images
   */
  async deleteRelationships(relationships: ImageRelationship[]): Promise<void> {
    await this.#db.readwrite(
      [this.#tables.imageRelationships],
      async (stores) => {
        for (const rel of relationships) {
          await stores.imageRelationships.delete(rel.id);
        }
      },
    );
  }

  /**
   * Delete image and all its relationships
   */
  async deleteImage(id: string): Promise<void> {
    const image = await this.getImage(id);
    if (!image) return;

    await this.#db.readwrite(
      [this.#tables.images, this.#tables.imageRelationships],
      async (stores) => {
        // Delete the image
        await stores.images.delete(id);

        // Delete all relationships
        const relationships = await this.getRelationships(id);
        for (const rel of relationships) {
          await stores.imageRelationships.delete(rel.id);
        }

        // Delete cached data
        if (image.cacheKey) {
          await this.#imageCache.deleteCacheEntry(image.cacheKey);
        }
      },
    );
  }

  /**
   * Update relationship metadata
   */
  async updateRelationship(
    id: string,
    updates: Partial<ImageRelationship>,
  ): Promise<void> {
    await this.#db.readwrite(
      [this.#tables.imageRelationships],
      async (stores) => {
        const existing = await stores.imageRelationships.get(id);
        if (!existing) return;

        const updated = { ...existing, ...updates };
        await stores.imageRelationships.put(updated);
      },
    );
  }

  /**
   * Get an image by ID
   */
  async getImage(id: string): Promise<EnhancedImage | undefined> {
    return await this.#db.readonly([this.#tables.images], async (stores) => {
      // const imageStore = stores[TABLE_NAMES.IMAGES]!;

      const imageObj = stores.images.get(id);
      const image = await asyncReq(imageObj);
      return image;
    });
  }

  /**
   * Get an image by hash
   */
  async getImageByHash(hash: string): Promise<EnhancedImage | undefined> {
    console.log('getImageByHash', hash, this.#tables.images);
    return await this.#db.readonly([this.#tables.images], async (stores) => {
      // const imageStore = stores[TABLE_NAMES.IMAGES]!;
      const idx = stores.images.index('hash');
      const hashObj = await idx.get(hash);
      const result = await asyncReq(hashObj);
      return result;
    });
  }

  // Image Variant Methods
  async createImageVariant(
    originalId: string,
    variant: Omit<ImageVariant, 'id' | 'originalId' | 'created' | 'status'>,
  ): Promise<ImageVariant> {
    const timestamp = await this.getTimestamp();
    const newVariant: ImageVariant = {
      ...variant,
      id: await this.generateId(),
      originalId,
      created: timestamp,
      status: 'active',
    };

    await this.#db.readwrite([this.#tables.imageVariants], async (stores) => {
      await stores.imageVariants.put(newVariant);
    });

    return newVariant;
  }

  async getImageVariant(id: string): Promise<ImageVariant | undefined> {
    return await this.#db.readonly(
      [this.#tables.imageVariants],
      async (stores) => {
        return await asyncReq(stores.imageVariants.get(id));
      },
    );
  }

  async getImageVariants(
    originalId: string,
    type?: string,
  ): Promise<ImageVariant[]> {
    return await this.#db.readonly(
      [this.#tables.imageVariants],
      async (stores) => {
        const idx = stores.imageVariants.index('originalId');
        const variants = await asyncReq(idx.getAll(originalId));

        if (type) {
          return variants.filter((v) => v.type === type);
        }
        return variants;
      },
    );
  }

  async updateImageVariant(
    id: string,
    updates: Partial<ImageVariant>,
  ): Promise<void> {
    await this.#db.readwrite([this.#tables.imageVariants], async (stores) => {
      const existing = await stores.imageVariants.get(id);
      if (!existing) return;

      const updated = { ...existing, ...updates };
      await stores.imageVariants.put(updated);
    });
  }

  async deleteImageVariant(id: string): Promise<void> {
    await this.#db.readwrite([this.#tables.imageVariants], async (stores) => {
      const variant = await this.getImageVariant(id);
      // const variant = await stores.imageVariants.get(id);
      if (!variant) return;

      await stores.imageVariants.delete(id);

      // Delete cached data if exists
      if (variant.cacheKey) {
        await this.#imageCache.deleteCacheEntry(variant.cacheKey);
      }
    });
  }

  // Band Management Methods
  async createBand(
    band: Omit<BandInstance, 'id' | 'created' | 'lastAccessed' | 'lastUpdated'>,
  ): Promise<BandInstance> {
    const timestamp = await this.getTimestamp();
    const newBand: BandInstance = {
      ...band,
      id: await this.generateId(),
      created: timestamp,
      lastAccessed: timestamp,
      lastUpdated: timestamp,
    };
    logger.info('createBand', { newBand });
    await this.#db.readwrite([this.#tables.bands], async (stores) => {
      const key = await stores.bands.put(newBand);
      logger.info('createed Band with key', { key });
    });

    return newBand;
  }

  async getBand(id: string): Promise<BandInstance | undefined> {
    return await this.#db.readonly([this.#tables.bands], async (stores) => {
      const band = await asyncReq(stores.bands.get(id));
      return band;
    });
  }

  async getBandByName(name: string): Promise<BandInstance | undefined> {
    return await this.#db.readonly([this.#tables.bands], async (stores) => {
      const idx = stores.bands.index('name');
      const band = await asyncReq(idx.get(name));
      return band;
    });
  }

  async updateBand(id: string, updates: Partial<BandInstance>): Promise<void> {
    logger.info('updateBand', { id, updates });
    await this.#db.readwrite([this.#tables.bands], async (stores) => {
      const existing: BandInstance = await asyncReq(stores.bands.get(id));
      // const existing = await this.getBand(id);
      // const existing = await stores.bands.get(id);
      // existing.result
      logger.info('updateBand', { existing });
      if (!existing) return;

      const updated = {
        ...existing,
        ...updates,
        id: existing.id,
        lastUpdated: await this.getTimestamp(),
      };
      await stores.bands.put(updated);
    });
  }

  private async updateBandAccess(id: string): Promise<void> {
    return this.updateBand(id, { lastAccessed: await this.getTimestamp() });
  }

  async deleteBand(id: string): Promise<void> {
    await this.#db.readwrite(
      [this.#tables.bands, this.#tables.bandData],
      async (stores) => {
        await stores.bands.delete(id);
        await stores.bandData.delete(id);
      },
    );
  }

  // Query Methods
  async queryBands(options: {
    type?: string;
    tags?: string[];
    categories?: string[];
    isPrivate?: boolean;
    orderBy?: 'created' | 'lastAccessed' | 'lastUpdated';
    limit?: number;
  }): Promise<BandInstance[]> {
    return await this.#db.readonly([this.#tables.bands], async (stores) => {
      let results: BandInstance[] = [];

      // Start with basic type filter if specified
      if (options.type) {
        const typeIdx = stores.bands.index('type');
        results = await asyncReq(typeIdx.getAll(options.type));
      } else {
        results = await asyncReq(stores.bands.getAll());
      }

      // Apply additional filters
      if (options.isPrivate !== undefined) {
        results = results.filter((b) => b.isPrivate === options.isPrivate);
      }

      if (options.tags?.length) {
        results = results.filter((b) =>
          options.tags!.every((tag) => b.tags.includes(tag)),
        );
      }

      if (options.categories?.length) {
        results = results.filter((b) =>
          options.categories!.every((cat) => b.categories.includes(cat)),
        );
      }

      // Sort results
      if (options.orderBy) {
        results.sort((a, b) => b[options.orderBy!] - a[options.orderBy!]);
      }

      // Apply limit
      if (options.limit && options.limit > 0) {
        results = results.slice(0, options.limit);
      }

      return results;
    });
  }

  async searchBands(query: string): Promise<BandInstance[]> {
    return await this.#db.readonly([this.#tables.bands], async (stores) => {
      const results = await asyncReq(stores.bands.getAll());
      const searchTerms = query.toLowerCase().split(/\s+/);

      return results.filter((band) => {
        const searchableText = [
          band.name,
          band.description,
          ...band.tags,
          ...band.categories,
        ]
          .join(' ')
          .toLowerCase();

        return searchTerms.every((term) => searchableText.includes(term));
      });
    });
  }

  // // Band Data Management
  // async storeBandData(bandId: string, data: ArrayBuffer): Promise<void> {
  //   await this.#db.readwrite([this.#tables.bandData], async (stores) => {
  //     await stores.bandData.put(data, bandId);
  //   });
  // }

  // async getBandData(bandId: string): Promise<ArrayBuffer | undefined> {
  //   return await this.#db.readonly([this.#tables.bandData], async (stores) => {
  //     return await asyncReq(stores.bandData.get(bandId));
  //   });
  // }

  // async deleteBandData(bandId: string): Promise<void> {
  //   await this.#db.readwrite([this.#tables.bandData], async (stores) => {
  //     await stores.bandData.delete(bandId);
  //   });
  // }'

  async storeBandData(layout: SerializedLayout): Promise<void> {
    // Validate layout type and version
    if (!this.isValidLayout(layout)) {
      throw new Error('Invalid layout format or version');
    }

    await this.#db.readwrite([this.#tables.bandData], async (stores) => {
      // const data = {
      //   ...layout,
      // };
      logger.info('storeBandData', { layout });
      try {
        // Ensure layout has the required id field
        await stores.bandData.put(layout);
      } catch (error) {
        logger.error('storeBandData', { error });
      }
      // Ensure layout has the required id field
      // await stores.bandData.put({
      //   ...layout,
      //   id,
      // });
    });

    logger.info('set lastUpdated', { id: layout.id, bandId: layout.bandId });
    // Update the band's lastUpdated timestamp
    await this.updateBand(layout.bandId, {
      lastUpdated: await this.getTimestamp(),
    });
  }

  // async storeBandData(id: string, layout: SerializedLayout): Promise<void> {
  //   // Validate layout type and version
  //   if (!this.isValidLayout(layout)) {
  //     throw new Error('Invalid layout format or version');
  //   }

  //   await this.#db.readwrite([this.#tables.bandData], async (stores) => {
  //     // await stores.bandData.put(...layout, id);
  //     const key = await stores.bandData.put({
  //       ...layout,
  //       id,
  //     });
  //     logger.info('storeBandData', { key });
  //   });

  //   // Update the band's lastUpdated timestamp
  //   await this.updateBand(id, {
  //     lastUpdated: await this.getTimestamp(),
  //   });
  // }

  async getBandData(bandId: string): Promise<SerializedLayout | undefined> {
    return await this.#db.readonly([this.#tables.bandData], async (stores) => {
      const layout = await asyncReq(stores.bandData.get(bandId));
      if (!layout) return undefined;

      return layout;
    });
  }

  async getBandDataByBandId(
    bandId: string,
  ): Promise<SerializedLayout | undefined> {
    return await this.#db.readonly([this.#tables.bandData], async (stores) => {
      const idx = stores.bandData.index('bandId');
      const obj = idx.get(bandId);
      const layout = await asyncReq(obj);
      // const layout = await asyncReq(stores.bandData.get(bandId));
      if (!layout) return undefined;

      return layout;
    });
  }

  async deleteBandData(id: string): Promise<void> {
    await this.#db.readwrite([this.#tables.bandData], async (stores) => {
      await stores.bandData.delete(id);
    });
  }

  // Layout-specific methods
  async getBandLayoutByType<T extends SerializedLayout['type']>(
    id: string,
    type: T,
  ): Promise<Extract<SerializedLayout, { type: T }> | undefined> {
    const layout = await this.getBandData(id);
    if (layout?.type === type) {
      return layout as Extract<SerializedLayout, { type: T }>;
    }
    return undefined;
  }

  async updateOG7Layout(
    bandId: string,
    data: OG7LayoutV1['data'],
  ): Promise<void> {
    const layout = await this.getBandData(bandId);
    if (layout?.type !== 'og7') {
      throw new Error('Band does not have an OG7 layout');
    }

    await this.storeBandData({
      id: crypto.randomUUID(),
      type: 'og7',
      version: 1,
      bandId: bandId,
      data,
      metadata: {},
    });
  }

  async updateBannerLayout(
    bandId: string,
    data: BannerLayoutV1['data'],
  ): Promise<void> {
    const layout = await this.getBandData(bandId);
    if (layout?.type !== 'banner') {
      throw new Error('Band does not have a Banner layout');
    }

    await this.storeBandData({
      id: crypto.randomUUID(),
      type: 'banner',
      version: 1,
      bandId,
      data,
      metadata: {},
    });
  }

  // Utility methods for layout handling
  private isValidLayout(layout: SerializedLayout): boolean {
    // First check required fields exist
    if (!layout.id || !layout.version || !layout.metadata) {
      return false;
    }

    switch (layout.type) {
      case 'og7':
        return (
          layout.version === 1 && Array.isArray(layout.data)
          // Each item should be undefined or LoadableImage
        );

      case 'banner':
        if (layout.version !== 1) return false;
        if (!layout.data) return false;
        // if ('id' in layout.data) return false;
        return true;
      // return (
      //   layout.version === 1 &&
      //   // data should be undefined or LoadableImage
      //   (layout.data === undefined || 'id' in layout.data)
      // );

      default:
        return false;
    }
  }

  // Query Methods (adding layout-specific queries)
  async queryBandsByLayout(options: {
    type?: SerializedLayout['type'];
    version?: number;
    isEmpty?: boolean;
  }): Promise<BandInstance[]> {
    return await this.#db.readonly(
      [this.#tables.bands, this.#tables.bandData],
      async (stores) => {
        const bands = await asyncReq(stores.bands.getAll());
        const results: BandInstance[] = [];

        for (const band of bands) {
          const layout = await this.getBandData(band.id);
          // const layout = await stores.bandData.get(band.id);

          if (!layout && options.isEmpty) {
            results.push(band);
            continue;
          }

          if (!layout || !this.isValidLayout(layout)) continue;

          if (options.type && layout.type !== options.type) continue;
          if (options.version && layout.version !== options.version) continue;
          if (options.isEmpty === false && !layout.data) continue;

          results.push(band);
        }

        return results;
      },
    );
  }

  // Helper to convert legacy layouts if needed
  // private convertLegacyLayout(data: unknown): SerializedLayout | undefined {
  //   if (!data) return undefined;

  //   // Handle legacy array format for OG7
  //   if (Array.isArray(data)) {
  //     return {
  //       type: 'og7',
  //       version: 1,
  //       data: data.map(item => typeof item === 'string' ? item : undefined)
  //     };
  //   }

  //   // Handle legacy string format for Banner
  //   if (typeof data === 'string') {
  //     return {
  //       type: 'banner',
  //       version: 1,
  //       data: data
  //     };
  //   }

  //   return undefined;
  // }

  // Helper methods
  private async generateId(): Promise<string> {
    return this.#config.idGenerator?.() ?? DEFAULT_ID_GENERATOR();
  }

  private async getTimestamp(): Promise<number> {
    return this.#config.timestampGenerator?.() ?? DEFAULT_TIMESTAMP_GENERATOR();
  }
}
