import {
  TouchPoints,
  DragHandler,
  FocusHandler,
  KeyboardHandler,
  MouseHandler,
  TouchHandler,
  WheelHandler,
} from './index.js';
import {
  EventModifiers,
  PositionEventData,
  DragEventData,
  ZoomEventData,
} from './index.js';

export class InputEventHandlers {
  // Private fields with readonly to prevent modification after construction
  readonly #onClickHandler?: MouseHandler;
  readonly #onDblClickHandler?: MouseHandler;
  readonly #onMouseDownHandler?: MouseHandler;
  readonly #onMouseEnterHandler?: MouseHandler;
  readonly #onMouseLeaveHandler?: MouseHandler;
  readonly #onMouseMoveHandler?: MouseHandler;
  readonly #onMouseOutHandler?: MouseHandler;
  readonly #onMouseOverHandler?: MouseHandler;
  readonly #onMouseUpHandler?: MouseHandler;
  readonly #onWheelHandler?: WheelHandler;
  readonly #onTouchCancelHandler?: TouchHandler;
  readonly #onTouchEndHandler?: TouchHandler;
  readonly #onTouchMoveHandler?: TouchHandler;
  readonly #onTouchStartHandler?: TouchHandler;
  readonly #onDragHandler?: DragHandler;
  readonly #onDragEndHandler?: DragHandler;
  readonly #onDragEnterHandler?: DragHandler;
  readonly #onDragLeaveHandler?: DragHandler;
  readonly #onDragOverHandler?: DragHandler;
  readonly #onDragStartHandler?: DragHandler;
  readonly #onDropHandler?: DragHandler;
  readonly #onFocusHandler?: FocusHandler;
  readonly #onKeyDownHandler?: KeyboardHandler;
  readonly #onKeyUpHandler?: KeyboardHandler;

  constructor(params: {
    onClickHandler?: MouseHandler;
    onDblClickHandler?: MouseHandler;
    onMouseDownHandler?: MouseHandler;
    onMouseEnterHandler?: MouseHandler;
    onMouseLeaveHandler?: MouseHandler;
    onMouseMoveHandler?: MouseHandler;
    onMouseOutHandler?: MouseHandler;
    onMouseOverHandler?: MouseHandler;
    onMouseUpHandler?: MouseHandler;
    onWheelHandler?: WheelHandler;
    onTouchCancelHandler?: TouchHandler;
    onTouchEndHandler?: TouchHandler;
    onTouchMoveHandler?: TouchHandler;
    onTouchStartHandler?: TouchHandler;
    onDragHandler?: DragHandler;
    onDragEndHandler?: DragHandler;
    onDragEnterHandler?: DragHandler;
    onDragLeaveHandler?: DragHandler;
    onDragOverHandler?: DragHandler;
    onDragStartHandler?: DragHandler;
    onDropHandler?: DragHandler;
    onFocusHandler?: FocusHandler;
    onKeyDownHandler?: KeyboardHandler;
    onKeyUpHandler?: KeyboardHandler;
  }) {
    this.#onClickHandler = params.onClickHandler;
    this.#onDblClickHandler = params.onDblClickHandler;
    this.#onMouseDownHandler = params.onMouseDownHandler;
    this.#onMouseEnterHandler = params.onMouseEnterHandler;
    this.#onMouseLeaveHandler = params.onMouseLeaveHandler;
    this.#onMouseMoveHandler = params.onMouseMoveHandler;
    this.#onMouseOutHandler = params.onMouseOutHandler;
    this.#onMouseOverHandler = params.onMouseOverHandler;
    this.#onMouseUpHandler = params.onMouseUpHandler;
    this.#onWheelHandler = params.onWheelHandler;
    this.#onTouchCancelHandler = params.onTouchCancelHandler;
    this.#onTouchEndHandler = params.onTouchEndHandler;
    this.#onTouchMoveHandler = params.onTouchMoveHandler;
    this.#onTouchStartHandler = params.onTouchStartHandler;
    this.#onDragHandler = params.onDragHandler;
    this.#onDragEndHandler = params.onDragEndHandler;
    this.#onDragEnterHandler = params.onDragEnterHandler;
    this.#onDragLeaveHandler = params.onDragLeaveHandler;
    this.#onDragOverHandler = params.onDragOverHandler;
    this.#onDragStartHandler = params.onDragStartHandler;
    this.#onDropHandler = params.onDropHandler;
    this.#onFocusHandler = params.onFocusHandler;
    this.#onKeyDownHandler = params.onKeyDownHandler;
    this.#onKeyUpHandler = params.onKeyUpHandler;
  }

  // Helper method to extract common event properties
  private getEventModifiers(event: {
    altKey: boolean;
    ctrlKey: boolean;
    metaKey: boolean;
    shiftKey: boolean;
  }): EventModifiers {
    return {
      altKey: event.altKey,
      ctrlKey: event.ctrlKey,
      metaKey: event.metaKey,
      shiftKey: event.shiftKey,
    };
  }

  // Mouse event handlers
  handleMouseEvent(
    type:
      | 'click'
      | 'dblclick'
      | 'mousedown'
      | 'mouseenter'
      | 'mouseleave'
      | 'mousemove'
      | 'mouseout'
      | 'mouseover'
      | 'mouseup',
    event: MouseEvent,
    ext: PositionEventData,
  ): void {
    const handlerMap = {
      click: this.#onClickHandler,
      dblclick: this.#onDblClickHandler,
      mousedown: this.#onMouseDownHandler,
      mouseenter: this.#onMouseEnterHandler,
      mouseleave: this.#onMouseLeaveHandler,
      mousemove: this.#onMouseMoveHandler,
      mouseout: this.#onMouseOutHandler,
      mouseover: this.#onMouseOverHandler,
      mouseup: this.#onMouseUpHandler,
    };

    const handler = handlerMap[type];
    if (handler) {
      handler(event, {
        ...this.getEventModifiers(event),
        ...ext,
      });
    }
  }

  // Touch event handlers
  handleTouchEvent(
    type: 'touchcancel' | 'touchend' | 'touchmove' | 'touchstart',
    event: TouchEvent,
    ext: TouchPoints,
  ): void {
    const handlerMap = {
      touchcancel: this.#onTouchCancelHandler,
      touchend: this.#onTouchEndHandler,
      touchmove: this.#onTouchMoveHandler,
      touchstart: this.#onTouchStartHandler,
    };

    const handler = handlerMap[type];
    if (handler) {
      handler(event, {
        ...this.getEventModifiers(event),
        ...ext,
      });
    }
  }

  // Drag event handlers
  handleDragEvent(
    type:
      | 'drag'
      | 'dragend'
      | 'dragenter'
      | 'dragleave'
      | 'dragover'
      | 'dragstart'
      | 'drop',
    event: DragEvent,
    ext: PositionEventData,
  ): void {
    const handlerMap = {
      drag: this.#onDragHandler,
      dragend: this.#onDragEndHandler,
      dragenter: this.#onDragEnterHandler,
      dragleave: this.#onDragLeaveHandler,
      dragover: this.#onDragOverHandler,
      dragstart: this.#onDragStartHandler,
      drop: this.#onDropHandler,
    };

    const handler = handlerMap[type];
    if (handler) {
      handler(event, {
        ...this.getEventModifiers(event),
        ...ext,
      });
    }
  }

  // Keyboard event handlers
  handleKeyboardEvent(
    type: 'keydown' | 'keyup',
    event: KeyboardEvent,
    // ext: EventModifiers,
  ): void {
    const handlerMap = {
      keydown: this.#onKeyDownHandler,
      keyup: this.#onKeyUpHandler,
    };

    const handler = handlerMap[type];
    if (handler) {
      handler(event, {
        ...this.getEventModifiers(event),
        // ...ext,
      });
    }
  }

  // Wheel event handler
  handleWheel(event: WheelEvent, ext: PositionEventData): void {
    this.#onWheelHandler?.(event, {
      ...this.getEventModifiers(event),
      ...ext,
    });
  }

  // Focus event handler
  handleFocus(event: FocusEvent): void {
    this.#onFocusHandler?.(event);
  }
}
