import { initializeBrowserFeatures } from './common/polyfill.js';
import { getOrientation } from './common/index.js';
import { Rect } from '@repo/drawing';
import { Designer } from './Designer.js';
import { Logger } from '@repo/logger';

export * from './common/index.js';
// export * from './ClientApis.js';
export * from './AppState';
export * from './Container.js';
export * from './Controller.js';
export * from './Designer.js';
export * from './DesignerRegistry.js';
export * from './ImageSelector.js';
export * from './Renderer.js';

export * from './ImageWorkflowManager.js';

export const logger: Logger = new Logger('[Designer]');

// Only declare the global type if we're in a browser environment
// declare global {
//   interface Window {
//     designers: Promise<Array<Designer>>;
//     ontouchend:
//       | (((this: GlobalEventHandlers, ev: TouchEvent) => any) &
//           ((this: Window, ev: TouchEvent) => any))
//       | null
//       | undefined;
//   }
// }

// export const init = async (): Promise<Array<Designer>> => {
//   logger.info('Init Beginning');
//   // Initialize browser features safely
//   initializeBrowserFeatures();

//   // Ensure we're in a browser environment before proceeding
//   if (typeof window === 'undefined' || typeof document === 'undefined') {
//     return [];
//   }

//   logger.info('Attach to Window');

//   try {
//     if ('screen' in window && 'orientation' in screen) {
//       screen.orientation.addEventListener('change', (e) => {
//         console.log('Orientation:', e);
//       });
//       await (screen.orientation as any).lock('landscape').catch(console.error);
//     }
//   } catch (ex) {
//     logger.warn('Screen orientation API not available:', { error: ex });
//   }

//   const designerEls = document.getElementsByClassName('wsband_designer');
//   const designers = [];

//   for (let i = 0; i < designerEls.length; i++) {
//     const designerEl = designerEls[i];
//     if (!(designerEl instanceof HTMLDivElement)) continue;

//     const orientation = getOrientation(designerEl.getAttribute('orientation'));
//     const bandSize = Rect.fromSize(0, 0, 1024, 7168);

//     logger.info('Building new designer with index:', { index: i });
//     const designer = new Designer(
//       {
//         target: designerEl,
//         bandSize,
//       },
//       {
//         orientation,
//         windowRef: window,
//         dev: false,
//       },
//     );

//     designers.push(designer);
//   }

//   return designers;
// };
