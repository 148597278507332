import { degreesToRadians, ElementWrapper, Point, Rect } from '@repo/drawing';
// import { EventModifiers, DragHandler, FocusHandler, KeyboardHandler, MouseHandler, TouchHandler, WheelHandler, TouchPoint } from './common';
import { TouchPoints } from './common/index.js';
import { Logger } from '@repo/logger';
import {
  InputEventHandlers,
  Orientation,
  RendererCallback,
  WindowRef,
  NOOP_RENDERER_CALLBACK,
} from './common/index.js';
import { ConfigManager } from './ConfigManager.js';
import { PerformanceMonitor } from './PerformanceMonitor.js';

export interface ContainerParams {
  bandSize: Rect;
  orientation?: Orientation;
  lockAspect?: boolean;
  dev?: boolean;
}

export interface ContainerBindingConfig {
  enableResizeHandling?: boolean;
  enableWindowEvents?: boolean;
}

export class Container {
  #logger: Logger;
  #params: ContainerParams;
  #target?: HTMLDivElement;
  /**
   * Captures position and layout calculations for the element.
   */
  #targetWrapper?: ElementWrapper;
  #windowRef?: WindowRef;
  #canvas?: HTMLCanvasElement;
  #bufferCanvas?: HTMLCanvasElement;
  #context?: CanvasRenderingContext2D;
  #bufferContext?: CanvasRenderingContext2D;
  #cleanupHandlers: Array<() => void> = [];
  /**
   * Enforces the 1:7 aspect ratio when true.
   */
  #lockAspect?: boolean;
  /**
   * Constrains the orientation regardless of the dimensions.
   */
  #forcedOrientation?: Orientation;
  /**
   * Determined or provided orientation of the tiles.
   */
  #orientation!: Orientation;
  #fps: number = 0;
  #targetFramerate: number = 60;
  #performanceMonitor: PerformanceMonitor;
  #rendererCallbacks: Array<RendererCallback> = [];

  constructor(params: ContainerParams) {
    this.#logger = new Logger('Container');
    this.#performanceMonitor = PerformanceMonitor.getInstance();
    this.#params = params;
    this.#orientation = params.orientation ?? 'portrait';
    this.#lockAspect = params.lockAspect;

    // Create canvases without attaching to DOM
    this.#initializeCanvases();
  }

  /**
   * Reference to the target passed into the Container.
   */
  get target(): HTMLDivElement | undefined {
    return this.#target;
  }

  /**
   * Specifies the target offset and size of the raw band pixels.
   */
  get bandSize(): Rect {
    return this.#params.bandSize;
  }

  /**
   * Reference to the window object passed into the Container.
   */
  get window(): WindowRef | undefined {
    return this.#windowRef;
  }

  // /**
  //  * Gets the rectangle representing the global window object.
  //  *
  //  * @returns Rect struct where the width and height components are those of the global window object
  //  */
  // get windowRect() {
  //   return this.#windowRect;
  // }

  /**
   * Indicates true if the orientation override is in place.
   */
  get orientationForced(): boolean {
    return this.#forcedOrientation === undefined;
  }

  /**
   * Gets the defined orientation.
   */
  get orientation(): Orientation {
    return this.#orientation;
  }

  /**
   * Calculated orientation or override if specified.
   */
  set orientation(orientation: Orientation | undefined) {
    this.#forcedOrientation = orientation;
    this.reflow();
  }

  /**
   * Enforces the 1:7 aspect ratio when true.
   */
  get lockAspect(): boolean | undefined {
    return this.#lockAspect;
  }

  /**
   * Enforces the 1:7 aspect ratio when true.
   */
  set lockAspect(value: boolean | undefined) {
    this.#lockAspect = value;
  }

  get buffer(): HTMLCanvasElement | undefined {
    return this.#bufferCanvas;
  }

  get canvas(): HTMLCanvasElement | undefined {
    return this.#canvas;
  }

  get context(): CanvasRenderingContext2D | undefined {
    return this.#context;
  }

  get fps(): number {
    return this.#fps;
  }

  #initializeCanvases(): void {
    // Create preview canvas
    this.#canvas = document.createElement('canvas');
    this.#canvas.setAttribute('role', 'img');
    this.#canvas.setAttribute('aria-label', 'Design canvas with snap guides');
    this.#canvas.setAttribute('tabindex', '0');
    this.#context = this.#canvas.getContext('2d', {
      // TODO(pbirch): As the base layer this might want to be alpha false
      alpha: true,
      willReadFrequently: true,
    })!;
    this.#context.imageSmoothingEnabled = false;

    // Create buffer canvas
    this.#bufferCanvas = document.createElement('canvas');
    this.#bufferCanvas.height = this.#params.bandSize.height;
    this.#bufferCanvas.width = this.#params.bandSize.width;
    this.#bufferContext = this.#bufferCanvas.getContext('2d', {
      // TODO(pbirch): As the base layer this might want to be alpha false
      alpha: true,
      willReadFrequently: true,
    })!;
    this.#bufferContext.imageSmoothingEnabled = false;
  }

  bindToElement(target: HTMLDivElement): void {
    if (this.#target) {
      throw new Error('Container already bound to element');
    }

    try {
      this.#target = target;
      this.#targetWrapper = new ElementWrapper({ target: this.#target });

      // Configure the preview canvas
      this.#canvas!.style.imageRendering = 'pixelated';
      this.#target.appendChild(this.#canvas!);
      this.#canvas!.onselectstart = function () {
        return false;
      };

      // Configure the buffer canvas if in dev mode
      if (this.#params.dev) {
        this.#bufferCanvas!.style.position = 'fixed';
        this.#bufferCanvas!.style.top = '0';
        this.#bufferCanvas!.style.right = '-1400';
        this.#target.appendChild(this.#bufferCanvas!);
      }

      // Perform initial layout
      this.reflow();
    } catch (error) {
      this.#logger.error('Failed to bind to element:', error);
      throw error;
    }
  }

  bindToWindow(
    windowRef: WindowRef,
    config: ContainerBindingConfig = {},
  ): void {
    if (this.#windowRef) {
      throw new Error('Container already bound to window');
    }

    if (!this.#target) {
      throw new Error(
        'Container must be bound to element before binding to window',
      );
    }

    try {
      this.#windowRef = windowRef;
      this.setupWindowHandlers(config);
      // Need the performance API to start the render loop
      this.startRenderLoop();
    } catch (error) {
      this.#logger.error('Failed to bind to window:', error);
      throw error;
    }
  }

  /**
   * Updates the windowRect property.
   *
   * [Getting the Width and Height of an Element](https://www.javascripttutorial.net/javascript-dom/javascript-width-height/) has a variant
   * of this.windowRef.{prop} that falls back to document and body values
   */
  // #updateWindowRect() {
  //   const windowWidth = this.#windowRef.innerWidth;
  //   const windowHeight = this.#windowRef.innerHeight;
  //   this.#windowRect = new Rect(0, 0, windowWidth, windowHeight);
  // }

  private setupWindowHandlers(config: ContainerBindingConfig): void {
    if (config.enableResizeHandling && this.#windowRef) {
      const resizeHandler = () => {
        // this.#updateWindowRect();
        this.reflow();
      };
      this.#windowRef.addEventListener('resize', resizeHandler);
      this.#cleanupHandlers.push(() =>
        this.#windowRef?.removeEventListener('resize', resizeHandler),
      );
    }

    // Add other window event handlers as needed
  }

  unbind(): void {
    // Clean up event handlers
    this.#cleanupHandlers.forEach((cleanup) => cleanup());
    this.#cleanupHandlers = [];

    // Remove canvases from DOM
    this.#canvas?.remove();
    this.#bufferCanvas?.remove();

    // Reset references
    this.#target = undefined;
    this.#windowRef = undefined;
  }

  reflow(): void {
    if (!this.#target) return;

    const rect = this.#target.getBoundingClientRect();
    this.#canvas!.width = rect.width;
    this.#canvas!.height = rect.height;

    // // Get the latest dimensions for the canvas
    // this.#targetWrapper.updateDimensions();
    // // Calculate orientation
    // const r = this.#targetWrapper.internal;
    // const calculatedOrientation: Orientation =
    //   r.height >= r.width ? 'portrait' : 'landscape';
    // this.#orientation = this.#forcedOrientation ?? calculatedOrientation;
    // this.#canvas!.width = r.width;
    // this.#canvas!.height = r.height;
  }

  // Render method for testing
  public async render(
    renderFn: (ctx: CanvasRenderingContext2D) => Promise<void>,
  ): Promise<void> {
    await renderFn(this.#context!);
  }

  // // Method to manually trigger reflow for testing
  // public triggerReflow(): void {
  //   this.#reflow();
  // }

  registerRendererCallback(callback: RendererCallback) {
    this.#rendererCallbacks.push(callback);
  }

  registerEventHandlers(handlers: InputEventHandlers) {
    // https://developer.chrome.com/docs/lighthouse/best-practices/uses-passive-event-listeners/?utm_source=lighthouse&utm_medium=lr

    this.#canvas!.addEventListener('click', (event: MouseEvent) => {
      const point = this.relPointFromPageLocation(event);
      handlers.handleMouseEvent('click', event, { point });
    });
    this.#canvas!.addEventListener('dblclick', (event: MouseEvent) => {
      const point = this.relPointFromPageLocation(event);
      handlers.handleMouseEvent('dblclick', event, { point });
    });
    this.#canvas!.addEventListener('mousedown', (event: MouseEvent) => {
      const point = this.relPointFromPageLocation(event);
      handlers.handleMouseEvent('mousedown', event, { point });
    });
    this.#canvas!.addEventListener('mouseenter', (event: MouseEvent) => {
      const point = this.relPointFromPageLocation(event);
      handlers.handleMouseEvent('mouseenter', event, { point });
    });
    this.#canvas!.addEventListener('mouseleave', (event: MouseEvent) => {
      const point = this.relPointFromPageLocation(event);
      handlers.handleMouseEvent('mouseleave', event, { point });
    });
    this.#canvas!.addEventListener('mousemove', (event: MouseEvent) => {
      const point = this.relPointFromPageLocation(event);
      handlers.handleMouseEvent('mousemove', event, { point });
    });
    this.#canvas!.addEventListener('mouseout', (event: MouseEvent) => {
      const point = this.relPointFromPageLocation(event);
      handlers.handleMouseEvent('mouseout', event, { point });
    });
    this.#canvas!.addEventListener('mouseover', (event: MouseEvent) => {
      const point = this.relPointFromPageLocation(event);
      handlers.handleMouseEvent('mouseover', event, { point });
    });
    this.#canvas!.addEventListener('mouseup', (event: MouseEvent) => {
      const point = this.relPointFromPageLocation(event);
      handlers.handleMouseEvent('mouseup', event, { point });
    });

    this.#canvas!.addEventListener('wheel', (event: WheelEvent) => {
      const point = this.relPointFromPageLocation(event);
      handlers.handleWheel(event, { point });
    });

    this.#canvas!.addEventListener('drag', (event: DragEvent) => {
      const point = this.relPointFromPageLocation(event);
      handlers.handleDragEvent('drag', event, { point });
    });
    this.#canvas!.addEventListener('dragend', (event: DragEvent) => {
      const point = this.relPointFromPageLocation(event);
      handlers.handleDragEvent('dragend', event, { point });
    });
    this.#canvas!.addEventListener('dragenter', (event: DragEvent) => {
      const point = this.relPointFromPageLocation(event);
      handlers.handleDragEvent('dragenter', event, { point });
    });
    this.#canvas!.addEventListener('dragleave', (event: DragEvent) => {
      const point = this.relPointFromPageLocation(event);
      handlers.handleDragEvent('dragleave', event, { point });
    });
    this.#canvas!.addEventListener('dragover', (event: DragEvent) => {
      const point = this.relPointFromPageLocation(event);
      handlers.handleDragEvent('dragover', event, { point });
    });
    this.#canvas!.addEventListener('dragstart', (event: DragEvent) => {
      const point = this.relPointFromPageLocation(event);
      handlers.handleDragEvent('dragstart', event, { point });
    });
    this.#canvas!.addEventListener('drop', (event: DragEvent) => {
      const point = this.relPointFromPageLocation(event);
      handlers.handleDragEvent('drop', event, { point });
    });

    this.#canvas!.addEventListener('touchcancel', (event: TouchEvent) => {
      const rel = this.relPointsFromTouchEvent(event);
      handlers.handleTouchEvent('touchcancel', event, rel);
    });
    this.#canvas!.addEventListener('touchend', (event: TouchEvent) => {
      const rel = this.relPointsFromTouchEvent(event);
      handlers.handleTouchEvent('touchend', event, rel);
    });
    this.#canvas!.addEventListener('touchmove', (event: TouchEvent) => {
      const rel = this.relPointsFromTouchEvent(event);
      handlers.handleTouchEvent('touchmove', event, rel);
    });
    this.#canvas!.addEventListener('touchstart', (event: TouchEvent) => {
      const rel = this.relPointsFromTouchEvent(event);
      handlers.handleTouchEvent('touchstart', event, rel);
    });

    this.#canvas!.addEventListener('focus', (event: FocusEvent) => {
      handlers.handleFocus(event);
    });
    this.#canvas!.addEventListener('keydown', (event: KeyboardEvent) => {
      handlers.handleKeyboardEvent('keydown', event);
    });
    this.#canvas!.addEventListener('keyup', (event: KeyboardEvent) => {
      handlers.handleKeyboardEvent('keyup', event);
    });
  }

  relPointFromPageLocation(e: { pageX: number; pageY: number }): Point {
    const c = this.#targetWrapper!.client;
    const r = this.#targetWrapper!.offset;
    const raw_x = e.pageX - r.left;
    const raw_y = e.pageY - r.top;
    // const skew_x = this.#bandSize.width / c.width;
    // const skew_y = this.#bandSize.height / c.height;
    const skew_x = this.#params.bandSize.width / c.width;
    const skew_y = this.#params.bandSize.height / c.height;
    const x = raw_x * skew_x;
    const y = raw_y * skew_y;
    // console.log('relPointFromPageLocation', { raw_x, raw_y, x, y }, { c, r }, { skew_x, skew_y });
    return new Point(x, y);
  }

  relPointsFromTouchEvent(e: TouchEvent): TouchPoints {
    return {
      touches: Array.from(e.touches).map((touch) => ({
        point: this.relPointFromPageLocation(touch),
        ...touch,
      })),
      changedTouches: Array.from(e.changedTouches).map((touch) => ({
        point: this.relPointFromPageLocation(touch),
        ...touch,
      })),
    };
  }

  #drawPortrait(flipped: boolean) {
    const r = this.#targetWrapper!.internal;
    var translate_x = 0;
    var translate_y = 0;
    var scale_x = 1;
    var scale_y = 1;

    if (flipped) {
      scale_x *= -1; // Flip image across y-axis
      translate_x = r.width; // Correct for flip distance
    }

    const ctx = this.#context!;
    ctx.save();
    ctx.translate(translate_x, translate_y);
    ctx.scale(scale_x, scale_y);
    ctx.drawImage(this.#bufferCanvas!, 0, 0, r.width, r.height);
    ctx.restore();
  }

  #drawLandscape(flipped: boolean) {
    const r = this.#targetWrapper!.internal;
    var rotation = -90;
    var translate_x = 0;
    var translate_y = r.height;
    var scale_x = r.height / r.width;
    var scale_y = r.width / r.height;

    if (flipped) {
      scale_y *= -1; // Flip image across x-axis
      translate_x = r.width; // Correct for flip distance
    }

    const ctx = this.#context!;
    ctx.save();
    ctx.translate(translate_x, translate_y);
    ctx.rotate(degreesToRadians(rotation));
    ctx.scale(scale_x, scale_y);
    ctx.drawImage(this.#bufferCanvas!, 0, 0, r.width, r.height);
    ctx.restore();
  }

  startRenderLoop() {
    console.warn('[Container] startRenderLoop');
    const targetFramerate = this.#targetFramerate; // TARGET_FRAMERATE;
    const fpsInterval = 1000 / targetFramerate;
    var curr = 0;
    // var prev = this.window.performance.now();
    var prev = this.#windowRef!.performance.now();
    var startTime = prev;
    var elapsed = 0;
    var stop = false;
    var delta = 0;
    var frameCount = 0;
    // console.log('[Container] startTime:', startTime);
    // Capture this container
    const container = this;
    const renderLoopHandler = (timestamp: number) => {
      // TODO(pbirch): Do we need a different way to escape and/or pause the render loop?
      if (stop) {
        return;
      }
      // Pre-request the next frame
      // window.requestAnimationFrame(renderLoopHandler);
      this.#windowRef!.requestAnimationFrame(renderLoopHandler);
      curr = timestamp;
      delta = curr - prev;
      // Skip handling the render event if it hasn't been long enough yet
      if (delta <= fpsInterval) return;
      frameCount++;
      prev = curr - (delta % fpsInterval);
      // Calculate and update fps
      elapsed = curr - startTime;
      this.#fps = Math.round(1000 / delta);
      this.#rendererCallbacks.forEach((r) =>
        r(container, this.#bufferContext!),
      );
      // Transpose the buffer onto the preview
      switch (this.#orientation) {
        case 'portrait':
          this.#drawPortrait(false);
          break;
        case 'portrait-flip':
          this.#drawPortrait(true);
          break;
        case 'landscape':
          this.#drawLandscape(false);
          break;
        case 'landscape-flip':
          this.#drawLandscape(true);
          break;
      }
    };
    renderLoopHandler(startTime);
  }
}
