import {
  CenterTick,
  ClearRect,
  Icon,
  ImagePreview,
  SemiCircleCursor,
  SnapOverlay,
  SnapEngineVisualizer,
  TextBlock,
  TileBorder,
} from './shapes/index.js';
import { IRenderable, Point, Rect } from '@repo/drawing';
import { ReadonlyAppState } from './AppState.js';
import { formatBytes } from '@repo/shared';
import { ImageWorkflowOverlay } from './shapes/ImageWorkflowOverlay.js';
import { EnhancedImage } from '../../database/src/imagestore/v1-types.js';
import { BandLayoutHandler, OG7Layout, BannerLayout } from './layout';

interface Stateful {
  state: ReadonlyAppState;
}

export class Renderer implements IRenderable {
  private layoutHandler: BandLayoutHandler | undefined;
  private imagePreviews: ImagePreview[];
  private workflowOverlays: ImageWorkflowOverlay[];
  private borders: TileBorder[];

  private readonly clearRect: ClearRect;
  private readonly cursor: SemiCircleCursor;
  private readonly tick: CenterTick;
  private readonly photoButton: Icon;
  private readonly snapVisualizer: SnapEngineVisualizer;
  private snapOverlay: SnapOverlay;

  // Diagnostic elements
  private readonly fpsDisplay: TextBlock;
  private readonly imageCacheDisplay: TextBlock;
  private readonly imageCacheProgress: TextBlock;

  private showFps: boolean;
  private showImageCacheMetrics: boolean;
  private showSnapVisualizer: boolean;

  constructor(state: ReadonlyAppState) {
    if (state.bandLayout?.type === 'og7') {
      this.layoutHandler = new OG7Layout();
    } else {
      this.layoutHandler = new BannerLayout();
    }

    // Initialize diagnostic settings
    this.showFps = state.debugFlags.showFps;
    this.showImageCacheMetrics = state.debugFlags.showCacheMetrics;
    this.showSnapVisualizer = state.debugFlags.snapVisualizerEnabled;

    // Initialize diagnostic displays
    this.fpsDisplay = new TextBlock('', {
      opacity: 0.3,
      fontSize: 70,
      fontFamily: 'Roboto mono',
    });

    this.imageCacheDisplay = new TextBlock('', {
      opacity: 0.3,
      fontSize: 70,
      fontFamily: 'Roboto mono',
    });

    this.imageCacheProgress = new TextBlock('', {
      opacity: 0.3,
      fontSize: 70,
      fontFamily: 'Roboto mono',
    });

    // Initialize base elements
    this.clearRect = new ClearRect({ bandSize: state.bandSize });
    this.cursor = new SemiCircleCursor({
      color: '#008',
      size: 40,
      visible: false,
    });

    // Initialize snap visualization
    this.snapVisualizer = new SnapEngineVisualizer({
      enabled: state.debugFlags.snapVisualizerEnabled,
      bounds: state.bandSize,
      style: {
        snapPointColors: {
          corner: 'rgba(255, 0, 0, 0.6)',
          edge: 'rgba(0, 255, 0, 0.6)',
          center: 'rgba(0, 0, 255, 0.6)',
          grid: 'rgba(255, 165, 0, 0.6)',
          intersection: 'rgba(128, 0, 128, 0.6)',
          path: 'rgba(165, 42, 42, 0.6)',
          spacing: 'rgba(0, 128, 128, 0.6)',
          golden: 'rgba(218, 165, 32, 0.6)',
          custom: 'rgba(128, 128, 128, 0.6)',
        },
        gridColor: 'rgba(200, 200, 200, 0.3)',
        guideColor: 'rgba(0, 120, 255, 0.5)',
        textColor: 'rgba(60, 60, 60, 0.8)',
        fontSize: 50,
      },
      show: {
        points: state.debugFlags.showSnapPoints,
        grid: state.debugFlags.showGrid,
        guides: state.debugFlags.showGuides,
        spatialHash: state.debugFlags.showSpatialHash,
        labels: state.debugFlags.showLabels,
        cursor: state.debugFlags.showCursor,
        stats: state.debugFlags.showStats,
      },
    });

    this.snapOverlay = new SnapOverlay({
      snapResult: {
        snapped: false,
        position: new Point(0, 0),
        guides: [],
        metadata: { confidence: 0, snapDistance: 0 },
      },
      guideColor: 'rgba(0, 120, 255, 0.5)',
      pointColor: 'rgba(0, 120, 255, 0.8)',
      lineWidth: 6,
    });

    // Initialize display elements
    this.workflowOverlays = Array.from(
      { length: 7 },
      (_, i) =>
        new ImageWorkflowOverlay({
          index: i,
          tileSize: state.bandSize.width,
        }),
    );

    this.tick = new CenterTick({
      bandSize: state.bandSize,
      index: 0,
    });

    this.imagePreviews = Array.from(
      { length: 7 },
      (_, i) =>
        new ImagePreview({
          position: i,
          size: state.bandSize.width,
        }),
    );

    this.borders = Array.from(
      { length: 7 },
      (_, i) =>
        new TileBorder({
          bandSize: state.bandSize,
          position: i,
          visible: true,
        }),
    );

    this.photoButton = new Icon(
      {
        size: new Rect(0, 0, 120, 120),
        src: 'assets/images/add_a_photo_60dp_000_FILL0_wght400_GRAD0_opsz48.png',
      },
      {
        position: new Point(0, 0),
        visible: false,
      },
    );
  }

  private updateLayoutHandler(state: ReadonlyAppState): void {
    // Skip if no layout change is needed
    if (
      (state.bandLayout?.type === 'og7' &&
        this.layoutHandler instanceof OG7Layout) ||
      (state.bandLayout?.type !== 'og7' &&
        this.layoutHandler instanceof BannerLayout)
    ) {
      return;
    }

    // Update layout handler based on layout type
    if (state.bandLayout?.type === 'og7') {
      this.layoutHandler = new OG7Layout();
    } else {
      this.layoutHandler = new BannerLayout();
    }
  }

  // private updateLayoutHandler(state: ReadonlyAppState): void {
  //   // Update layout handler if layout type changes
  //   if (
  //     state.bandLayout?.type === 'og7' &&
  //     !(this.layoutHandler instanceof OG7Layout)
  //   ) {
  //     this.layoutHandler = new OG7Layout();
  //   } else if (
  //     state.bandLayout?.type !== 'og7' &&
  //     !(this.layoutHandler instanceof BannerLayout)
  //   ) {
  //     this.layoutHandler = new BannerLayout();
  //   }
  // }

  renderTo(ctx: CanvasRenderingContext2D): void {
    // Clear canvas
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    this.clearRect.renderTo(ctx);

    // Get preview count from current layout handler
    const previewCount = this.layoutHandler?.getPreviewCount();

    // Render base layer
    this.imagePreviews
      .slice(0, previewCount)
      .forEach((preview) => preview.renderTo(ctx));
    this.borders
      .slice(0, previewCount)
      .forEach((border) => border.renderTo(ctx));

    // Render interaction layer
    this.workflowOverlays
      .slice(0, previewCount)
      .forEach((overlay) => overlay.renderTo(ctx));
    this.renderInteractiveElements(ctx);

    // Render overlay layer
    this.renderSnapVisualization(ctx);
    this.renderCursor(ctx);

    // Render diagnostic layer
    this.renderDiagnostics(ctx);
  }

  applyState(target: Stateful): void {
    const state = target.state;

    // Update layout handler if needed
    this.updateLayoutHandler(state);

    // Update visualization based on state
    this.layoutHandler?.updatePreviews(state, this.imagePreviews);
    this.layoutHandler?.updateOverlays(state, this.workflowOverlays);
    this.layoutHandler?.updateBorders(state, this.borders);

    // Update other elements
    this.updateInteractiveElements(state);
    this.updateSnapVisualization(state);
    this.updateDiagnosticText(state);
  }

  private updateSnapVisualization(state: ReadonlyAppState): void {
    if (this.showSnapVisualizer && state.snapEngine && state.cursorPosition) {
      this.snapVisualizer.updateData({
        snapPoints: state.snapEngine.preCalculatedPoints,
        spatialHash: state.snapEngine.spatialHash,
        currentResult: state.snapResult,
        cursorPosition: state.cursorPosition,
      });

      if (state.snapResult) {
        this.snapOverlay = new SnapOverlay({
          snapResult: state.snapResult,
          guideColor:
            state.selectedIndex !== undefined
              ? 'rgba(255, 0, 0, 0.6)'
              : 'rgba(255, 0, 0, 0.5)',
          pointColor: 'rgba(255, 0, 0, 0.8)',
          lineWidth: 2,
        });
      }
    }
  }

  private updateInteractiveElements(state: ReadonlyAppState): void {
    // Update cursor
    this.cursor.visible =
      state.cursorPosition !== undefined && !state.previewMode;
    if (this.cursor.visible) {
      this.cursor.position = state.cursorPosition!;
    }

    // Update photo button
    if (state.selectedIndex !== undefined && !state.previewMode) {
      const bandWidth = state.bandSize.width;
      this.photoButton.position = new Point(
        bandWidth / 2,
        state.selectedIndex * bandWidth + bandWidth / 2,
      );
      this.photoButton.visible = true;
    } else {
      this.photoButton.visible = false;
    }

    // Update tick
    this.tick.visible = state.hoveredIndex !== undefined && !state.previewMode;
    if (this.tick.visible) {
      this.tick.index = state.hoveredIndex!;
    }
  }

  private updateDiagnosticText(state: ReadonlyAppState): void {
    if (this.showFps) {
      this.fpsDisplay.text = `FPS: ${state.fps}`;
    }
    if (this.showImageCacheMetrics) {
      this.imageCacheDisplay.text = `CACHE: #${state.cachedImages} - ${formatBytes(state.cacheSize)}`;
      this.imageCacheProgress.text = `PROGRESS: ${state.cacheProgress}%`;
    }
  }

  private renderInteractiveElements(ctx: CanvasRenderingContext2D): void {
    this.photoButton.renderTo(ctx);
    this.tick.renderTo(ctx);
  }

  private renderSnapVisualization(ctx: CanvasRenderingContext2D): void {
    if (this.showSnapVisualizer) {
      this.snapVisualizer.renderTo(ctx);
    }
    if (this.cursor.visible && this.snapOverlay) {
      this.snapOverlay.renderTo(ctx);
    }
  }

  private renderCursor(ctx: CanvasRenderingContext2D): void {
    // if (this.cursor.visible) {
    //   this.cursor.renderTo(ctx);
    // }
  }

  private renderDiagnostics(ctx: CanvasRenderingContext2D): void {
    ctx.save();
    let yOffset = 0;

    if (this.showFps) {
      this.fpsDisplay.renderTo(ctx);
      yOffset += 100;
    }

    if (this.showImageCacheMetrics) {
      ctx.translate(0, yOffset);
      this.imageCacheDisplay.renderTo(ctx);
      ctx.translate(0, 100);
      this.imageCacheProgress.renderTo(ctx);
    }

    ctx.restore();
  }
}
