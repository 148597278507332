export class DesignerError extends Error {
  constructor(
    message: string,
    public readonly code: DesignerErrorCode,
    public readonly metadata?: Record<string, any>,
  ) {
    super(message);
    this.name = 'DesignerError';
  }
}

export class SnapEngineError extends DesignerError {
  constructor(message: string, metadata?: Record<string, any>) {
    super(message, DesignerErrorCode.SNAP_ENGINE_ERROR, metadata);
  }
}

export enum DesignerErrorCode {
  CACHE_ERROR = 'CACHE_ERROR',
  IMAGE_PROCESSING_ERROR = 'IMAGE_PROCESSING_ERROR',
  INVALID_STATE = 'INVALID_STATE',
  NETWORK_ERROR = 'NETWORK_ERROR',
  CANVAS_ERROR = 'CANVAS_ERROR',
  SNAP_ENGINE_ERROR = 'SNAP_ENGINE_ERROR',
}

export class ErrorHandler {
  static handle(error: unknown, context: string): DesignerError {
    if (error instanceof DesignerError) {
      return error;
    }

    // Convert known error types to DesignerError
    if (error instanceof TypeError) {
      return new DesignerError(
        `Type error in ${context}: ${error.message}`,
        DesignerErrorCode.INVALID_STATE,
      );
    }

    // Network errors
    if (error instanceof Response) {
      return new DesignerError(
        `Network error in ${context}: ${error.statusText}`,
        DesignerErrorCode.NETWORK_ERROR,
        { status: error.status },
      );
    }

    // Default case
    return new DesignerError(
      `Unknown error in ${context}: ${String(error)}`,
      DesignerErrorCode.INVALID_STATE,
    );
  }

  static async handleAsync<T>(
    promise: Promise<T>,
    context: string,
  ): Promise<[DesignerError | null, T | null]> {
    try {
      const result = await promise;
      return [null, result];
    } catch (error) {
      return [ErrorHandler.handle(error, context), null];
    }
  }
}
