import { ReadonlyAppState } from '../AppState';
import { ImageWorkflow } from '../index.js';
import {
  ImagePreview,
  ImageWorkflowOverlay,
  TileBorder,
} from '../shapes/index.js';

// Define types for elements that can be reset
export interface Resettable {
  reset(): void;
}

// Define a type for state with workflow ID
export interface WorkflowState extends ReadonlyAppState {
  workflowId?: string;
}

export interface BandLayoutHandler {
  updatePreviews(
    state: ReadonlyAppState,
    previews: ImagePreview[],
  ): Promise<void>;
  updateOverlays(
    state: ReadonlyAppState,
    overlays: ImageWorkflowOverlay[],
  ): void;
  updateBorders(state: ReadonlyAppState, borders: TileBorder[]): void;
  getPreviewCount(): number;
}

export abstract class BaseBandLayout implements BandLayoutHandler {
  private reportedError = false;

  abstract updatePreviews(
    state: ReadonlyAppState,
    previews: ImagePreview[],
  ): Promise<void>;
  abstract updateOverlays(
    state: ReadonlyAppState,
    overlays: ImageWorkflowOverlay[],
  ): void;
  abstract updateBorders(state: ReadonlyAppState, borders: TileBorder[]): void;
  abstract getPreviewCount(): number;

  protected resetElements(
    elements: Array<ImagePreview | ImageWorkflowOverlay>,
    activeCount: number,
  ): void {
    elements.slice(activeCount).forEach((element) => {
      if (element instanceof ImagePreview) {
        element.reset();
      }
      // Add other element type checks if needed
    });
  }

  protected getWorkflowForIndex(
    state: ReadonlyAppState,
    index: number,
  ): ImageWorkflow | undefined {
    if (state.selectedWorkflows.length < index) {
      return undefined;
    }
    const workflowId = state.selectedWorkflows[index];
    if (!workflowId) {
      return undefined;
    }
    try {
      const activeWorkflow = state.activeWorkflows.get(workflowId ?? '');
      this.reportedError = false;
      return activeWorkflow;
    } catch (error) {
      if (!this.reportedError) {
        this.reportedError = true;
        console.error('Error getting active workflow:', error);
      }
      return undefined;
    }
    // return workflowId ? state.activeWorkflows.get(workflowId) : undefined;
  }
}
