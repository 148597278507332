/**
 * IndexedDB databases are persistent on the device and shared between browser tabs.
 *
 * Care must be taken to avoid conflicts between and/or handle updates from across instances.
 *
 * [Detailed IndexedDB Tutorial](https://youtu.be/yZ26CXny3iI?si=Bez4mIbKhS889ZwJ)
 *
 *
 * TODO(pbirch): Provide use with with and/or default error mechanism to just explode and rebuild the entire cache db.
 */

export * from './v1-schema.js';
export * from './v1-types.js';

import { IDB } from '../index.js';
import {
  asyncReq,
  IDBConfig,
  IDBPlugin,
  TableSchema,
  StoreRecord,
  UpgradeSchemaContext,
} from '../types.js';
import {
  CacheConfig,
  CacheEntry,
  CacheMetadata,
  FileMetadata,
  PreprocessStoreResult,
} from './v1-types.js';
import {
  CacheStats,
  CacheHealthMetrics,
  CachePerformanceStats,
} from './v1-types.js';
import { createV1Tables, SchemaV1 } from './v1-schema.js';
import { FileCacheSchema, TABLE_NAMES } from './v1-schema.js';
import { sha256HashFromByteArray } from '@repo/shared';
import { Table } from '../table.js';

export const DEFAULT_PREFIX = 'filecacheidb';
export const DEFAULT_MAX_CACHE_SIZE = 100 * 1024 * 1024;
export const DEFAULT_EXPIRATION_DAYS = 7;
export const DEFAULT_CHUNK_SIZE = 1024 * 1024;

export class FileCache<TConfig, TCacheExt, TFileExt, TStoreExt>
  implements IDBPlugin
{
  readonly #config: TConfig;
  readonly #maxSizeBytes: number;
  readonly #defaultExpirationDays: number;
  readonly #chunkSize: number;
  readonly #prefix: string;
  readonly #tables: SchemaV1<TCacheExt, TFileExt>['tables'];
  #db!: IDB;

  constructor(config: CacheConfig, innerConfig?: TConfig) {
    this.#config = innerConfig!;
    this.#maxSizeBytes = config.maxSizeBytes ?? DEFAULT_MAX_CACHE_SIZE;
    this.#defaultExpirationDays =
      config.defaultExpirationDays ?? DEFAULT_EXPIRATION_DAYS;
    this.#chunkSize = config.chunkSize ?? DEFAULT_CHUNK_SIZE;
    this.#prefix = config.prefix ?? DEFAULT_PREFIX;
    this.#tables = createV1Tables<TCacheExt, TFileExt>(this.#prefix);
  }

  get config(): TConfig | undefined {
    return this.#config;
  }

  get maxSizeBytes(): number {
    return this.#maxSizeBytes;
  }

  get defaultExpirationDays(): number {
    return this.#defaultExpirationDays;
  }

  get chunkSize(): number {
    return this.#chunkSize;
  }

  get prefix(): string {
    return this.#prefix;
  }

  init(instance: IDB): void {
    this.#db = instance;
  }

  upgradeSchema(ctx: UpgradeSchemaContext): void {
    FileCacheSchema.upgradeSchema(ctx, this.#prefix);
  }

  /**
   * Returns a promise that resolves when the cache is ready for use
   */
  async whenReady(): Promise<void> {
    return this.#db.whenReady();
  }

  /**
   * Performs maintenance operations on the cache
   */
  async maintain(): Promise<void> {
    await this.cleanExpiredItems();
    await this.defragment();
    await this.updateStats();
  }

  protected async preprocessStore(
    data: ArrayBuffer,
    type: string,
    ext: TStoreExt,
  ): Promise<PreprocessStoreResult<TCacheExt, TFileExt>> {
    return {
      handler: 'FileCache',
      data,
      type,
      cacheExt: undefined,
      fileExt: undefined,
    };
  }

  /**
   * TStoreExt is provided to the overridable callback to give it per-call config
   *
   * For images might look like:
   *
   * export interface ImageStoreExt {
   *     img: HTMLImageElement,
   * }
   */
  async store(
    data: ArrayBuffer,
    type: string,
    storeExt: TStoreExt,
    options?: {
      expiration?: number;
      onProgress?: (progress: number) => Promise<void>;
    },
  ): Promise<string> {
    options?.onProgress?.(0);
    const startTime = performance.now();
    const result = await this.preprocessStore(data, type, storeExt);
    console.log('[FileCacheDB] PreprocessStore:', result);
    this.#performanceMetrics.compressionTimes.push(
      performance.now() - startTime,
    );
    options?.onProgress?.(0.5);
    const byteArray = new Uint8Array(result.data);
    const hash = await sha256HashFromByteArray(byteArray);

    const now = Date.now();
    const expiresIn =
      options?.expiration ?? this.#defaultExpirationDays * 24 * 60 * 60 * 1000;

    const cacheMetadata: CacheMetadata<TCacheExt> = {
      key: hash,
      type: result.type,
      size: result.data.byteLength,
      originalType: type,
      originalSize: data.byteLength,
      timestamp: now,
      expiresAt: now + expiresIn,
      lastAccess: now,
      cacheExt: result.cacheExt,
    };

    const fileMetadata: FileMetadata<TFileExt> = {
      key: hash,
      type: result.type,
      size: result.data.byteLength,
      fileExt: result.fileExt,
    };
    options?.onProgress?.(0.6);

    await this.#db.readwrite(
      [this.#tables.data, this.#tables.fileMeta, this.#tables.cacheMeta],
      async (stores) => {
        const dataStore = stores[TABLE_NAMES.DATA]!;
        const fileMetaStore = stores[TABLE_NAMES.FILE_META]!;
        const cacheMetaStore = stores[TABLE_NAMES.CACHE_META]!;

        await fileMetaStore.put(fileMetadata);
        await cacheMetaStore.put(cacheMetadata);

        const chunkCount = Math.ceil(result.data.byteLength / this.#chunkSize);
        for (let i = 0; i < chunkCount; i++) {
          const chunkKey = `${hash}-chunk-${i}`;
          const chunk = result.data.slice(
            i * this.#chunkSize,
            (i + 1) * this.#chunkSize,
          );
          await dataStore.put(chunk, chunkKey);
          options?.onProgress?.((i + 1) / chunkCount);
          // progress?.(0.4 + (0.6 * (i / chunkCount)));
        }
      },
    );
    options?.onProgress?.(1);

    return hash;
  }

  async getCacheEntry(key: string): Promise<CacheEntry<TCacheExt, TFileExt>> {
    return await this.#db.readonly(
      [this.#tables.data, this.#tables.fileMeta, this.#tables.cacheMeta],
      async (stores) => {
        const dataStore = stores[TABLE_NAMES.DATA]!;
        const fileMetaStore = stores[TABLE_NAMES.FILE_META]!;
        const cacheMetaStore = stores[TABLE_NAMES.CACHE_META]!;

        const cacheObj = cacheMetaStore.get(key);
        const fileObj = fileMetaStore.get(key);
        const cache: CacheMetadata<TCacheExt> = await asyncReq(cacheObj);
        const file: FileMetadata<TFileExt> = await asyncReq(fileObj);
        const data = await this.#getDataFromChunks(key, dataStore);

        const result: CacheEntry<TCacheExt, TFileExt> = { cache, file, data };
        return result;
      },
    );
  }

  async #getDataFromChunks(
    key: string,
    store: IDBObjectStore,
  ): Promise<ArrayBuffer> {
    // Use index to get all chunks for this key
    const range = IDBKeyRange.bound(`${key}-chunk-0`, `${key}-chunk-\uffff`);

    const chunks: ArrayBuffer[] = [];
    const req: IDBRequest<IDBCursorWithValue | null> =
      await store.openCursor(range);
    if (req === null) return new Uint8Array(0);
    let cursor: IDBCursorWithValue | null = req.result;
    while (cursor) {
      if (cursor.value === null) break;
      chunks.push(cursor.value as ArrayBuffer);
      // cursor = await cursor.continue();
      await cursor.continue();
    }

    // Combine chunks
    const totalLength = chunks.reduce(
      (sum, chunk) => sum + chunk.byteLength,
      0,
    );
    const result = new Uint8Array(totalLength);
    let offset = 0;

    for (const chunk of chunks) {
      result.set(new Uint8Array(chunk), offset);
      offset += chunk.byteLength;
    }

    return result.buffer;
  }

  async deleteCacheEntry(key: string) {
    await this.#db.readwrite(
      [this.#tables.data, this.#tables.fileMeta, this.#tables.cacheMeta],
      async (
        stores: StoreRecord<
          'readwrite',
          [
            Table<'data', ArrayBuffer>,
            Table<'fileMeta', FileMetadata<TFileExt>>,
            Table<'cacheMeta', CacheMetadata<TCacheExt>>,
          ]
        >,
      ) => {
        const dataStore = stores[TABLE_NAMES.DATA]!;
        const fileMetaStore = stores[TABLE_NAMES.FILE_META]!;
        const cacheMetaStore = stores[TABLE_NAMES.CACHE_META]!;

        await asyncReq(dataStore.delete(key));
        await asyncReq(fileMetaStore.delete(key));
        await asyncReq(cacheMetaStore.delete(key));
      },
    );
  }

  async clear(): Promise<void> {
    this.#db.readwrite(
      [this.#tables.data, this.#tables.fileMeta, this.#tables.cacheMeta],
      async (stores) => {
        const cacheMetaStore = stores[TABLE_NAMES.CACHE_META]!;
        const fileMetaStore = stores[TABLE_NAMES.FILE_META]!;
        const dataStore = stores[TABLE_NAMES.DATA]!;
        const cacheKeys = await asyncReq(cacheMetaStore.getAllKeys());
        const fileKeys = await asyncReq(fileMetaStore.getAllKeys());
        const dataKeys = await asyncReq(dataStore.getAllKeys());
        for (const key of cacheKeys) {
          await asyncReq(cacheMetaStore.delete(key));
        }
        for (const key of fileKeys) {
          await asyncReq(fileMetaStore.delete(key));
        }
        for (const key of dataKeys) {
          await asyncReq(dataStore.delete(key));
        }
      },
    );
  }

  async cleanExpiredItems(): Promise<void> {
    try {
      const metadata = await this.getAllCacheMetadata();
      const now = Date.now();

      for (const item of metadata) {
        // Allow expiration to be ignored
        if (item.expiresAt != undefined && item.expiresAt < now) {
          await this.deleteCacheEntry(item.key);
        }
      }
    } catch (error) {
      console.error('Failed to clean expired items:', error);
    }
  }

  /**
   * Defragments the cache by consolidating chunks and cleaning up orphaned data
   */
  private async defragment(): Promise<void> {
    this.#db.readwrite(
      [this.#tables.data, this.#tables.fileMeta],
      async (stores) => {
        const dataStore = stores[TABLE_NAMES.DATA]!;
        const fileMetaStore = stores[TABLE_NAMES.FILE_META]!;

        // Get all file metadata
        const files = await asyncReq(fileMetaStore.getAll());
        const fileKeys = new Set(files.map((f) => f.key));

        // Get all chunk keys
        const chunkKeys = await asyncReq(dataStore.getAllKeys());

        // Delete orphaned chunks
        for (const chunkKey of chunkKeys) {
          const fileKey = (chunkKey as string).split('-chunk-')[0];
          if (!fileKeys.has(fileKey)) {
            await asyncReq(dataStore.delete(chunkKey));
          }
        }
      },
    );
  }

  /**
   * Updates cache statistics and triggers maintenance if needed
   */
  private async updateStats(): Promise<void> {
    const stats = await this.getStats();

    // If cache is nearing capacity, trigger cleanup
    if (stats.spaceUtilization > 90) {
      console.warn('Cache space utilization high, performing cleanup');
      await this.cleanLeastAccessed();
    }
  }

  /**
   * Removes least recently accessed items to free up space
   */
  private async cleanLeastAccessed(): Promise<void> {
    const metadata = await this.getAllCacheMetadata();

    // Sort by last access time
    metadata.sort((a, b) => (a.lastAccess ?? 0) - (b.lastAccess ?? 0));

    // Remove oldest 20% of items
    const itemsToRemove = Math.ceil(metadata.length * 0.2);
    for (let i = 0; i < itemsToRemove; i++) {
      await this.deleteCacheEntry(metadata[i]!.key);
    }
  }

  /**
   * Updates last access time for a cache entry
   */
  private async updateLastAccess(key: string): Promise<void> {
    this.#db.readwrite([this.#tables.cacheMeta], async (stores) => {
      const cacheMetaStore = stores[TABLE_NAMES.CACHE_META]!;
      const metadata = await asyncReq<CacheMetadata<TCacheExt>>(
        cacheMetaStore.get(key),
      );
      if (metadata) {
        metadata.lastAccess = Date.now();
        await asyncReq(cacheMetaStore.put(metadata));
      }
    });
  }

  /**
   * Provides cache health metrics
   */
  async getHealthMetrics(): Promise<CacheHealthMetrics> {
    const stats = await this.getStats();
    const perf = await this.getPerformanceStats();

    return {
      isHealthy: stats.spaceUtilization < 90 && perf.hitRate > 50,
      spaceUtilization: stats.spaceUtilization,
      hitRate: perf.hitRate,
      itemCount: stats.totalItems,
      totalSize: stats.totalSize,
      errors: this.#performanceMetrics.errors,
      warnings: [],
    };
  }

  async getVersion(): Promise<number> {
    return this.#db.readonly([this.#tables.cacheMeta], async (stores) => {
      const cacheMetaStore = stores[TABLE_NAMES.CACHE_META]!;
      const timestampIndex = cacheMetaStore.index('timestamp');
      const cursor = await asyncReq(timestampIndex.openCursor(null, 'prev'));
      return cursor?.value?.timestamp ?? 0;
    });
  }

  async getAllCacheMetadata(): Promise<Array<CacheMetadata<TCacheExt>>> {
    const result = await this.#db.readonly(
      [this.#tables.cacheMeta],
      async (stores) => {
        const cacheMetaStore = stores[TABLE_NAMES.CACHE_META]!;
        const cacheMetaData = cacheMetaStore.getAll();
        return await asyncReq<CacheMetadata<TCacheExt>[]>(cacheMetaData);
      },
    );
    return result;
  }

  async getAllFileMetadata(): Promise<Array<FileMetadata<TFileExt>>> {
    const result = await this.#db.readonly(
      [this.#tables.fileMeta],
      async (stores) => {
        const fileMetaStore = stores[TABLE_NAMES.FILE_META]!;
        const fileMetaData = fileMetaStore.getAll();
        return await asyncReq<FileMetadata<TFileExt>[]>(fileMetaData);
      },
    );
    return result;
  }

  async getCacheMetadata(
    key: string,
  ): Promise<CacheMetadata<TCacheExt> | undefined> {
    const result = await this.#db.readonly(
      [this.#tables.cacheMeta],
      async (stores) => {
        const cacheMetaStore = stores[TABLE_NAMES.CACHE_META]!;
        const cacheMetaData = cacheMetaStore.get(key);
        return await asyncReq<CacheMetadata<TCacheExt>>(cacheMetaData);
      },
    );
    return result;
  }

  async getFileMetadata(
    key: string,
  ): Promise<FileMetadata<TFileExt> | undefined> {
    const result = await this.#db.readonly(
      [this.#tables.fileMeta],
      async (stores) => {
        const fileMetaStore = stores[TABLE_NAMES.FILE_META]!;
        const fileMetaData = fileMetaStore.get(key);
        return await asyncReq<FileMetadata<TFileExt>>(fileMetaData);
      },
    );
    return result;
  }

  async getData(
    key: string,
  ): Promise<{ meta: FileMetadata<TFileExt>; buffer: ArrayBuffer }> {
    const result = await this.#db.readonly(
      [this.#tables.fileMeta, this.#tables.data],
      async (stores) => {
        const fileMetaStore = stores[TABLE_NAMES.FILE_META]!;
        const fileMetaReq = fileMetaStore.get(key);
        const fileMeta = await asyncReq<FileMetadata<TFileExt>>(fileMetaReq);
        const dataStore = stores[TABLE_NAMES.DATA]!;
        const data = await this.#getDataFromStore(key, dataStore);
        return { meta: fileMeta, buffer: data };
      },
    );
    return result;
  }

  async #getDataFromStore(
    key: string,
    store: IDBObjectStore,
  ): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      // Assuming we have an index on the 'key' property
      const keyRange = IDBKeyRange.bound(
        `${key}-chunk-0`,
        `${key}-chunk-\uffff`, // Uses the highest possible Unicode character
        false,
        false,
      );

      // Use a cursor to get chunks in order, avoiding the need to sort
      const chunks: any[] = [];
      const cursorRequest = store.openCursor(keyRange);

      cursorRequest.onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor) {
          chunks.push(cursor.value);
          cursor.continue();
        } else {
          // All chunks collected, combine them
          const totalLength = chunks.reduce(
            (sum, chunk) => sum + chunk.byteLength,
            0,
          );
          const result = new Uint8Array(totalLength);
          let offset = 0;

          for (const chunk of chunks) {
            result.set(new Uint8Array(chunk), offset);
            offset += chunk.byteLength;
          }
          resolve(result.buffer);
        }
      };

      cursorRequest.onerror = () => {
        reject(cursorRequest.error);
      };
    });
  }

  async #getCurrentCacheSize(
    allMetadata: Array<CacheMetadata<TCacheExt>>,
  ): Promise<number> {
    // const metadata = await this.getAllMetadata();
    return allMetadata.reduce((total, item) => total + item.size, 0);
  }

  async #ensureSpaceAvailable(
    allMetadata: Array<CacheMetadata<TCacheExt>>,
    requiredBytes: number,
  ): Promise<void> {
    const currentSize = await this.#getCurrentCacheSize(allMetadata);

    if (currentSize + requiredBytes > this.maxSizeBytes) {
      // const metadata = await this.getAllMetadata();
      allMetadata.sort((a, b) => a.timestamp - b.timestamp);

      let freedSpace = 0;
      for (const item of allMetadata) {
        if (currentSize + requiredBytes - freedSpace <= this.maxSizeBytes)
          break;

        await this.deleteCacheEntry(item.key);
        freedSpace += item.size;
      }
    }
  }

  /**
   *
   */
  #performanceMetrics = {
    cacheHits: 0,
    cacheMisses: 0,
    evictions: 0,
    compressionTimes: [] as number[],
    readTimes: [] as number[],
    writeTimes: [] as number[],
    errors: [],
  };

  async getStats(): Promise<CacheStats> {
    const metadata = await this.getAllCacheMetadata();
    const now = Date.now();

    // Initialize counters
    let totalSize = 0;
    const itemsByType: Record<string, number> = {};
    const sizeByType: Record<string, number> = {};
    const ageDistribution = {
      lastHour: 0,
      lastDay: 0,
      lastWeek: 0,
      older: 0,
    };
    const sizeDistribution = {
      under1MB: 0,
      under10MB: 0,
      under100MB: 0,
      over100MB: 0,
    };
    const expirationDistribution = {
      next24h: 0,
      next7d: 0,
      next30d: 0,
      later: 0,
    };

    // Process each item
    for (const item of metadata) {
      // Update total size
      totalSize += item.size;

      // Update type statistics
      itemsByType[item.originalType] =
        (itemsByType[item.originalType] || 0) + 1;
      sizeByType[item.originalType] =
        (sizeByType[item.originalType] || 0) + item.size;

      // Update age distribution
      const age = now - item.timestamp;
      if (age < 3600000) ageDistribution.lastHour++;
      else if (age < 86400000) ageDistribution.lastDay++;
      else if (age < 604800000) ageDistribution.lastWeek++;
      else ageDistribution.older++;

      // Update size distribution
      const sizeInMB = item.size / (1024 * 1024);
      if (sizeInMB < 1) sizeDistribution.under1MB++;
      else if (sizeInMB < 10) sizeDistribution.under10MB++;
      else if (sizeInMB < 100) sizeDistribution.under100MB++;
      else sizeDistribution.over100MB++;

      // Update expiration distribution
      const timeToExpiration = (item.expiresAt ?? now) - now;
      if (timeToExpiration < 86400000) expirationDistribution.next24h++;
      else if (timeToExpiration < 604800000) expirationDistribution.next7d++;
      else if (timeToExpiration < 2592000000) expirationDistribution.next30d++;
      else expirationDistribution.later++;
    }

    // Calculate dates
    const timestamps = metadata.map((item) => item.timestamp);
    const expirations = metadata
      .map((item) => item.expiresAt)
      .filter((item) => item != undefined);

    return {
      totalItems: metadata.length,
      totalSize,
      averageSize: totalSize / metadata.length || 0,
      compressionRatio: this.#calculateCompressionRatio(metadata),
      oldestItem: new Date(Math.min(...timestamps)),
      newestItem: new Date(Math.max(...timestamps)),
      expiringNext: new Date(Math.min(...expirations)),
      spaceUtilization: (totalSize / this.maxSizeBytes) * 100,
      itemsByType,
      sizeByType,
      ageDistribution,
      sizeDistribution,
      expirationDistribution,
    };
  }

  async getPerformanceStats(): Promise<CachePerformanceStats> {
    const {
      cacheHits,
      cacheMisses,
      evictions,
      compressionTimes,
      readTimes,
      writeTimes,
    } = this.#performanceMetrics;

    const totalRequests = cacheHits + cacheMisses;

    return {
      averageCompressionTime: this.#calculateAverage(compressionTimes),
      averageReadTime: this.#calculateAverage(readTimes),
      averageWriteTime: this.#calculateAverage(writeTimes),
      hitRate: totalRequests ? (cacheHits / totalRequests) * 100 : 0,
      missRate: totalRequests ? (cacheMisses / totalRequests) * 100 : 0,
      evictionRate: evictions,
      compressionSavings: await this.#calculateCompressionSavings(),
    };
  }

  #calculateAverage(array: number[]): number {
    if (array.length === 0) return 0;
    return array.reduce((a, b) => a + b, 0) / array.length;
  }

  #calculateCompressionRatio(metadata: CacheMetadata<TCacheExt>[]): number {
    let totalOriginal = 0;
    let totalCompressed = 0;

    for (const item of metadata) {
      if (item.originalSize) {
        totalOriginal += item.originalSize;
        totalCompressed += item.size;
      }
    }

    return totalOriginal ? totalCompressed / totalOriginal : 1;
  }

  async #calculateCompressionSavings(): Promise<number> {
    const metadata = await this.getAllCacheMetadata();
    let totalSaved = 0;

    for (const item of metadata) {
      if (item.originalSize) {
        totalSaved += item.originalSize - item.size;
      }
    }

    return totalSaved;
  }
}

export class DefaultFileCache<TConfig> extends FileCache<
  TConfig,
  unknown,
  unknown,
  unknown
> {
  constructor(config: CacheConfig, innerConfig?: TConfig) {
    super(config, innerConfig);
  }
}
