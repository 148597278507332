import { IDBConfig, IDBState } from './types.js';

export interface RecoveryOptions {
  maxRetries: number;
  backoffMs: number;
  forceReset: boolean;
  timeout: number;
}

export class IDBRecovery {
  private retryCount = 0;
  private readonly defaultOptions: RecoveryOptions = {
    maxRetries: 3,
    backoffMs: 1000,
    forceReset: false,
    timeout: 5000,
  };

  async recoverDatabase(
    config: IDBConfig,
    options: Partial<RecoveryOptions> = {},
  ): Promise<IDBDatabase> {
    console.log('[IDBRecovery] recoverDatabase()', config, options);
    const opts = { ...this.defaultOptions, ...options };

    // First try closing any hanging connections
    this.closeExistingConnections(config.dbName);

    try {
      console.log('[IDBRecovery] recoverDatabase() - openWithRetry()');
      // Try deleting with timeout protection
      await Promise.race([
        this.deleteWithRetry(config.dbName, opts),
        new Promise((_, reject) =>
          setTimeout(() => reject(new Error('Delete timeout')), opts.timeout),
        ),
      ]);

      // Wait briefly for cleanup
      await new Promise((resolve) => setTimeout(resolve, 100));

      // Attempt reopening
      return await this.openWithRetry(config, opts);
    } catch (error) {
      console.error('[IDBRecovery] recoverDatabase() - error', error);
      if (this.retryCount < opts.maxRetries) {
        this.retryCount++;
        await new Promise((resolve) =>
          setTimeout(resolve, opts.backoffMs * this.retryCount),
        );
        return this.recoverDatabase(config, opts);
      }

      if (opts.forceReset) {
        // Last resort - try to delete through indexedDB.databases() API
        await this.forceDeleteDatabase(config.dbName);
        return this.openWithRetry(config, opts);
      }

      throw new Error(`Recovery failed after ${this.retryCount} attempts`);
    }
  }

  private closeExistingConnections(dbName: string): void {
    // Force close any existing connections
    console.log('[IDBRecovery] closeExistingConnections()', dbName);
    const databases = indexedDB.databases();
    databases.then((dbs) => {
      dbs.forEach((db) => {
        if (db.name === dbName) {
          const req = indexedDB.open(dbName);
          req.onsuccess = () => {
            const db = req.result;
            db.close();
          };
        }
      });
    });
  }

  private async deleteWithRetry(
    dbName: string,
    options: RecoveryOptions,
  ): Promise<void> {
    console.log('[IDBRecovery] deleteWithRetry()', dbName, options);
    return new Promise((resolve, reject) => {
      const req = indexedDB.deleteDatabase(dbName);

      req.onerror = () => reject(new Error('Delete failed'));
      req.onblocked = () => reject(new Error('Delete blocked'));
      req.onsuccess = () => resolve();

      // Handle edge cases
      req.onupgradeneeded = () => {
        try {
          req.result.close();
          resolve();
        } catch (e) {
          reject(e);
        }
      };
    });
  }

  private async openWithRetry(
    config: IDBConfig,
    options: RecoveryOptions,
  ): Promise<IDBDatabase> {
    console.log('[IDBRecovery] openWithRetry()', config, options);
    return new Promise((resolve, reject) => {
      const req = indexedDB.open(config.dbName, config.version);

      req.onerror = () => reject(new Error('Open failed'));
      req.onblocked = () => reject(new Error('Open blocked'));
      req.onsuccess = () => resolve(req.result);

      req.onupgradeneeded = (event) => {
        const db = req.result;
        // Re-create schema
        try {
          // Schema recreation logic here
          resolve(db);
        } catch (e) {
          reject(e);
        }
      };
    });
  }

  private async forceDeleteDatabase(dbName: string): Promise<void> {
    console.log('[IDBRecovery] forceDeleteDatabase()', dbName);
    const databases = await indexedDB.databases();
    const target = databases.find((db) => true); // db.name === dbName);
    if (target) {
      await new Promise<void>((resolve, reject) => {
        console.log('[IDBRecovery] forceDeleteDatabase() - deleting', target);
        const req = indexedDB.deleteDatabase(target.name!);
        req.onsuccess = () => resolve();
        req.onerror = () => reject();
      });
    }
  }
}
