export * from './CacheMetrics.js';
export * from './Database.js';

import { Logger } from '@repo/logger';

// import { CacheMetrics } from './CacheMetrics.js';
import { Database } from './Database.js';

import { v4 as uuidv4 } from 'uuid';

export interface DesignerInstance {
  id: string; // Guid for the designer instance
}

export interface StateManager {
  database: Database;
}

export class StateManagerImpl implements StateManager {
  private static instance: StateManager;
  private databaseInstance: Database;
  private databaseInstanceInitializer: Promise<void>;
  private instances: Map<string, DesignerInstance>;
  private logger: Logger;

  private constructor() {
    this.databaseInstance = Database.getInstance();
    this.databaseInstanceInitializer = this.databaseInstance.initialize();
    this.instances = new Map();
    this.logger = new Logger('StateManager');
  }

  static getInstance(): StateManager {
    if (!StateManagerImpl.instance) {
      StateManagerImpl.instance = new StateManagerImpl();
    }
    return StateManagerImpl.instance;
  }

  async initialize(): Promise<void> {
    return this.databaseInstanceInitializer;
    // await this.database.initialize();
  }

  createInstance(): string {
    const id = uuidv4();
    this.instances.set(id, {
      id,
    });
    return id;
  }

  getInstance(id: string): DesignerInstance | undefined {
    return this.instances.get(id);
  }

  get database(): Database {
    return this.databaseInstance;
  }
}

// imageList(): Array<{ url: string, type: string }> {
//     return [
//         { url: '/assets/images/favicon.webp', type: 'image/webp' },
//         { url: '/assets/images/examples/2-DSC03373.jpg', type: 'image/jpg' },
//         { url: '/assets/images/examples/4-DSC03389.jpg', type: 'image/jpg' },
//         // { url: '/assets/images/examples/09_08_24_two_arms_bracelets.png', type: 'image/png' },
//         // { url: '/assets/images/examples/dark_wrsr_universe.png', type: 'image/png' },
//         // { url: '/assets/images/examples/high_five_480.jpg', type: 'image/jpg' },
//         // { url: '/assets/images/examples/sitting_on_concrete_480.jpg', type: 'image/jpg' },
//         // { url: '/assets/images/examples/tying_band_480.webp', type: 'image/webp' },
//     ];
// }

// async testCache() {

//     const div = document.createElement('div');
//     div.id = 'crdt-visualizer';
//     div.style.position = 'absolute';
//     div.style.top = '0px';
//     div.style.left = '0px';
//     document.body.appendChild(div);
//     // initializeCRDTTest();

//     // temp.test();
//     // orient();

//     // const images = this.imageList();
//     // for (var i = 0; i < images.length; i++) {
//     //     const image = images[i];
//     //     await this.writeImage(image.url, image.type);
//     // }
//     // for (var i = 0; i < images.length; i++) {
//     //     const image = images[i];
//     //     await this.writeImage(image.url, image.type);
//     // }

//     // const data = new ArrayBuffer(100);
//     // const data = await fetchImageAsArrayBuffer('/assets/images/favicon.webp');
//     // const temp = await this.#controller.cacheImageFromBuffer(data, "image/webp");
//     // console.log('[Designer] testCache finished');
// }
