import { ExifParser } from './ExifParser.js';

// Raw EXIF data from parser
export type RawExifData = { [key: string]: string };

// Normalized EXIF metadata with strongly typed fields
export interface ExifData {
  raw: RawExifData; // Preserve raw data for debugging/custom processing

  // Core device info
  make?: string;
  model?: string;
  software?: string;

  // Image capture
  dateTaken?: number;
  orientation?: number;
  exposureTime?: string; // Formatted as fraction if < 1
  fNumber?: number;
  focalLength?: number;
  focalLengthIn35mm?: number;
  iso?: number;

  // Location
  gpsLatitude?: number;
  gpsLongitude?: number;

  // Rights
  artist?: string;
  copyright?: string;

  // Additional data
  meteringMode?: string;
  whiteBalance?: string;
  flash?: string;
  exposureProgram?: string;
  exposureBiasValue?: number;
}

/**
 * Normalize raw EXIF data into our expected format
 * @param rawExif Raw EXIF data from parser
 * @returns Normalized EXIF data structure
 */
export function normalizeExifData(rawExif: RawExifData): ExifData {
  return {
    raw: rawExif,

    // Core device info
    make: rawExif.Make,
    model: rawExif.Model,
    software: rawExif.Software,

    // Image capture
    dateTaken: rawExif.DateTimeOriginal
      ? parseExifDate(rawExif.DateTimeOriginal)
      : undefined,
    orientation: rawExif.Orientation
      ? parseInt(rawExif.Orientation)
      : undefined,
    exposureTime: rawExif.ExposureTime,
    fNumber: rawExif.FNumber
      ? parseFloat(rawExif.FNumber.replace('f/', ''))
      : undefined,
    focalLength: rawExif.FocalLength
      ? parseFloat(rawExif.FocalLength.replace('mm', ''))
      : undefined,
    focalLengthIn35mm: rawExif.FocalLengthIn35mmFilm
      ? parseFloat(rawExif.FocalLengthIn35mmFilm.replace('mm', ''))
      : undefined,
    iso: rawExif.ISOSpeedRatings
      ? parseInt(rawExif.ISOSpeedRatings)
      : undefined,

    // Location
    gpsLatitude: parseGpsCoordinate(
      rawExif.GPSLatitude,
      rawExif.GPSLatitudeRef,
    ),
    gpsLongitude: parseGpsCoordinate(
      rawExif.GPSLongitude,
      rawExif.GPSLongitudeRef,
    ),

    // Rights
    artist: rawExif.Artist,
    copyright: rawExif.Copyright,

    // Additional data
    meteringMode: rawExif.MeteringMode,
    whiteBalance: rawExif.WhiteBalance,
    flash: rawExif.Flash,
    exposureProgram: rawExif.ExposureProgram,
    exposureBiasValue: rawExif.ExposureBiasValue
      ? parseFloat(rawExif.ExposureBiasValue)
      : undefined,
  };
}

/**
 * Parse EXIF date string into Unix timestamp
 * @param dateStr EXIF date string (format: "YYYY:MM:DD HH:MM:SS")
 * @returns Unix timestamp in milliseconds, or undefined if invalid
 */
function parseExifDate(dateStr: string): number | undefined {
  try {
    if (!dateStr || typeof dateStr !== 'string') {
      return undefined;
    }

    // Split date and time
    const [date, time] = dateStr.split(' ');
    if (!date || !time) {
      return undefined;
    }

    // Parse date components
    const [yearStr, monthStr, dayStr] = date.split(':');
    if (!yearStr || !monthStr || !dayStr) {
      return undefined;
    }

    const year = parseInt(yearStr, 10);
    const month = parseInt(monthStr, 10) - 1; // JS months are 0-based
    const day = parseInt(dayStr, 10);

    // Validate date components
    if (
      isNaN(year) ||
      isNaN(month) ||
      isNaN(day) ||
      year < 1970 ||
      year > 9999 || // Reasonable year range
      month < 0 ||
      month > 11 ||
      day < 1 ||
      day > 31
    ) {
      return undefined;
    }

    // Parse time components
    const [hourStr, minuteStr, secondStr] = time.split(':');
    if (!hourStr || !minuteStr || !secondStr) {
      return undefined;
    }

    const hour = parseInt(hourStr, 10);
    const minute = parseInt(minuteStr, 10);
    const second = parseInt(secondStr, 10);

    // Validate time components
    if (
      isNaN(hour) ||
      isNaN(minute) ||
      isNaN(second) ||
      hour < 0 ||
      hour > 23 ||
      minute < 0 ||
      minute > 59 ||
      second < 0 ||
      second > 59
    ) {
      return undefined;
    }

    const timestamp = Date.UTC(year, month, day, hour, minute, second);
    return isNaN(timestamp) ? undefined : timestamp;
  } catch (error) {
    console.error('Error parsing EXIF date:', error);
    return undefined;
  }
}

/**
 * Parse GPS coordinates from EXIF data
 * @param coord GPS coordinate in degrees/minutes/seconds format
 * @param ref Direction reference (N/S/E/W)
 * @returns Decimal degrees (positive for N/E, negative for S/W)
 */
function parseGpsCoordinate(coord?: string, ref?: string): number | undefined {
  try {
    // Validate inputs
    if (
      !coord ||
      typeof coord !== 'string' ||
      !ref ||
      typeof ref !== 'string'
    ) {
      return undefined;
    }

    // Validate reference direction
    const validRef = ref.trim().toUpperCase();
    if (!['N', 'S', 'E', 'W'].includes(validRef)) {
      return undefined;
    }

    // Parse coordinate parts
    const parts = coord.split(',').map((part) => {
      const num = parseFloat(part.trim());
      return isNaN(num) ? undefined : num;
    });

    // Validate we have all three parts (degrees, minutes, seconds)
    if (parts.length !== 3 || parts.includes(undefined)) {
      return undefined;
    }

    const [degrees, minutes, seconds] = parts as number[];

    // Validate ranges
    if (degrees === undefined || minutes === undefined || seconds === undefined)
      return undefined;
    if (
      degrees < 0 ||
      degrees > 180 || // Max 180° for longitude
      minutes < 0 ||
      minutes >= 60 ||
      seconds < 0 ||
      seconds >= 60
    ) {
      return undefined;
    }

    // Convert to decimal degrees
    let decimal = degrees + minutes / 60 + seconds / 3600;

    // Validate final result
    if (decimal > 180) {
      // Maximum possible coordinate value
      return undefined;
    }

    // Apply direction
    if (validRef === 'S' || validRef === 'W') {
      decimal = -decimal;
    }

    // Ensure we return a number with reasonable precision
    return parseFloat(decimal.toFixed(8));
  } catch (error) {
    console.error('Error parsing GPS coordinate:', error);
    return undefined;
  }
}

/**
 * Test if a date string matches the expected EXIF format
 * @param dateStr Date string to test
 * @returns boolean indicating if the string matches EXIF date format
 */
function isValidExifDateFormat(dateStr: string): boolean {
  const regex = /^\d{4}:\d{2}:\d{2} \d{2}:\d{2}:\d{2}$/;
  return regex.test(dateStr);
}

/**
 * Extract EXIF data from an ArrayBuffer
 * @param buffer Raw image data
 * @returns Normalized EXIF data
 */
export function extractExifData(buffer: ArrayBuffer): ExifData {
  const parser = new ExifParser(buffer);
  const rawExif = parser.parse();
  return normalizeExifData(rawExif);
}

/**
 * Read EXIF data from a File object
 * @param file Image file
 * @returns Promise resolving to normalized EXIF data
 */
export async function readImageExif(
  imageBuffer: ArrayBuffer,
): Promise<ExifData | undefined> {
  try {
    // const buffer = await file.arrayBuffer();
    return extractExifData(imageBuffer);
  } catch (error) {
    console.error('Error reading EXIF:', error);
    return undefined;
  }
}

/**
 * Read EXIF data from an image URL
 * @param url Image URL
 * @returns Promise resolving to normalized EXIF data
 */
export async function readImageExifFromUrl(
  url: string,
): Promise<ExifData | undefined> {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const buffer = await response.arrayBuffer();
    return extractExifData(buffer);
  } catch (error) {
    console.error('Error reading EXIF from URL:', error);
    return undefined;
  }
}

/**
 * Format EXIF data as a human-readable string
 * @param data Normalized EXIF data
 * @returns Formatted string representation
 */
export function formatExifDataToString(data: ExifData): string {
  const lines: string[] = [];

  if (data.make || data.model) {
    lines.push(`Camera: ${[data.make, data.model].filter(Boolean).join(' ')}`);
  }

  if (data.dateTaken) {
    lines.push(`Date: ${new Date(data.dateTaken).toLocaleString()}`);
  }

  if (data.exposureTime) {
    lines.push(`Exposure: ${data.exposureTime}s`);
  }

  if (data.fNumber) {
    lines.push(`Aperture: f/${data.fNumber}`);
  }

  if (data.iso) {
    lines.push(`ISO: ${data.iso}`);
  }

  if (data.focalLength) {
    lines.push(
      `Focal Length: ${data.focalLength}mm` +
        (data.focalLengthIn35mm
          ? ` (${data.focalLengthIn35mm}mm in 35mm)`
          : ''),
    );
  }

  if (data.gpsLatitude !== undefined && data.gpsLongitude !== undefined) {
    lines.push(
      `Location: ${data.gpsLatitude.toFixed(6)}, ${data.gpsLongitude.toFixed(6)}`,
    );
  }

  return lines.join('\n');
}
