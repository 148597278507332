import { IRenderable, Point } from '@repo/drawing';
import { SnapResult } from '@repo/snap-engine';

interface SnapOverlayConfig {
  snapResult: SnapResult;
  guideColor?: string;
  pointColor?: string;
  lineWidth?: number;
}

export class SnapOverlay implements IRenderable {
  private readonly snapResult: SnapResult;
  private readonly guideColor: string;
  private readonly pointColor: string;
  private readonly lineWidth: number;

  constructor(config: SnapOverlayConfig) {
    this.snapResult = config.snapResult;
    this.guideColor = config.guideColor ?? 'rgba(255, 0, 0, 0.5)'; // Changed to red for testing
    this.pointColor = config.pointColor ?? 'rgba(255, 0, 0, 0.8)';
    this.lineWidth = config.lineWidth ?? 2; // Increased for visibility

    // console.log('[SnapOverlay] Initialized with:', {
    //     snapResult: this.snapResult,
    //     guideColor: this.guideColor,
    //     pointColor: this.pointColor,
    //     lineWidth: this.lineWidth
    // });
  }

  renderTo(ctx: CanvasRenderingContext2D): void {
    // console.log('[SnapOverlay] Rendering with result:', this.snapResult);

    if (!this.snapResult.snapped) {
      // console.log('[SnapOverlay] No snap, skipping render');
      return;
    }

    ctx.save();

    // Draw guides with strength-based opacity
    if (this.snapResult.guides.length > 0) {
      // console.log('[SnapOverlay] Rendering guides:', this.snapResult.guides);

      this.snapResult.guides.forEach((guide) => {
        const baseOpacity = 0.8; // Increased for visibility
        const strengthFactor = guide.strength;
        const confidenceFactor = this.snapResult.metadata?.confidence ?? 1;
        const opacity = baseOpacity * strengthFactor * confidenceFactor;

        const color = this.guideColor.replace(
          /rgba\((\d+),\s*(\d+),\s*(\d+),\s*[\d.]+\)/,
          `rgba($1, $2, $3, ${opacity})`,
        );

        ctx.strokeStyle = color;
        ctx.lineWidth = this.lineWidth;

        ctx.beginPath();
        if (guide.type === 'horizontal') {
          ctx.moveTo(guide.start, guide.position);
          ctx.lineTo(guide.end, guide.position);
        } else if (guide.type === 'vertical') {
          ctx.moveTo(guide.position, guide.start);
          ctx.lineTo(guide.position, guide.end);
        }
        ctx.stroke();
      });
    }

    // Draw snap point with confidence-based size
    // console.log('[SnapOverlay] Rendering snap point at:', this.snapResult.position);

    const confidence = this.snapResult.metadata?.confidence ?? 1;
    const baseRadius = 6; // Increased for visibility
    const radius = baseRadius * confidence;

    ctx.fillStyle = this.pointColor;
    ctx.beginPath();
    ctx.arc(
      this.snapResult.position.x,
      this.snapResult.position.y,
      radius,
      0,
      Math.PI * 2,
    );
    ctx.fill();

    ctx.restore();
  }
}
