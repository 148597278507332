import { Table, SchemaTableDefinition, SchemaManager } from '../table.js';
import { TableName, UpgradeSchemaContext } from '../types.js';
import {
  CacheMetadata,
  FileMetadata,
  VersionMetadata,
} from '../filecache/v1-types.js';

// Define literal string types for table names
export const TABLE_NAMES = {
  DATA: 'data',
  FILE_META: 'fileMeta',
  CACHE_META: 'cacheMeta',
  VERSION: 'version',
} as const;

export interface SchemaV1<TCacheExt, TFileExt> {
  tables: {
    data: Table<'data', ArrayBuffer>;
    fileMeta: Table<'fileMeta', FileMetadata<TFileExt>>;
    cacheMeta: Table<'cacheMeta', CacheMetadata<TCacheExt>>;
  };
}

// Define schema structure
const SCHEMA_DEFINITIONS = {
  data: {
    name: TABLE_NAMES.DATA as TableName<'data'>,
    storeName: 'data',
  } as SchemaTableDefinition<'data', ArrayBuffer>,

  fileMeta: {
    name: TABLE_NAMES.FILE_META as TableName<'fileMeta'>,
    storeName: 'file-meta',
    keyPath: 'key',
  } as SchemaTableDefinition<'fileMeta', FileMetadata<any>>,

  cacheMeta: {
    name: TABLE_NAMES.CACHE_META as TableName<'cacheMeta'>,
    storeName: 'cache-meta',
    keyPath: 'key',
    indexes: [
      { name: 'timestamp', keyPath: 'timestamp' },
      { name: 'lastAccess', keyPath: 'lastAccess' },
      { name: 'expiresAt', keyPath: 'expiresAt' },
    ],
  } as SchemaTableDefinition<'cacheMeta', CacheMetadata<any>>,
};

export function createV1Tables<TCacheExt, TFileExt>(
  prefix: string,
): SchemaV1<TCacheExt, TFileExt>['tables'] {
  return {
    data: Table.fromDefinition(prefix, SCHEMA_DEFINITIONS.data),
    fileMeta: Table.fromDefinition(prefix, SCHEMA_DEFINITIONS.fileMeta),
    cacheMeta: Table.fromDefinition(prefix, SCHEMA_DEFINITIONS.cacheMeta),
  };
}

export const FileCacheSchema = {
  currentVersion: 1,

  upgradeSchema: (ctx: UpgradeSchemaContext, prefix: string) => {
    const { db, prevVersion } = ctx;
    console.log(
      `Upgrading file cache schema from v${prevVersion} to v${FileCacheSchema.currentVersion}`,
    );

    // Create or update tables
    const tables = createV1Tables(prefix);
    SchemaManager.upgradeSchema(db, Object.values(tables));
  },

  validateSchema: (db: IDBDatabase, prefix: string): boolean => {
    const tables = createV1Tables(prefix);
    return SchemaManager.validateSchema(db, Object.values(tables));
  },
};
