import { Point, Transform, Bounds } from '@repo/drawing';
import {
  GridConfig,
  SnapPoint,
  SnapGuide,
  SnapResult,
  SnapBehavior,
  SnapPointType,
  SpatialHashGrid,
  SnapCache,
} from './types.js';

export * from './types.js';

export interface BandConfig {
  width: number;
  height: number;
  orientation: 'horizontal' | 'vertical';
  divisions?: number[]; // Make divisions configurable
}
export class SnapEngine {
  private snapThreshold: number;
  private behavior: SnapBehavior;
  private bandConfig: BandConfig;
  spatialHash: SpatialHashGrid;
  preCalculatedPoints: SnapPoint[];
  private snapCache: Map<string, SnapCache>;
  private readonly cacheTimeout: number;
  private cellSize: number;

  constructor(config: {
    snapThreshold: number;
    behavior: SnapBehavior;
    bandConfig: BandConfig;
    cacheTimeout?: number;
  }) {
    this.validateConfig(config);

    this.snapThreshold = config.snapThreshold;
    this.behavior = config.behavior;
    this.bandConfig = {
      ...config.bandConfig,
      divisions: config.bandConfig.divisions ?? [2, 3, 4, 7],
    };
    this.cacheTimeout = config.cacheTimeout ?? 100;
    this.cellSize = this.snapThreshold * 2;
    this.snapCache = new Map();

    // Initialize in correct order
    this.preCalculatedPoints = this.calculateFixedSnapPoints();
    this.spatialHash = this.initializeSpatialHash(this.preCalculatedPoints);
  }

  private validateConfig(config: any) {
    if (!config.snapThreshold || config.snapThreshold <= 0) {
      throw new Error('Invalid snap threshold');
    }
    if (!config.bandConfig?.width || !config.bandConfig?.height) {
      throw new Error('Invalid band configuration');
    }
  }

  private initializeSpatialHash(points: SnapPoint[]): SpatialHashGrid {
    const bounds: Bounds = {
      x: 0,
      y: 0,
      width: this.bandConfig.width,
      height: this.bandConfig.height,
    };

    const grid: SpatialHashGrid = {
      cellSize: this.cellSize,
      bounds,
      points: new Map(),
    };

    // Populate spatial hash with points
    points.forEach((point) => {
      const cell = this.getHashKey(point.position, this.cellSize);
      const cellPoints = grid.points.get(cell) ?? [];
      cellPoints.push(point);
      grid.points.set(cell, cellPoints);
    });

    return grid;
  }

  private getHashKey(point: Point, cellSize: number): string {
    const cellX = Math.floor(point.x / cellSize);
    const cellY = Math.floor(point.y / cellSize);
    return `${cellX},${cellY}`;
  }

  private getNearbyPoints(point: Point): SnapPoint[] {
    const nearbyPoints: SnapPoint[] = [];

    // Check surrounding cells
    for (let dx = -1; dx <= 1; dx++) {
      for (let dy = -1; dy <= 1; dy++) {
        const cellX = Math.floor(point.x / this.cellSize) + dx;
        const cellY = Math.floor(point.y / this.cellSize) + dy;
        const key = `${cellX},${cellY}`;
        const cellPoints = this.spatialHash.points.get(key) ?? [];
        nearbyPoints.push(...cellPoints);
      }
    }

    return nearbyPoints;
  }

  private calculateFixedSnapPoints(): SnapPoint[] {
    const points: SnapPoint[] = [];
    const { width, height, orientation } = this.bandConfig;

    // Add points based on behavior configuration
    if (this.behavior.points.enabled) {
      // Add corners if configured
      if (this.behavior.points.types.includes('corner')) {
        points.push(
          this.createSnapPoint('corner', new Point(0, 0)),
          this.createSnapPoint('corner', new Point(width, 0)),
          this.createSnapPoint('corner', new Point(0, height)),
          this.createSnapPoint('corner', new Point(width, height)),
        );
      }

      // Add center points if configured
      if (this.behavior.points.types.includes('center')) {
        points.push(
          this.createSnapPoint('center', new Point(width / 2, height / 2), 2),
        );
      }

      // Add edge points if configured
      if (this.behavior.points.types.includes('edge')) {
        points.push(
          this.createSnapPoint('edge', new Point(width / 2, 0), 1.5),
          this.createSnapPoint('edge', new Point(width / 2, height), 1.5),
          this.createSnapPoint('edge', new Point(0, height / 2), 1.5),
          this.createSnapPoint('edge', new Point(width, height / 2), 1.5),
        );
      }
    }

    // Add division points
    if (this.behavior.points.types.includes('grid')) {
      this.addDivisionPoints(points);
    }

    return points;
  }

  private createSnapPoint(
    type: SnapPointType,
    position: Point,
    strengthMultiplier = 1,
  ): SnapPoint {
    return {
      type,
      position,
      strength: this.behavior.points.strength * strengthMultiplier,
      metadata: {
        sourceId: type,
        label: type,
      },
    };
  }

  private addDivisionPoints(points: SnapPoint[]) {
    const { width, height, orientation, divisions } = this.bandConfig;

    divisions?.forEach((div) => {
      const isSeventhDiv = div === 7;
      const strengthMultiplier = isSeventhDiv ? 2 : 1;

      if (orientation === 'horizontal') {
        for (let i = 1; i < div; i++) {
          points.push(
            this.createSnapPoint(
              'grid',
              new Point((width * i) / div, height / 2),
              strengthMultiplier,
            ),
          );
        }
      } else {
        for (let i = 1; i < div; i++) {
          points.push(
            this.createSnapPoint(
              'grid',
              new Point(width / 2, (height * i) / div),
              strengthMultiplier,
            ),
          );
        }
      }
    });
  }

  calculateSnap(point: Point): SnapResult {
    try {
      const cacheKey = `${Math.round(point.x)},${Math.round(point.y)}`;
      const cached = this.snapCache.get(cacheKey);

      if (cached && Date.now() - cached.timestamp < this.cacheTimeout) {
        return cached.result;
      }

      const nearbyPoints = this.getNearbyPoints(point).filter(
        (snapPoint) =>
          this.distance(point, snapPoint.position) <=
          this.snapThreshold * snapPoint.strength,
      );

      const result = this.calculateSnapResult(point, nearbyPoints);

      // Cache the result
      this.snapCache.set(cacheKey, {
        key: cacheKey,
        result,
        timestamp: Date.now(),
        validityDuration: this.cacheTimeout,
      });

      return result;
    } catch (error) {
      console.error('Error in calculateSnap:', error);
      return {
        snapped: false,
        position: point,
        guides: [],
        metadata: {
          confidence: 0,
          snapDistance: 0,
        },
      };
    }
  }

  private calculateSnapResult(
    point: Point,
    nearbyPoints: SnapPoint[],
  ): SnapResult {
    if (nearbyPoints.length === 0) {
      return {
        snapped: false,
        position: point,
        guides: [],
        metadata: {
          confidence: 0,
          snapDistance: 0,
        },
      };
    }

    // Calculate weighted average for snap position
    const totalWeight = nearbyPoints.reduce((sum, p) => sum + p.strength, 0);
    const snappedPosition = new Point(
      nearbyPoints.reduce((sum, p) => sum + p.position.x * p.strength, 0) /
        totalWeight,
      nearbyPoints.reduce((sum, p) => sum + p.position.y * p.strength, 0) /
        totalWeight,
    );

    // Generate guides
    const guides = this.generateGuides(nearbyPoints, point);

    // Calculate confidence based on distance and number of snap points
    const avgDistance = this.distance(point, snappedPosition);
    const confidence = Math.max(
      0,
      1 -
        avgDistance /
          (this.snapThreshold *
            Math.max(...nearbyPoints.map((p) => p.strength))),
    );

    return {
      snapped: true,
      position: snappedPosition,
      guides,
      metadata: {
        confidence,
        snapDistance: avgDistance,
        snapPoints: nearbyPoints,
      },
    };
  }

  private generateGuides(
    snapPoints: SnapPoint[],
    originalPoint: Point,
  ): SnapGuide[] {
    if (!this.behavior.guides.enabled) {
      return [];
    }

    const guides: SnapGuide[] = [];
    const { width, height } = this.bandConfig;

    snapPoints.forEach((snapPoint) => {
      // Vertical guide
      if (
        this.behavior.guides.types.includes('vertical') &&
        Math.abs(originalPoint.x - snapPoint.position.x) <= this.snapThreshold
      ) {
        guides.push({
          type: 'vertical',
          position: snapPoint.position.x,
          start: 0,
          end: height,
          strength: snapPoint.strength * this.behavior.guides.strength,
          metadata: {
            sourceId: snapPoint.metadata?.sourceId,
            label: snapPoint.metadata?.label,
          },
        });
      }

      // Horizontal guide
      if (
        this.behavior.guides.types.includes('horizontal') &&
        Math.abs(originalPoint.y - snapPoint.position.y) <= this.snapThreshold
      ) {
        guides.push({
          type: 'horizontal',
          position: snapPoint.position.y,
          start: 0,
          end: width,
          strength: snapPoint.strength * this.behavior.guides.strength,
          metadata: {
            sourceId: snapPoint.metadata?.sourceId,
            label: snapPoint.metadata?.label,
          },
        });
      }
    });

    return this.deduplicateGuides(guides);
  }

  private distance(p1: Point, p2: Point): number {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  private deduplicateGuides(guides: SnapGuide[]): SnapGuide[] {
    const grouped = new Map<string, SnapGuide>();

    guides.forEach((guide) => {
      const key = `${guide.type}-${Math.round(guide.position)}`;
      const existing = grouped.get(key);

      if (!existing || existing.strength < guide.strength) {
        grouped.set(key, guide);
      }
    });

    return Array.from(grouped.values());
  }

  clearCache(): void {
    this.snapCache = new Map();
  }

  setConfig(
    config: Partial<{
      snapThreshold: number;
      behavior: Partial<SnapBehavior>;
      bandConfig: Partial<BandConfig>;
    }>,
  ): void {
    let needsReinitialize = false;

    if (config.snapThreshold) {
      this.snapThreshold = config.snapThreshold;
      this.cellSize = this.snapThreshold * 2;
      needsReinitialize = true;
    }
    if (config.behavior) {
      this.behavior = { ...this.behavior, ...config.behavior };
      needsReinitialize = true;
    }
    if (config.bandConfig) {
      this.bandConfig = { ...this.bandConfig, ...config.bandConfig };
      needsReinitialize = true;
    }

    if (needsReinitialize) {
      // Recalculate points and spatial hash
      this.preCalculatedPoints = this.calculateFixedSnapPoints();
      this.spatialHash = this.initializeSpatialHash(this.preCalculatedPoints);
      this.clearCache();
    }
  }
}
