import { TableName } from './types.js';

export interface TableOptions {
  keyPath?: string | string[];
  autoIncrement?: boolean;
}

export interface IndexOptions {
  unique?: boolean;
  multiEntry?: boolean;
}

export interface IndexDefinition {
  name: string;
  keyPath: string | string[];
  options?: IndexOptions;
}

export interface SchemaTableDefinition<N extends string, T> {
  name: TableName<N>;
  storeName: string;
  keyPath?: string;
  autoIncrement?: boolean;
  indexes?: IndexDefinition[];
}

/**
 * Table class that enforces type safety for IDB object stores
 */
export class Table<N extends string, T> {
  readonly name: TableName<N>;
  readonly postfix: string;
  readonly type!: T; // Type marker, not used at runtime
  readonly options?: TableOptions;
  readonly indexes: IndexDefinition[];

  constructor(
    name: TableName<N>,
    postfix: string,
    options?: TableOptions,
    indexes: IndexDefinition[] = [],
  ) {
    this.name = name;
    this.postfix = postfix;
    this.options = options;
    this.indexes = indexes;
  }

  /**
   * Creates or updates the object store and its indexes in the database
   */
  ensureStore(db: IDBDatabase): IDBObjectStore {
    let store: IDBObjectStore;

    if (db.objectStoreNames.contains(this.postfix)) {
      // Store exists - get reference and validate/update
      console.log(
        `Table exists: ${this.postfix}, validating/updating configuration`,
      );
      store = db
        .transaction(this.postfix, 'readonly')
        .objectStore(this.postfix);

      // Validate and update indexes
      this.ensureIndexes(store);
    } else {
      // Create new store
      console.log(`Creating table: ${this.postfix}`);
      store = db.createObjectStore(this.postfix, this.options);

      // Create initial indexes
      for (const index of this.indexes) {
        store.createIndex(index.name, index.keyPath, index.options);
      }
    }

    return store;
  }

  /**
   * Ensures all indexes exist with correct configuration
   */
  private ensureIndexes(store: IDBObjectStore): void {
    // Check existing indexes
    for (const index of this.indexes) {
      if (!store.indexNames.contains(index.name)) {
        console.log(`Creating missing index: ${index.name} on ${this.postfix}`);
        store.createIndex(index.name, index.keyPath, index.options);
      } else {
        // Validate existing index
        const existingIndex = store.index(index.name);
        if (!this.validateIndex(existingIndex, index)) {
          // If validation fails, recreate the index
          console.log(
            `Recreating invalid index: ${index.name} on ${this.postfix}`,
          );
          store.deleteIndex(index.name);
          store.createIndex(index.name, index.keyPath, index.options);
        }
      }
    }
  }

  /**
   * Validates the complete table configuration
   */
  validate(db: IDBDatabase): boolean {
    if (!db.objectStoreNames.contains(this.postfix)) {
      console.error(`Missing table: ${this.postfix}`);
      return false;
    }

    const store = db
      .transaction(this.postfix, 'readonly')
      .objectStore(this.postfix);

    // Validate store configuration
    if (this.options?.keyPath && store.keyPath !== this.options.keyPath) {
      console.error(`Invalid keyPath for ${this.postfix}`);
      return false;
    }

    if (
      this.options?.autoIncrement !== undefined &&
      store.autoIncrement !== this.options.autoIncrement
    ) {
      console.error(`Invalid autoIncrement for ${this.postfix}`);
      return false;
    }

    // Validate indexes
    for (const index of this.indexes) {
      if (!store.indexNames.contains(index.name)) {
        console.error(`Missing index ${index.name} on ${this.postfix}`);
        return false;
      }

      const storeIndex = store.index(index.name);
      if (!this.validateIndex(storeIndex, index)) {
        console.error(
          `Invalid index configuration for ${index.name} on ${this.postfix}`,
        );
        return false;
      }
    }

    return true;
  }

  /**
   * Helper to validate index configuration
   */
  private validateIndex(actual: IDBIndex, expected: IndexDefinition): boolean {
    // Validate keyPath
    if (Array.isArray(expected.keyPath)) {
      if (
        !Array.isArray(actual.keyPath) ||
        actual.keyPath.join(',') !== expected.keyPath.join(',')
      ) {
        return false;
      }
    } else if (actual.keyPath !== expected.keyPath) {
      return false;
    }

    // Validate options if specified
    if (expected.options?.unique !== undefined) {
      if (actual.unique !== expected.options.unique) {
        return false;
      }
    }
    if (expected.options?.multiEntry !== undefined) {
      if (actual.multiEntry !== expected.options.multiEntry) {
        return false;
      }
    }

    return true;
  }

  /**
   * Creates a properly prefixed store name
   */
  static createStoreName(prefix: string, baseName: string): string {
    return `${prefix}-${baseName}`;
  }

  /**
   * Creates a table definition from SchemaTableDefinition
   */
  static fromDefinition<N extends string, T>(
    prefix: string,
    def: SchemaTableDefinition<N, T>,
  ): Table<N, T> {
    return new Table<N, T>(
      def.name,
      Table.createStoreName(prefix, def.storeName),
      {
        keyPath: def.keyPath,
        autoIncrement: def.autoIncrement,
      },
      def.indexes || [],
    );
  }
}

// Helper class for managing sets of tables
export class SchemaManager {
  /**
   * Validates all tables in a schema
   */
  static validateSchema(db: IDBDatabase, tables: Table<any, any>[]): boolean {
    for (const table of tables) {
      if (!table.validate(db)) {
        return false;
      }
    }
    return true;
  }

  /**
   * Ensures all tables exist with correct configuration
   */
  static upgradeSchema(db: IDBDatabase, tables: Table<any, any>[]): void {
    for (const table of tables) {
      table.ensureStore(db);
    }
  }
}

//   /**
//    * Creates the object store and its indexes in the database
//    */
//   create(db: IDBDatabase): IDBObjectStore {
//     if (db.objectStoreNames.contains(this.postfix)) {
//       throw new Error(`Table ${this.postfix} already exists`);
//     }

//     const store = db.createObjectStore(this.postfix, this.options);

//     // Create indexes
//     for (const index of this.indexes) {
//       store.createIndex(index.name, index.keyPath, index.options);
//     }

//     return store;
//   }

//   /**
//    * Validates that the store exists and has the correct configuration
//    */
//   validate(db: IDBDatabase): boolean {
//     if (!db.objectStoreNames.contains(this.postfix)) {
//       return false;
//     }

//     const transaction = db.transaction(this.postfix, 'readonly');
//     const store = transaction.objectStore(this.postfix);

//     // Validate keyPath if specified
//     if (this.options?.keyPath) {
//       if (store.keyPath !== this.options.keyPath) {
//         return false;
//       }
//     }

//     // Validate autoIncrement if specified
//     if (this.options?.autoIncrement !== undefined) {
//       if (store.autoIncrement !== this.options.autoIncrement) {
//         return false;
//       }
//     }

//     // Validate indexes
//     for (const index of this.indexes) {
//       try {
//         const storeIndex = store.index(index.name);
//         if (!this.#validateIndex(storeIndex, index)) {
//           return false;
//         }
//       } catch {
//         return false;
//       }
//     }

//     return true;
//   }

//   /**
//    * Helper to validate index configuration
//    */
//   #validateIndex(actual: IDBIndex, expected: IndexDefinition): boolean {
//     // Validate keyPath
//     if (Array.isArray(expected.keyPath)) {
//       if (
//         !Array.isArray(actual.keyPath) ||
//         actual.keyPath.join(',') !== expected.keyPath.join(',')
//       ) {
//         return false;
//       }
//     } else if (actual.keyPath !== expected.keyPath) {
//       return false;
//     }

//     // Validate options if specified
//     if (expected.options?.unique !== undefined) {
//       if (actual.unique !== expected.options.unique) {
//         return false;
//       }
//     }
//     if (expected.options?.multiEntry !== undefined) {
//       if (actual.multiEntry !== expected.options.multiEntry) {
//         return false;
//       }
//     }

//     return true;
//   }

//   /**
//    * Creates a properly prefixed store name
//    */
//   static createStoreName(prefix: string, baseName: string): string {
//     return `${prefix}-${baseName}`;
//   }

//   /**
//    * Creates a table definition from SchemaTableDefinition
//    */
//   static fromDefinition<N extends string, T>(
//     prefix: string,
//     def: SchemaTableDefinition<N, T>
//   ): Table<N, T> {
//     return new Table<N, T>(
//       def.name,
//       Table.createStoreName(prefix, def.storeName),
//       {
//         keyPath: def.keyPath,
//         autoIncrement: def.autoIncrement
//       },
//       def.indexes || []
//     );
//   }
// }

/**
 * Helper to create common table configurations
 */
export const TableBuilders = {
  /**
   * Creates a table with a key path and optional indexes
   */
  withKey<N extends string, T>(
    name: TableName<N>,
    prefix: string,
    storeName: string,
    keyPath: string,
    indexes: IndexDefinition[] = [],
  ): Table<N, T> {
    return new Table<N, T>(
      name,
      Table.createStoreName(prefix, storeName),
      { keyPath },
      indexes,
    );
  },

  /**
   * Creates a table for simple key-value storage
   */
  keyValue<N extends string, T>(
    name: TableName<N>,
    prefix: string,
    storeName: string,
  ): Table<N, T> {
    return new Table<N, T>(name, Table.createStoreName(prefix, storeName));
  },

  /**
   * Creates a table with auto-incrementing keys
   */
  autoIncrement<N extends string, T>(
    name: TableName<N>,
    prefix: string,
    storeName: string,
    keyPath: string,
  ): Table<N, T> {
    return new Table<N, T>(name, Table.createStoreName(prefix, storeName), {
      keyPath,
      autoIncrement: true,
    });
  },
};
