import { EXIF_TAGS, ExifTagDefinition } from './ExifTags.js';

// Raw EXIF data from parser
export type RawExifData = { [key: string]: string };

// Normalized EXIF metadata with strongly typed fields
export interface ExifData {
  raw: RawExifData; // Preserve raw data for debugging/custom processing

  // Core device info
  make?: string;
  model?: string;
  software?: string;

  // Image capture
  dateTaken?: number;
  orientation?: number;
  exposureTime?: string; // Formatted as fraction if < 1
  fNumber?: number;
  focalLength?: number;
  focalLengthIn35mm?: number;
  iso?: number;

  // Location
  gpsLatitude?: number;
  gpsLongitude?: number;

  // Rights
  artist?: string;
  copyright?: string;

  // Additional data
  meteringMode?: string;
  whiteBalance?: string;
  flash?: string;
  exposureProgram?: string;
  exposureBiasValue?: number;
}

// EXIF data types
const EXIF_TYPES = {
  BYTE: 1, // 8-bit unsigned int
  ASCII: 2, // 8-bit byte containing ASCII
  SHORT: 3, // 16-bit unsigned int
  LONG: 4, // 32-bit unsigned int
  RATIONAL: 5, // Two LONGs, numerator/denominator
  SBYTE: 6, // 8-bit signed int
  UNDEFINED: 7, // 8-bit byte
  SSHORT: 8, // 16-bit signed int
  SLONG: 9, // 32-bit signed int
  SRATIONAL: 10, // Two SLONGs
  FLOAT: 11, // 32-bit float
  DOUBLE: 12, // 64-bit float
} as const;

export class ExifParser {
  private dataView: DataView;
  private littleEndian: boolean = false;
  private tiffOffset: number = 0;

  constructor(buffer: ArrayBuffer) {
    this.dataView = new DataView(buffer);
  }

  public parse(): RawExifData {
    try {
      if (this.dataView.getUint16(0) !== 0xffd8) {
        throw new Error('Not a valid JPEG');
      }

      return this.parseJpegSegments();
    } catch (error) {
      throw new Error(`Failed to parse EXIF data: ${error}`);
    }
  }

  private parseJpegSegments(): RawExifData {
    let offset = 2;
    const exifData: RawExifData = {};

    while (offset < this.dataView.byteLength) {
      const marker = this.dataView.getUint16(offset);
      offset += 2;

      // Look for APP1 marker (0xFFE1) containing EXIF
      if (marker === 0xffe1) {
        const length = this.dataView.getUint16(offset);
        offset += 2;

        const exifHeader = this.getStringFromBuffer(offset, 6);
        if (exifHeader === 'Exif\0\0') {
          offset += 6;
          this.tiffOffset = offset;
          this.parseTiffHeader(offset);
          this.parseIfdData(exifData);
          break;
        }

        offset += length - 2;
      } else if ((marker & 0xff00) !== 0xff00) {
        break;
      } else {
        offset += this.dataView.getUint16(offset) + 2;
      }
    }

    return this.formatExifData(exifData);
  }

  private parseTiffHeader(offset: number): void {
    const byteOrder = this.getStringFromBuffer(offset, 2);
    this.littleEndian = byteOrder === 'II';

    const magicNumber = this.readUint16(offset + 2);
    if (magicNumber !== 0x002a) {
      throw new Error('Invalid TIFF data');
    }
  }

  private parseIfdData(exifData: RawExifData): void {
    const ifdOffset = this.readUint32(this.tiffOffset + 4);
    this.parseIfd('IFD0', this.tiffOffset + ifdOffset, exifData);
  }

  private parseIfd(
    ifdType: string,
    offset: number,
    exifData: RawExifData,
  ): void {
    const numEntries = this.readUint16(offset);
    offset += 2;

    for (let i = 0; i < numEntries; i++) {
      const entryOffset = offset + i * 12;
      const tag = this.readUint16(entryOffset);
      const type = this.readUint16(entryOffset + 2);
      const count = this.readUint32(entryOffset + 4);
      const valueOffset = entryOffset + 8;

      // Handle special cases for sub-IFDs
      if (tag === 0x8769) {
        // EXIF Sub-IFD
        const subOffset = this.readUint32(valueOffset);
        this.parseIfd('EXIF', this.tiffOffset + subOffset, exifData);
        continue;
      }

      if (tag === 0x8825) {
        // GPS Sub-IFD
        const subOffset = this.readUint32(valueOffset);
        this.parseIfd('GPS', this.tiffOffset + subOffset, exifData);
        continue;
      }

      // Find tag definition
      const tagDef = this.findTagDefinition(ifdType, tag);
      if (!tagDef) continue;

      const value = this.readTagValue(type, count, valueOffset);
      if (value !== undefined) {
        exifData[tagDef.name] = value;
      }
    }
  }

  private findTagDefinition(
    ifdType: string,
    tag: number,
  ): ExifTagDefinition | undefined {
    const ifdTags = EXIF_TAGS[ifdType];
    return ifdTags?.[tag];
  }

  private readTagValue(type: number, count: number, offset: number): any {
    switch (type) {
      case EXIF_TYPES.BYTE:
      case EXIF_TYPES.SBYTE:
        return this.readUint8(offset);

      case EXIF_TYPES.ASCII:
        if (count <= 4) {
          return this.getStringFromBuffer(offset, count).replace(/\0+$/, '');
        }
        const stringOffset = this.readUint32(offset);
        return this.getStringFromBuffer(
          this.tiffOffset + stringOffset,
          count,
        ).replace(/\0+$/, '');

      case EXIF_TYPES.SHORT:
      case EXIF_TYPES.SSHORT:
        if (count === 1) {
          return this.readUint16(offset);
        }
        return Array.from({ length: count }, (_, i) =>
          this.readUint16(offset + i * 2),
        );

      case EXIF_TYPES.LONG:
      case EXIF_TYPES.SLONG:
        if (count === 1) {
          return this.readUint32(offset);
        }
        return Array.from({ length: count }, (_, i) =>
          this.readUint32(offset + i * 4),
        );

      case EXIF_TYPES.RATIONAL:
      case EXIF_TYPES.SRATIONAL:
        const num = this.readUint32(this.tiffOffset + this.readUint32(offset));
        const den = this.readUint32(
          this.tiffOffset + this.readUint32(offset) + 4,
        );
        return den === 0 ? 0 : num / den;

      default:
        return undefined;
    }
  }

  private formatExifData(rawExif: RawExifData): RawExifData {
    const formatted: RawExifData = {};

    for (const [key, value] of Object.entries(rawExif)) {
      if (value === undefined || value === null) continue;

      // Format specific values
      switch (key) {
        case 'ExposureTime':
          const expTime = parseFloat(value);
          formatted[key] =
            expTime >= 1 ? expTime.toString() : `1/${Math.round(1 / expTime)}`;
          break;

        case 'FNumber':
          formatted[key] = `f/${value}`;
          break;

        case 'FocalLength':
        case 'FocalLengthIn35mmFilm':
          formatted[key] = `${value}mm`;
          break;

        default:
          formatted[key] = value.toString();
      }
    }

    return formatted;
  }

  // Helper methods for reading values
  private readUint8(offset: number): number {
    return this.dataView.getUint8(offset);
  }

  private readUint16(offset: number): number {
    return this.dataView.getUint16(offset, this.littleEndian);
  }

  private readUint32(offset: number): number {
    return this.dataView.getUint32(offset, this.littleEndian);
  }

  private getStringFromBuffer(start: number, length: number): string {
    const bytes = Array.from({ length }, (_, i) =>
      this.dataView.getUint8(start + i),
    );
    return String.fromCharCode(...bytes);
  }
}
