/**
 * Core interfaces and types for image processing
 */
export interface ImageDimensions {
  width: number;
  height: number;
}

export interface ResizeResult extends ImageDimensions {
  scale: number;
}

export interface ImageProcessingResult {
  img: HTMLImageElement;
  blob: Blob;
  dimensions: {
    original: ImageDimensions;
    final: ImageDimensions;
  };
}

export interface ProcessProgress {
  stage: 'loading' | 'preprocessing' | 'caching' | 'uploading' | 'registering';
  progress: number;
  detail?: string;
}

/**
 * Constants for image processing
 */
export const IMAGE_CONSTANTS = {
  MAX_CANVAS_DIMENSIONS: 16777216, // 4096 * 4096
  MIN_DIMENSION: 16,
  DEFAULT_COMPRESSION_QUALITY: 0.9,
  DEFAULT_MIME_TYPE: 'image/webp',
  DEFAULT_MAX_FILE_SIZE: 20 * 1024 * 1024, // 20MB
} as const;

/**
 * Core utilities for working with images and array buffers
 */
export class ImageBufferUtils {
  /**
   *
   */
  static async imageFromCanvas(
    canvas: HTMLCanvasElement,
  ): Promise<HTMLImageElement> {
    // TODO(pbirch): Implement retry and timeout?
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = canvas.toDataURL();
    });
  }
  /**
   * Creates and loads an image from an ArrayBuffer
   */
  static async imageFromBuffer(
    buffer: ArrayBuffer,
    type: string,
  ): Promise<HTMLImageElement> {
    const blob = new Blob([buffer], { type });
    const img = document.createElement('img');
    const blobUrl = URL.createObjectURL(blob);

    try {
      await new Promise<void>((resolve, reject) => {
        img.onload = () => resolve();
        img.onerror = reject;
        img.src = blobUrl;
      });
      return img;
    } finally {
      URL.revokeObjectURL(blobUrl);
    }
  }

  /**
   * Reads a File as ArrayBuffer
   */
  static async readFileAsBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as ArrayBuffer);
      reader.onerror = () => reject(reader.error);
      reader.readAsArrayBuffer(file);
    });
  }

  /**
   * Fetches an image from URL as ArrayBuffer
   */
  static async fetchImageAsArrayBuffer(imageUrl: string): Promise<ArrayBuffer> {
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.status}`);
    }
    return await response.arrayBuffer();
  }

  /**
   * Calculate optimal dimensions for resizing while maintaining aspect ratio
   */
  static calculateOptimalDimensions(
    originalWidth: number,
    originalHeight: number,
    maxPixels: number,
  ): ResizeResult {
    // Input validation
    if (originalWidth <= 0 || originalHeight <= 0 || maxPixels <= 0) {
      throw new Error('Dimensions and maxPixels must be positive numbers');
    }

    // Common scaling factors that typically result in good quality
    const preferredScales = [1, 0.75, 0.5, 0.375, 0.25, 0.125];

    // Calculate theoretical maximum scale
    const theoreticalScale = Math.sqrt(
      maxPixels / (originalWidth * originalHeight),
    );

    // Start with theoretical dimensions
    let bestResult: ResizeResult = {
      width: Math.floor(originalWidth * theoreticalScale),
      height: Math.floor(originalHeight * theoreticalScale),
      scale: theoreticalScale,
    };

    // Try preferred scales first
    for (const scale of preferredScales) {
      const width = Math.floor(originalWidth * scale);
      const height = Math.floor(originalHeight * scale);

      if (width * height <= maxPixels && width > 0 && height > 0) {
        bestResult = { width, height, scale };
        break;
      }
    }

    // Fine-tune if no preferred scale works
    if (!preferredScales.includes(bestResult.scale)) {
      let left = 0;
      let right = theoreticalScale;
      const MAX_ITERATIONS = 20;
      let iterations = 0;

      while (right - left > 0.001 && iterations < MAX_ITERATIONS) {
        const mid = (left + right) / 2;
        const width = Math.floor(originalWidth * mid);
        const height = Math.floor(originalHeight * mid);

        if (width * height <= maxPixels) {
          bestResult = { width, height, scale: mid };
          left = mid;
        } else {
          right = mid;
        }

        iterations++;
      }
    }

    // Final safety check
    while (bestResult.width * bestResult.height > maxPixels) {
      bestResult.width = Math.floor(bestResult.width * 0.99);
      bestResult.height = Math.floor(bestResult.height * 0.99);
      bestResult.scale *= 0.99;
    }

    return bestResult;
  }

  /**
   * Progressively compress an image with quality control
   */
  static async compressImage(
    buffer: ArrayBuffer,
    type: string,
    compressionQuality: number = IMAGE_CONSTANTS.DEFAULT_COMPRESSION_QUALITY,
  ): Promise<ImageProcessingResult | undefined> {
    try {
      const img = await this.imageFromBuffer(buffer, type);
      const originalDimensions = {
        width: img.width,
        height: img.height,
      };

      // For images within canvas limits, compress directly
      if (img.width * img.height <= IMAGE_CONSTANTS.MAX_CANVAS_DIMENSIONS) {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        if (!ctx) throw new Error('Failed to get canvas context');

        ctx.drawImage(img, 0, 0);

        const blob = await new Promise<Blob>((resolve, reject) => {
          canvas.toBlob(
            (blob) =>
              blob ? resolve(blob) : reject(new Error('Failed to create blob')),
            type,
            compressionQuality,
          );
        });

        return {
          img,
          blob,
          dimensions: {
            original: originalDimensions,
            final: { width: img.width, height: img.height },
          },
        };
      }

      // For larger images, calculate optimal dimensions and use progressive scaling
      const { width: targetWidth, height: targetHeight } =
        this.calculateOptimalDimensions(
          img.width,
          img.height,
          IMAGE_CONSTANTS.MAX_CANVAS_DIMENSIONS,
        );

      const tempCanvas = document.createElement('canvas');
      const tempCtx = tempCanvas.getContext('2d');
      if (!tempCtx) throw new Error('Failed to get temporary canvas context');

      const scaleFactor = Math.min(
        targetWidth / img.width,
        targetHeight / img.height,
      );
      const numSteps = scaleFactor < 0.5 ? 2 : 1;
      const stepScaleFactor = scaleFactor ** (1 / numSteps);

      let currentImg: HTMLImageElement = img;
      let currentWidth = img.width;
      let currentHeight = img.height;

      // Progressive scaling steps
      for (let step = 0; step < numSteps; step++) {
        const stepWidth = Math.round(currentWidth * stepScaleFactor);
        const stepHeight = Math.round(currentHeight * stepScaleFactor);

        tempCanvas.width = stepWidth;
        tempCanvas.height = stepHeight;

        tempCtx.clearRect(0, 0, stepWidth, stepHeight);
        tempCtx.imageSmoothingEnabled = true;
        tempCtx.imageSmoothingQuality = 'high';

        tempCtx.drawImage(currentImg, 0, 0, stepWidth, stepHeight);

        if (step < numSteps - 1) {
          const stepBlob = await new Promise<Blob>((resolve, reject) => {
            tempCanvas.toBlob(
              (blob) =>
                blob
                  ? resolve(blob)
                  : reject(new Error('Failed to create intermediate blob')),
              type,
              1.0,
            );
          });

          const stepImg = new Image();
          await new Promise<void>((resolve, reject) => {
            stepImg.onload = () => resolve();
            stepImg.onerror = reject;
            stepImg.src = URL.createObjectURL(stepBlob);
          });

          currentImg = stepImg;
          currentWidth = stepWidth;
          currentHeight = stepHeight;
        }
      }

      const finalBlob = await new Promise<Blob>((resolve, reject) => {
        tempCanvas.toBlob(
          (blob) =>
            blob
              ? resolve(blob)
              : reject(new Error('Failed to create final blob')),
          type,
          compressionQuality,
        );
      });

      return {
        img,
        blob: finalBlob,
        dimensions: {
          original: originalDimensions,
          final: { width: tempCanvas.width, height: tempCanvas.height },
        },
      };
    } catch (error) {
      console.error('[ImageBufferUtils] Compression error:', error);
      return undefined;
    }
  }

  /**
   * Calculate correct aspect ratio accounting for EXIF orientation
   */
  static calculateAspectRatio(
    width: number,
    height: number,
    orientation?: number,
  ): number {
    // EXIF orientation 5-8 swap width/height
    if (orientation && orientation >= 5 && orientation <= 8) {
      return height / width;
    }
    return width / height;
  }

  /**
   * Validate image file
   */
  static validateImageFile(
    file: File,
    maxSize: number = IMAGE_CONSTANTS.DEFAULT_MAX_FILE_SIZE,
  ): void {
    if (!file.type.startsWith('image/')) {
      throw new Error('Invalid file type. Only images are supported.');
    }

    if (file.size > maxSize) {
      throw new Error(`File size exceeds maximum allowed (${maxSize} bytes)`);
    }
  }

  /**
   * Generate thumbnails for an image
   */
  static async generateThumbnails(
    img: HTMLImageElement,
    type: string,
    sizes: number[],
    quality: number = IMAGE_CONSTANTS.DEFAULT_COMPRESSION_QUALITY,
  ): Promise<Blob[]> {
    const thumbnails: Blob[] = [];

    for (const size of sizes) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d')!;

      const ratio = Math.min(size / img.width, size / img.height);
      canvas.width = img.width * ratio;
      canvas.height = img.height * ratio;

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      const thumbnailBlob = await new Promise<Blob>((resolve) => {
        canvas.toBlob((blob) => resolve(blob!), type, quality);
      });

      thumbnails.push(thumbnailBlob);
    }

    return thumbnails;
  }

  static async canvasToJpgArrayBuffer(
    canvas: HTMLCanvasElement,
  ): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result as ArrayBuffer);
          };
          reader.onerror = () => {
            reject(new Error('Failed to convert blob to ArrayBuffer'));
          };
          reader.readAsArrayBuffer(blob);
        } else {
          reject(new Error('Failed to create blob from canvas'));
        }
      }, 'image/jpeg');
    });
  }
}
