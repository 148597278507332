import { IRenderable, Point, Rect } from '@repo/drawing';
import { ReadonlyAppState } from '../AppState.js';
import { ImageWorkflow, ProcessStage } from '../ImageWorkflowManager.js';

// Extension to track workflow state in Renderer
interface WorkflowRenderState {
  activeWorkflow?: ImageWorkflow;
  workflowByIndex: Map<number, ImageWorkflow>;
}

const fontSize = 80;

export class ImageWorkflowOverlay implements IRenderable {
  #index: number;
  #tileSize: number;
  #progressBarHeight: number = 6;
  #cropStrokeWidth: number = 4;

  // Add workflow state
  #workflowState: WorkflowRenderState = {
    workflowByIndex: new Map(),
  };

  constructor(params: { index: number; tileSize: number }) {
    this.#index = params.index;
    this.#tileSize = params.tileSize;
  }

  get index(): number {
    return this.#index;
  }

  applyState(appState: ReadonlyAppState): void {
    // Map workflows to indexes
    this.#workflowState.workflowByIndex.clear();

    // Get workflow ID for this index from selected workflows array
    const workflowId = appState.selectedWorkflows[this.#index];
    if (workflowId) {
      // Look up the actual workflow from active workflows map
      const workflow = appState.activeWorkflows.get(workflowId);
      if (workflow) {
        this.#workflowState.workflowByIndex.set(this.#index, workflow);
      }
    }
  }

  renderTo(ctx: CanvasRenderingContext2D): void {
    const workflow = this.#workflowState.workflowByIndex.get(this.#index);
    if (!workflow) return;

    // Calculate tile position
    const tileTop = this.#index * this.#tileSize;

    // Save context state
    ctx.save();

    try {
      // Only show processing overlay if not complete
      if (workflow.stage !== 'complete') {
        this.renderProcessingOverlay(ctx, tileTop, workflow);
      }

      // Show crop region if defined
      if (workflow.cropRegion) {
        // this.renderCropOverlay(ctx, tileTop, workflow);
      }
    } finally {
      // Restore context state
      ctx.restore();
    }
  }

  private renderProcessingOverlay(
    ctx: CanvasRenderingContext2D,
    tileTop: number,
    workflow: ImageWorkflow,
  ): void {
    // Draw semi-transparent overlay
    ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
    ctx.fillRect(0, tileTop, this.#tileSize, this.#tileSize);

    // Draw progress bar background
    const barY = tileTop + this.#tileSize / 2 - this.#progressBarHeight / 2;
    const padding = 40;
    const barWidth = this.#tileSize - padding * 2;

    ctx.fillStyle = 'rgba(255, 255, 255, 0.3)';
    ctx.fillRect(padding, barY, barWidth, this.#progressBarHeight);

    // Draw progress bar fill
    const progress = Math.max(0, Math.min(100, workflow.progress)) / 100;
    ctx.fillStyle = this.getStageColor(workflow.stage);
    ctx.fillRect(padding, barY, barWidth * progress, this.#progressBarHeight);

    // Draw stage text
    ctx.fillStyle = 'white';
    ctx.font = `${fontSize}px Arial`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';
    const stageText = this.getStageText(workflow.stage);
    ctx.fillText(stageText, this.#tileSize / 2, barY - 10);

    // Draw percentage text
    ctx.textBaseline = 'top';
    ctx.fillText(
      `${Math.round(workflow.progress)}%`,
      this.#tileSize / 2,
      barY + this.#progressBarHeight + 10,
    );

    // Draw error message if applicable
    if (workflow.stage === 'error' && workflow.error) {
      // ctx.save();
      // ctx.translate(this.#tileSize / 2, barY + this.#progressBarHeight + 40);
      ctx.translate(0, 60);
      ctx.fillStyle = 'red';
      ctx.font = `${fontSize}px Arial`;
      ctx.fillText(
        workflow.error.message,
        this.#tileSize / 2,
        barY + this.#progressBarHeight + 40,
      );
      // ctx.reset();
    }
  }

  private renderCropOverlay(
    ctx: CanvasRenderingContext2D,
    tileTop: number,
    workflow: ImageWorkflow,
  ): void {
    if (!workflow.cropRegion || !workflow.original) return;

    const image = workflow.original;
    const crop = workflow.cropRegion;

    // Calculate scale to fit image in tile
    const scale = this.#tileSize / Math.max(image.width, image.height);

    // Calculate image position to center it
    const scaledWidth = image.width * scale;
    const scaledHeight = image.height * scale;
    const imageX = (this.#tileSize - scaledWidth) / 2;
    const imageY = tileTop + (this.#tileSize - scaledHeight) / 2;

    // Draw crop region outline
    ctx.strokeStyle =
      workflow.stage === 'complete'
        ? 'rgba(0, 255, 0, 0.8)'
        : 'rgba(255, 255, 255, 0.8)';
    ctx.lineWidth = this.#cropStrokeWidth;

    const cropX = imageX + crop.x * scale;
    const cropY = imageY + crop.y * scale;
    const cropSize = crop.size * scale;

    ctx.strokeRect(cropX, cropY, cropSize, cropSize);

    // Draw corner markers
    const markerSize = 20;
    const corners = [
      [cropX, cropY], // Top-left
      [cropX + cropSize, cropY], // Top-right
      [cropX, cropY + cropSize], // Bottom-left
      [cropX + cropSize, cropY + cropSize], // Bottom-right
    ];

    ctx.beginPath();
    for (const [x, y] of corners) {
      if (x === undefined || y === undefined) continue;
      // Draw L-shaped corner markers
      ctx.moveTo(x - markerSize, y);
      ctx.lineTo(x, y);
      ctx.lineTo(x, y - markerSize);
    }
    ctx.stroke();
  }

  private getStageColor(stage: ProcessStage): string {
    switch (stage) {
      case 'initial':
        return 'rgba(150, 150, 150, 0.8)';
      case 'preprocessing':
      case 'caching':
      case 'generating-thumbnails':
      case 'generating-variants':
        return 'rgba(0, 120, 255, 0.8)';
      case 'uploading':
        return 'rgba(255, 165, 0, 0.8)';
      case 'complete':
        return 'rgba(0, 255, 0, 0.8)';
      case 'error':
        return 'rgba(255, 0, 0, 0.8)';
      default:
        return 'rgba(150, 150, 150, 0.8)';
    }
  }

  private getStageText(stage: ProcessStage): string {
    switch (stage) {
      case 'initial':
        return 'Starting...';
      case 'preprocessing':
        return 'Preprocessing';
      case 'caching':
        return 'Caching';
      case 'generating-thumbnails':
        return 'Generating Thumbnails';
      case 'generating-variants':
        return 'Creating Variants';
      case 'uploading':
        return 'Uploading';
      case 'complete':
        return 'Complete';
      case 'error':
        return 'Error';
      default:
        return 'Processing';
    }
  }
}
