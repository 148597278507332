export interface ExifTagDefinition {
  name: string;
  type: number; // 1=BYTE, 2=ASCII, 3=SHORT, 4=LONG, 5=RATIONAL, 6=SBYTE, 7=UNDEFINED, 8=SSHORT, 9=SLONG, 10=SRATIONAL, 11=FLOAT, 12=DOUBLE
  description: string;
}

export const EXIF_TAGS: {
  [key: string]: { [key: number]: ExifTagDefinition };
} = {
  // IFD0 (Main Image) Tags
  IFD0: {
    0x010e: {
      name: 'ImageDescription',
      type: 2,
      description: 'Image description',
    },
    0x010f: { name: 'Make', type: 2, description: 'Camera manufacturer' },
    0x0110: { name: 'Model', type: 2, description: 'Camera model' },
    0x0112: { name: 'Orientation', type: 3, description: 'Image orientation' },
    0x011a: {
      name: 'XResolution',
      type: 5,
      description: 'X resolution in pixels per unit',
    },
    0x011b: {
      name: 'YResolution',
      type: 5,
      description: 'Y resolution in pixels per unit',
    },
    0x0128: {
      name: 'ResolutionUnit',
      type: 3,
      description: 'Unit of X and Y resolution',
    },
    0x0131: { name: 'Software', type: 2, description: 'Software used' },
    0x0132: {
      name: 'DateTime',
      type: 2,
      description: 'File change date and time',
    },
    0x013b: { name: 'Artist', type: 2, description: 'Artist name' },
    0x013e: {
      name: 'WhitePoint',
      type: 5,
      description: 'White point chromaticity',
    },
    0x013f: {
      name: 'PrimaryChromaticities',
      type: 5,
      description: 'Chromaticities of primary colors',
    },
    0x0211: {
      name: 'YCbCrCoefficients',
      type: 5,
      description: 'Color space transformation coefficients',
    },
    0x0213: {
      name: 'YCbCrPositioning',
      type: 3,
      description: 'Y and C positioning',
    },
    0x0214: {
      name: 'ReferenceBlackWhite',
      type: 5,
      description: 'Reference black and white point values',
    },
    0x8298: { name: 'Copyright', type: 2, description: 'Copyright holder' },
    0x8769: {
      name: 'ExifOffset',
      type: 4,
      description: 'Pointer to EXIF sub-IFD',
    },
    0x8825: {
      name: 'GPSInfo',
      type: 4,
      description: 'Pointer to GPS Info IFD',
    },
  },

  // EXIF Sub-IFD Tags
  EXIF: {
    0x829a: {
      name: 'ExposureTime',
      type: 5,
      description: 'Exposure time in seconds',
    },
    0x829d: { name: 'FNumber', type: 5, description: 'F-number' },
    0x8822: {
      name: 'ExposureProgram',
      type: 3,
      description: 'Exposure program',
    },
    0x8824: {
      name: 'SpectralSensitivity',
      type: 2,
      description: 'Spectral sensitivity',
    },
    0x8827: {
      name: 'ISOSpeedRatings',
      type: 3,
      description: 'ISO speed rating',
    },
    0x8828: {
      name: 'OECF',
      type: 7,
      description: 'Optoelectric conversion factor',
    },
    0x8830: {
      name: 'SensitivityType',
      type: 3,
      description: 'Sensitivity type',
    },
    0x8831: {
      name: 'StandardOutputSensitivity',
      type: 4,
      description: 'Standard output sensitivity',
    },
    0x8832: {
      name: 'RecommendedExposureIndex',
      type: 4,
      description: 'Recommended exposure index',
    },
    0x8833: { name: 'ISOSpeed', type: 4, description: 'ISO speed' },
    0x8834: {
      name: 'ISOSpeedLatitudeyyy',
      type: 4,
      description: 'ISO speed latitude yyy',
    },
    0x8835: {
      name: 'ISOSpeedLatitudezzz',
      type: 4,
      description: 'ISO speed latitude zzz',
    },
    0x9000: { name: 'ExifVersion', type: 7, description: 'EXIF version' },
    0x9003: {
      name: 'DateTimeOriginal',
      type: 2,
      description: 'Original date and time',
    },
    0x9004: {
      name: 'DateTimeDigitized',
      type: 2,
      description: 'Digitized date and time',
    },
    0x9101: {
      name: 'ComponentsConfiguration',
      type: 7,
      description: 'Meaning of each component',
    },
    0x9102: {
      name: 'CompressedBitsPerPixel',
      type: 5,
      description: 'Image compression mode',
    },
    0x9201: {
      name: 'ShutterSpeedValue',
      type: 5,
      description: 'Shutter speed',
    },
    0x9202: { name: 'ApertureValue', type: 5, description: 'Aperture' },
    0x9203: { name: 'BrightnessValue', type: 5, description: 'Brightness' },
    0x9204: {
      name: 'ExposureBiasValue',
      type: 5,
      description: 'Exposure bias',
    },
    0x9205: {
      name: 'MaxApertureValue',
      type: 5,
      description: 'Maximum lens aperture',
    },
    0x9206: {
      name: 'SubjectDistance',
      type: 5,
      description: 'Subject distance',
    },
    0x9207: { name: 'MeteringMode', type: 3, description: 'Metering mode' },
    0x9208: { name: 'LightSource', type: 3, description: 'Light source' },
    0x9209: { name: 'Flash', type: 3, description: 'Flash status' },
    0x920a: { name: 'FocalLength', type: 5, description: 'Focal length' },
    0x9214: { name: 'SubjectArea', type: 3, description: 'Subject area' },
    0x927c: { name: 'MakerNote', type: 7, description: 'Manufacturer notes' },
    0x9286: { name: 'UserComment', type: 7, description: 'User comments' },
    0x9290: { name: 'SubSecTime', type: 2, description: 'DateTime subseconds' },
    0x9291: {
      name: 'SubSecTimeOriginal',
      type: 2,
      description: 'DateTimeOriginal subseconds',
    },
    0x9292: {
      name: 'SubSecTimeDigitized',
      type: 2,
      description: 'DateTimeDigitized subseconds',
    },
    0xa000: {
      name: 'FlashpixVersion',
      type: 7,
      description: 'Flashpix format version',
    },
    0xa001: {
      name: 'ColorSpace',
      type: 3,
      description: 'Color space information',
    },
    0xa002: {
      name: 'PixelXDimension',
      type: 4,
      description: 'Valid image width',
    },
    0xa003: {
      name: 'PixelYDimension',
      type: 4,
      description: 'Valid image height',
    },
    0xa004: {
      name: 'RelatedSoundFile',
      type: 2,
      description: 'Related audio file',
    },
    0xa005: {
      name: 'InteroperabilityOffset',
      type: 4,
      description: 'Pointer to interoperability IFD',
    },
    0xa20b: { name: 'FlashEnergy', type: 5, description: 'Flash energy' },
    0xa20c: {
      name: 'SpatialFrequencyResponse',
      type: 7,
      description: 'Spatial frequency response',
    },
    0xa20e: {
      name: 'FocalPlaneXResolution',
      type: 5,
      description: 'Focal plane X resolution',
    },
    0xa20f: {
      name: 'FocalPlaneYResolution',
      type: 5,
      description: 'Focal plane Y resolution',
    },
    0xa210: {
      name: 'FocalPlaneResolutionUnit',
      type: 3,
      description: 'Focal plane resolution unit',
    },
    0xa214: {
      name: 'SubjectLocation',
      type: 3,
      description: 'Subject location',
    },
    0xa215: { name: 'ExposureIndex', type: 5, description: 'Exposure index' },
    0xa217: { name: 'SensingMethod', type: 3, description: 'Sensing method' },
    0xa300: { name: 'FileSource', type: 7, description: 'File source' },
    0xa301: { name: 'SceneType', type: 7, description: 'Scene type' },
    0xa302: { name: 'CFAPattern', type: 7, description: 'CFA pattern' },
    0xa401: {
      name: 'CustomRendered',
      type: 3,
      description: 'Custom image processing',
    },
    0xa402: { name: 'ExposureMode', type: 3, description: 'Exposure mode' },
    0xa403: { name: 'WhiteBalance', type: 3, description: 'White balance' },
    0xa404: {
      name: 'DigitalZoomRatio',
      type: 5,
      description: 'Digital zoom ratio',
    },
    0xa405: {
      name: 'FocalLengthIn35mmFilm',
      type: 3,
      description: '35mm focal length equivalent',
    },
    0xa406: {
      name: 'SceneCaptureType',
      type: 3,
      description: 'Scene capture type',
    },
    0xa407: { name: 'GainControl', type: 3, description: 'Gain control' },
    0xa408: { name: 'Contrast', type: 3, description: 'Contrast' },
    0xa409: { name: 'Saturation', type: 3, description: 'Saturation' },
    0xa40a: { name: 'Sharpness', type: 3, description: 'Sharpness' },
    0xa40b: {
      name: 'DeviceSettingDescription',
      type: 7,
      description: 'Device settings',
    },
    0xa40c: {
      name: 'SubjectDistanceRange',
      type: 3,
      description: 'Subject distance range',
    },
    0xa420: { name: 'ImageUniqueID', type: 2, description: 'Unique image ID' },
    0xa430: {
      name: 'CameraOwnerName',
      type: 2,
      description: 'Camera owner name',
    },
    0xa431: {
      name: 'BodySerialNumber',
      type: 2,
      description: 'Camera body serial number',
    },
    0xa432: {
      name: 'LensSpecification',
      type: 5,
      description: 'Lens specifications',
    },
    0xa433: { name: 'LensMake', type: 2, description: 'Lens manufacturer' },
    0xa434: { name: 'LensModel', type: 2, description: 'Lens model' },
    0xa435: {
      name: 'LensSerialNumber',
      type: 2,
      description: 'Lens serial number',
    },
  },

  // GPS IFD Tags
  GPS: {
    0x0000: { name: 'GPSVersionID', type: 1, description: 'GPS tag version' },
    0x0001: {
      name: 'GPSLatitudeRef',
      type: 2,
      description: 'North or South Latitude',
    },
    0x0002: { name: 'GPSLatitude', type: 5, description: 'Latitude' },
    0x0003: {
      name: 'GPSLongitudeRef',
      type: 2,
      description: 'East or West Longitude',
    },
    0x0004: { name: 'GPSLongitude', type: 5, description: 'Longitude' },
    0x0005: {
      name: 'GPSAltitudeRef',
      type: 1,
      description: 'Altitude reference',
    },
    0x0006: { name: 'GPSAltitude', type: 5, description: 'Altitude' },
    0x0007: {
      name: 'GPSTimeStamp',
      type: 5,
      description: 'GPS time (atomic clock)',
    },
    0x0008: {
      name: 'GPSSatellites',
      type: 2,
      description: 'GPS satellites used',
    },
    0x0009: { name: 'GPSStatus', type: 2, description: 'GPS receiver status' },
    0x000a: {
      name: 'GPSMeasureMode',
      type: 2,
      description: 'GPS measurement mode',
    },
    0x000b: { name: 'GPSDOP', type: 5, description: 'Measurement precision' },
    0x000c: { name: 'GPSSpeedRef', type: 2, description: 'Speed unit' },
    0x000d: { name: 'GPSSpeed', type: 5, description: 'Speed of GPS receiver' },
    0x000e: {
      name: 'GPSTrackRef',
      type: 2,
      description: 'Reference for direction of movement',
    },
    0x000f: { name: 'GPSTrack', type: 5, description: 'Direction of movement' },
    0x0010: {
      name: 'GPSImgDirectionRef',
      type: 2,
      description: 'Reference for direction of image',
    },
    0x0011: {
      name: 'GPSImgDirection',
      type: 5,
      description: 'Direction of image',
    },
    0x0012: {
      name: 'GPSMapDatum',
      type: 2,
      description: 'Geodetic survey data',
    },
    0x0013: {
      name: 'GPSDestLatitudeRef',
      type: 2,
      description: 'Reference for latitude of destination',
    },
    0x0014: {
      name: 'GPSDestLatitude',
      type: 5,
      description: 'Latitude of destination',
    },
    0x0015: {
      name: 'GPSDestLongitudeRef',
      type: 2,
      description: 'Reference for longitude of destination',
    },
    0x0016: {
      name: 'GPSDestLongitude',
      type: 5,
      description: 'Longitude of destination',
    },
    0x0017: {
      name: 'GPSDestBearingRef',
      type: 2,
      description: 'Reference for bearing of destination',
    },
    0x0018: {
      name: 'GPSDestBearing',
      type: 5,
      description: 'Bearing of destination',
    },
    0x0019: {
      name: 'GPSDestDistanceRef',
      type: 2,
      description: 'Reference for distance to destination',
    },
    0x001a: {
      name: 'GPSDestDistance',
      type: 5,
      description: 'Distance to destination',
    },
    0x001b: {
      name: 'GPSProcessingMethod',
      type: 7,
      description: 'Name of GPS processing method',
    },
    0x001c: {
      name: 'GPSAreaInformation',
      type: 7,
      description: 'Name of GPS area',
    },
    0x001d: { name: 'GPSDateStamp', type: 2, description: 'GPS date' },
    0x001e: {
      name: 'GPSDifferential',
      type: 3,
      description: 'GPS differential correction',
    },
    0x001f: {
      name: 'GPSHPositioningError',
      type: 5,
      description: 'Horizontal positioning error',
    },
  },

  // Interoperability IFD Tags
  Interop: {
    0x0001: {
      name: 'InteroperabilityIndex',
      type: 2,
      description: 'Interoperability indicator',
    },
    0x0002: {
      name: 'InteroperabilityVersion',
      type: 7,
      description: 'Interoperability version',
    },
    0x1000: {
      name: 'RelatedImageFileFormat',
      type: 2,
      description: 'File format of image file',
    },
    0x1001: { name: 'RelatedImageWidth', type: 4, description: 'Image width' },
    0x1002: {
      name: 'RelatedImageLength',
      type: 4,
      description: 'Image height',
    },
  },

  // Thumbnail IFD Tags
  Thumbnail: {
    0x0100: {
      name: 'ImageWidth',
      type: 4,
      description: 'Thumbnail image width',
    },
    0x0101: {
      name: 'ImageLength',
      type: 4,
      description: 'Thumbnail image height',
    },
    0x0102: {
      name: 'BitsPerSample',
      type: 3,
      description: 'Number of bits per component',
    },
    0x0103: { name: 'Compression', type: 3, description: 'Compression scheme' },
    0x0106: {
      name: 'PhotometricInterpretation',
      type: 3,
      description: 'Pixel composition',
    },
    0x0111: {
      name: 'StripOffsets',
      type: 4,
      description: 'Image data location',
    },
    0x0115: {
      name: 'SamplesPerPixel',
      type: 3,
      description: 'Number of components',
    },
    0x0116: {
      name: 'RowsPerStrip',
      type: 4,
      description: 'Number of rows per strip',
    },
    0x0117: {
      name: 'StripByteCounts',
      type: 4,
      description: 'Bytes per compressed strip',
    },
    0x011a: {
      name: 'XResolution',
      type: 5,
      description: 'Horizontal resolution',
    },
    0x011b: {
      name: 'YResolution',
      type: 5,
      description: 'Vertical resolution',
    },
    0x011c: {
      name: 'PlanarConfiguration',
      type: 3,
      description: 'Image data arrangement',
    },
  },

  // Metadata Working Group Tags
  MWG: {
    0xc4a5: {
      name: 'PrintImageMatching',
      type: 7,
      description: 'Print Image Matching data',
    },
    0xc612: { name: 'DNGVersion', type: 1, description: 'DNG version number' },
    0xc613: {
      name: 'DNGBackwardVersion',
      type: 1,
      description: 'DNG compatibility version',
    },
    0xc614: {
      name: 'UniqueCameraModel',
      type: 2,
      description: 'Unique camera model name',
    },
    0xc615: {
      name: 'LocalizedCameraModel',
      type: 2,
      description: 'Localized camera model name',
    },
    0xc618: {
      name: 'LinearizationTable',
      type: 3,
      description: 'Linearization table',
    },
    0xc619: {
      name: 'BlackLevelRepeatDim',
      type: 3,
      description: 'Black level repeat dimensions',
    },
    0xc61a: { name: 'BlackLevel', type: 5, description: 'Black level value' },
    0xc61b: {
      name: 'BlackLevelDeltaH',
      type: 10,
      description: 'Black level horizontal differences',
    },
    0xc61c: {
      name: 'BlackLevelDeltaV',
      type: 10,
      description: 'Black level vertical differences',
    },
    0xc61d: { name: 'WhiteLevel', type: 4, description: 'White level value' },
  },

  // Canon Maker Note Tags
  Canon: {
    0x0001: {
      name: 'CanonCameraSettings',
      type: 3,
      description: 'Camera settings',
    },
    0x0002: {
      name: 'CanonFocalLength',
      type: 3,
      description: 'Focal length settings',
    },
    0x0003: { name: 'CanonFlashInfo', type: 3, description: 'Flash settings' },
    0x0004: { name: 'CanonShotInfo', type: 3, description: 'Shot information' },
    0x0005: {
      name: 'CanonPanorama',
      type: 3,
      description: 'Panorama settings',
    },
    0x0006: { name: 'CanonImageType', type: 2, description: 'Image type' },
    0x0007: {
      name: 'CanonFirmwareVersion',
      type: 2,
      description: 'Firmware version',
    },
    0x0008: { name: 'FileNumber', type: 4, description: 'File number' },
    0x0009: { name: 'OwnerName', type: 2, description: 'Owner name' },
    0x000c: {
      name: 'SerialNumber',
      type: 4,
      description: 'Camera serial number',
    },
    0x000d: { name: 'CanonCameraInfo', type: 3, description: 'Camera info' },
    0x000e: { name: 'CanonFileLength', type: 4, description: 'File length' },
    0x000f: {
      name: 'CustomFunctions',
      type: 3,
      description: 'Custom functions',
    },
    0x0010: { name: 'CanonModelID', type: 4, description: 'Model ID' },
    0x0012: { name: 'CanonAFInfo', type: 3, description: 'AF information' },
    0x0013: {
      name: 'ThumbnailImageValidArea',
      type: 3,
      description: 'Thumbnail valid area',
    },
    0x0015: {
      name: 'SerialNumberFormat',
      type: 4,
      description: 'Serial number format',
    },
    0x001c: { name: 'DateStampMode', type: 3, description: 'Date stamp mode' },
    0x001d: { name: 'MyColors', type: 3, description: 'My Colors settings' },
    0x001e: {
      name: 'FirmwareRevision',
      type: 4,
      description: 'Firmware revision',
    },
  },

  // Nikon Maker Note Tags
  Nikon: {
    0x0001: {
      name: 'NikonVersion',
      type: 7,
      description: 'Maker note version',
    },
    0x0002: { name: 'ISOSpeed', type: 3, description: 'ISO speed setting' },
    0x0003: { name: 'ColorMode', type: 2, description: 'Color mode' },
    0x0004: { name: 'Quality', type: 2, description: 'Image quality setting' },
    0x0005: { name: 'WhiteBalance', type: 2, description: 'White balance' },
    0x0006: { name: 'Sharpening', type: 2, description: 'Image sharpening' },
    0x0007: { name: 'FocusMode', type: 2, description: 'Focus mode' },
    0x0008: { name: 'FlashSetting', type: 2, description: 'Flash setting' },
    0x0009: { name: 'FlashMode', type: 2, description: 'Flash mode' },
    0x000b: {
      name: 'WhiteBalanceBias',
      type: 3,
      description: 'White balance bias',
    },
    0x000c: {
      name: 'WB_RBLevels',
      type: 5,
      description: 'White balance RB levels',
    },
    0x000d: { name: 'ProgramShift', type: 7, description: 'Program shift' },
    0x000e: {
      name: 'ExposureDiff',
      type: 7,
      description: 'Exposure difference',
    },
    0x000f: { name: 'ISOSelection', type: 2, description: 'ISO selection' },
    0x0010: { name: 'DataDump', type: 7, description: 'Data dump' },
    0x0011: { name: 'Preview', type: 7, description: 'Preview IFD' },
    0x0012: { name: 'FlashComp', type: 7, description: 'Flash compensation' },
    0x0013: { name: 'ISOSettings', type: 3, description: 'ISO settings' },
    0x0016: { name: 'ImageBoundary', type: 3, description: 'Image boundary' },
    0x0017: {
      name: 'ExternalFlashExposureComp',
      type: 7,
      description: 'External flash exposure comp',
    },
    0x0018: {
      name: 'FlashExposureBracketValue',
      type: 7,
      description: 'Flash exposure bracket value',
    },
    0x0019: {
      name: 'ExposureBracketValue',
      type: 7,
      description: 'Exposure bracket value',
    },
    0x001a: {
      name: 'ImageProcessing',
      type: 7,
      description: 'Image processing',
    },
    0x001b: { name: 'CropHiSpeed', type: 7, description: 'Crop high speed' },
    0x001c: { name: 'ExposureTuning', type: 7, description: 'Exposure tuning' },
    0x001d: { name: 'SerialNumber', type: 2, description: 'Serial number' },
    0x001e: { name: 'ColorSpace', type: 3, description: 'Color space' },
    0x001f: { name: 'VRInfo', type: 7, description: 'VR info' },
    0x0020: {
      name: 'ImageAuthentication',
      type: 3,
      description: 'Image authentication',
    },
    0x0022: {
      name: 'ActiveDLighting',
      type: 3,
      description: 'Active D-Lighting',
    },
    0x0023: { name: 'PictureControl', type: 7, description: 'Picture control' },
    0x0024: { name: 'WorldTime', type: 7, description: 'World time' },
    0x0025: { name: 'ISOInfo', type: 7, description: 'ISO info' },
  },

  // Sony Maker Note Tags
  Sony: {
    0xb000: { name: 'FileFormat', type: 3, description: 'File format' },
    0xb001: { name: 'SonyModelID', type: 3, description: 'Model ID' },
    0xb020: {
      name: 'ColorReproduction',
      type: 2,
      description: 'Color reproduction',
    },
    0xb021: {
      name: 'ColorTemperature',
      type: 3,
      description: 'Color temperature',
    },
    0xb022: {
      name: 'ColorCompensationFilter',
      type: 3,
      description: 'Color compensation filter',
    },
    0xb023: { name: 'SceneMode', type: 3, description: 'Scene mode' },
    0xb024: { name: 'ZoneMatching', type: 3, description: 'Zone matching' },
    0xb025: {
      name: 'DynamicRangeOptimizer',
      type: 3,
      description: 'Dynamic range optimizer',
    },
    0xb026: {
      name: 'ImageStabilization',
      type: 3,
      description: 'Image stabilization',
    },
    0xb027: { name: 'LensID', type: 3, description: 'Lens ID' },
    0xb028: {
      name: 'MinoltaMakerNote',
      type: 7,
      description: 'Minolta maker note',
    },
    0xb029: { name: 'ColorMode', type: 3, description: 'Color mode' },
    0xb02b: { name: 'FullImageSize', type: 4, description: 'Full image size' },
    0xb02c: {
      name: 'PreviewImageSize',
      type: 4,
      description: 'Preview image size',
    },
    0xb040: { name: 'Macro', type: 3, description: 'Macro' },
    0xb041: { name: 'ExposureMode', type: 3, description: 'Exposure mode' },
    0xb042: { name: 'FocusMode', type: 3, description: 'Focus mode' },
    0xb043: { name: 'AFMode', type: 3, description: 'AF mode' },
    0xb044: { name: 'AFIlluminator', type: 3, description: 'AF illuminator' },
    0xb047: { name: 'Quality2', type: 3, description: 'Quality 2' },
    0xb048: { name: 'FlashLevel', type: 3, description: 'Flash level' },
    0xb049: { name: 'ReleaseMode', type: 3, description: 'Release mode' },
    0xb04a: { name: 'SequenceNumber', type: 3, description: 'Sequence number' },
    0xb04b: { name: 'AntiBlur', type: 3, description: 'Anti-blur' },
  },

  // Fujifilm Maker Note Tags
  Fujifilm: {
    0x0000: { name: 'Version', type: 7, description: 'Maker note version' },
    0x1000: { name: 'Quality', type: 2, description: 'Image quality' },
    0x1001: { name: 'Sharpness', type: 3, description: 'Sharpness' },
    0x1002: { name: 'WhiteBalance', type: 3, description: 'White balance' },
    0x1003: { name: 'Color', type: 3, description: 'Color' },
    0x1004: { name: 'Tone', type: 3, description: 'Tone' },
    0x1010: { name: 'FlashMode', type: 3, description: 'Flash mode' },
    0x1011: { name: 'FlashStrength', type: 3, description: 'Flash strength' },
    0x1020: { name: 'Macro', type: 3, description: 'Macro' },
    0x1021: { name: 'FocusMode', type: 3, description: 'Focus mode' },
    0x1030: { name: 'SlowSync', type: 3, description: 'Slow sync' },
    0x1031: { name: 'PictureMode', type: 3, description: 'Picture mode' },
    0x1100: { name: 'ContTake', type: 3, description: 'Continuous take' },
    0x1300: { name: 'BlurWarning', type: 3, description: 'Blur warning' },
    0x1301: { name: 'FocusWarning', type: 3, description: 'Focus warning' },
    0x1302: { name: 'AEWarning', type: 3, description: 'AE warning' },
  },

  // Olympus Maker Note Tags
  Olympus: {
    0x0200: { name: 'SpecialMode', type: 3, description: 'Special mode' },
    0x0201: { name: 'JpegQuality', type: 3, description: 'JPEG quality' },
    0x0202: { name: 'Macro', type: 3, description: 'Macro' },
    0x0203: { name: 'BWMode', type: 3, description: 'BW mode' },
    0x0204: { name: 'DigitalZoom', type: 5, description: 'Digital zoom' },
    0x0205: {
      name: 'FocalPlaneDiagonal',
      type: 5,
      description: 'Focal plane diagonal',
    },
    0x0206: {
      name: 'LensFocalRange',
      type: 5,
      description: 'Lens focal range',
    },
    0x0207: {
      name: 'LensMaxAperture',
      type: 5,
      description: 'Lens maximum aperture',
    },
    0x0208: {
      name: 'LensMinAperture',
      type: 5,
      description: 'Lens minimum aperture',
    },
    0x0209: {
      name: 'ManualFocusDistance',
      type: 5,
      description: 'Manual focus distance',
    },
    0x020a: { name: 'ZoomStepCount', type: 3, description: 'Zoom step count' },
  },
};
