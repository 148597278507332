export function isBrowser(): boolean {
  return typeof window !== 'undefined' && typeof document !== 'undefined';
}

export function toBlobPolyfill() {
  // Only run polyfill in browser environment
  if (!isBrowser()) {
    return;
  }

  // Check if HTMLCanvasElement exists and if toBlob needs to be polyfilled
  if (
    typeof HTMLCanvasElement !== 'undefined' &&
    !HTMLCanvasElement.prototype.toBlob
  ) {
    (HTMLCanvasElement.prototype as any).toBlobPolyfill = true;
    Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
      value: function (
        callback: (blob: Blob) => void,
        type: string,
        quality: number,
      ) {
        var binStr = atob(this.toDataURL(type, quality).split(',')[1]),
          len = binStr.length,
          arr = new Uint8Array(len);

        for (var i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }

        callback(new Blob([arr], { type: type || 'image/png' }));
      },
    });
  }
}

// Additional browser-safe initialization helper
export function initializeBrowserFeatures() {
  if (isBrowser()) {
    toBlobPolyfill();
  }
}

// export function toBlobPolyfill() {
//     if (!HTMLCanvasElement.prototype.toBlob) {
//         (HTMLCanvasElement.prototype as any).toBlobPolyfill = true;
//         Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
//             value: function (callback: (blob: Blob) => void, type: string, quality: number) {
//                 var binStr = atob( this.toDataURL(type, quality).split(',')[1] ),
//                     len = binStr.length,
//                     arr = new Uint8Array(len);

//                 for (var i = 0; i < len; i++ ) {
//                     arr[i] = binStr.charCodeAt(i);
//                 }

//                 callback( new Blob( [arr], {type: type || 'image/png'} ) );
//             }
//         });
//     }
// }
