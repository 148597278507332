export class Vector2d {
  #x: number;
  #y: number;

  constructor(x: number, y: number) {
    this.#x = x;
    this.#y = y;
  }

  get x(): number {
    return this.#x;
  }

  get y(): number {
    return this.#y;
  }

  toString(): string {
    return `Vector2d { x: ${this.#x}, y: ${this.#y} }`;
  }

  deepClone(): Vector2d {
    return new Vector2d(this.x, this.y);
  }
}
