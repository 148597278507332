// import type {
//     DOMRect,
//     HTMLElement,
// } from 'https://deno.land/x/evt@v2.4.15/lib/types/lib.dom.ts';
// import { HTMLElement } from '
import { Edges } from './Edges.js';
import { Rect } from './rect.js';

export class ElementWrapper {
  #target: HTMLElement;
  #boundingClientDOMRect!: DOMRect;
  #boundingClientRect!: Rect;
  #marginRect!: Edges;
  #borderEdges!: Edges;
  #offsetRect!: Rect;
  #clientRect!: Rect;
  #scrollRect!: Rect;
  #internalRect!: Rect;

  constructor(params: { target: HTMLElement }) {
    this.#target = params.target;
    this.updateDimensions();
  }

  public get boundingClientDOMRect(): DOMRect {
    return this.#boundingClientDOMRect;
  }

  public get boundingClientRect(): Rect {
    return this.#boundingClientRect;
  }

  public get margin(): Edges {
    return this.#marginRect;
  }

  public get border(): Edges {
    return this.#borderEdges;
  }

  public get offset(): Rect {
    return this.#offsetRect;
  }

  public get client(): Rect {
    return this.#clientRect;
  }

  public get scroll(): Rect {
    return this.#scrollRect;
  }

  public get internal(): Rect {
    return this.#internalRect;
  }

  private parseCSSSize(value: string): number {
    if (!value) return 0;
    if (value === '') return 0;
    return parseInt(value);
  }

  public updateDimensions() {
    const target = this.#target;
    const bc = target.getBoundingClientRect();
    this.#boundingClientDOMRect = bc;
    this.#boundingClientRect = new Rect(bc.top, bc.left, bc.width, bc.height);
    let style = getComputedStyle(target);
    this.#marginRect = new Edges(
      this.parseCSSSize(style.marginTop),
      this.parseCSSSize(style.marginRight),
      this.parseCSSSize(style.marginBottom),
      this.parseCSSSize(style.marginLeft),
    );
    this.#borderEdges = new Edges(
      this.parseCSSSize(style.borderTopWidth),
      this.parseCSSSize(style.borderRightWidth),
      this.parseCSSSize(style.borderBottomWidth),
      this.parseCSSSize(style.borderLeftWidth),
    );
    this.#offsetRect = new Rect(
      target.offsetTop,
      target.offsetLeft,
      target.offsetWidth,
      target.offsetHeight,
    );
    this.#clientRect = new Rect(
      target.clientTop,
      target.clientLeft,
      target.clientWidth,
      target.clientHeight,
    );
    this.#scrollRect = new Rect(
      target.scrollTop,
      target.scrollLeft,
      target.scrollWidth,
      target.scrollHeight,
    );
    this.#updateInternal();
  }

  #updateInternal() {
    const client = this.client;
    const margin = this.margin;
    // The bounding element's client size can be offset by the declared margin to
    // determine the visible / drawable area inside the container
    const width = client.width - margin.left - margin.right;
    const height = client.height - margin.top - margin.bottom;
    const internalRect = new Rect(0, 0, width, height);
    this.#internalRect = internalRect;
  }

  public logDetails() {
    console.log(
      `[${this.#target.id}] getDimensions[boundingClientDOMRect]:`,
      this.#boundingClientDOMRect.toString(),
    );
    console.log(
      `[${this.#target.id}] getDimensions[boundingClientRect]:`,
      this.#boundingClientRect.toString(),
    );
    console.log(
      `[${this.#target.id}] getDimensions[marginRect]:`,
      this.#marginRect.toString(),
    );
    console.log(
      `[${this.#target.id}] getDimensions[borderEdges]:`,
      this.#borderEdges.toString(),
    );
    console.log(
      `[${this.#target.id}] getDimensions[offsetRect]:`,
      this.#offsetRect.toString(),
    );
    console.log(
      `[${this.#target.id}] getDimensions[clientRect]:`,
      this.#clientRect.toString(),
    );
    console.log(
      `[${this.#target.id}] getDimensions[scrollRect]:`,
      this.#scrollRect.toString(),
    );
  }

  public toString(): string {
    return `Element[${this.#target}] {
            boundingClientDOMRect: ${this.#boundingClientDOMRect},
            boundingClientRect: ${this.#boundingClientRect},
            marginRect: ${this.#marginRect},
            borderEdges: ${this.#borderEdges},
            offsetRect: ${this.#offsetRect},
            clientRect: ${this.#clientRect},
            scrollRect: ${this.#scrollRect},
        }`;
  }
}
