import { IRenderable, Rect } from '@repo/drawing';

export class TileBorder implements IRenderable {
  #bandSize: Rect;
  #position: number;
  #lineWidth: number;
  #visible: boolean;
  #hovered: boolean;
  #selected: boolean;

  constructor(params: {
    bandSize: Rect;
    position: number;
    lineWidth?: number;
    visible?: boolean;
  }) {
    this.#bandSize = params.bandSize;
    this.#position = params.position;
    this.#lineWidth = params.lineWidth ?? 2;
    this.#visible = params.visible ?? false;
    this.#hovered = false;
    this.#selected = false;
  }

  get position(): number {
    return this.#position;
  }

  get lineWidth(): number {
    return this.#lineWidth;
  }

  get visible(): boolean {
    return this.#visible;
  }

  set visible(value: boolean) {
    this.#visible = value;
  }

  get hovered(): boolean {
    return this.#hovered;
  }

  set hovered(value: boolean) {
    this.#hovered = value;
  }

  get selected(): boolean {
    return this.#selected;
  }

  set selected(value: boolean) {
    this.#selected = value;
  }

  renderTo(ctx: CanvasRenderingContext2D): void {
    if (!this.#visible) return;
    const top = this.#position * this.#bandSize.width;
    const s = this.#bandSize.width / 10;
    const lineWidth =
      this.#hovered || this.#selected ? this.#lineWidth + 4 : this.#lineWidth;
    ctx.save();
    ctx.translate(0, top);
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = this.selected ? '#000' : '#000';
    var opacity = 0.2;
    if (this.#selected) opacity += 0.2;
    if (this.#hovered) opacity += 0.6;
    ctx.globalAlpha = opacity;
    // ctx.globalAlpha = this.#hovered || this.#selected ? 0.7 : 0.2;
    this.corners(ctx, s, lineWidth + 10); // / 2);
    ctx.restore();
  }

  corners(ctx: CanvasRenderingContext2D, s: number, w: number): void {
    const bw = this.#bandSize.width;
    ctx.beginPath();
    ctx.moveTo(s + w, 0 + w);
    ctx.lineTo(0 + w, 0 + w);
    ctx.lineTo(0 + w, s + w);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(bw - s - w, 0 + w);
    ctx.lineTo(bw - w, 0 + w);
    ctx.lineTo(bw - w, s + w);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(bw - s - w, bw - w);
    ctx.lineTo(bw - w, bw - w);
    ctx.lineTo(bw - w, bw - s - w);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(s + w, bw - w);
    ctx.lineTo(0 + w, bw - w);
    ctx.lineTo(0 + w, bw - s - w);
    ctx.stroke();
  }
}
