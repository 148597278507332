import { EnhancedImage } from '../../../database/src/imagestore/v1-types.js';
import { BaseBandLayout } from './BaseBandLayout';
import { ReadonlyAppState } from '../AppState';
import { ImagePreview, ImageWorkflowOverlay, TileBorder } from '../shapes';
// import { logger } from '../index.js';
import { Logger } from '@repo/logger';
import { StateManagerImpl } from '@repo/state-manager';

const logger = new Logger('OG7Layout');
export class OG7Layout extends BaseBandLayout {
  private readonly PREVIEW_COUNT = 7;
  private currentIds: string[] = Array.from({ length: this.PREVIEW_COUNT });
  private retries: number[] = Array.from({ length: this.PREVIEW_COUNT });

  getPreviewCount(): number {
    return this.PREVIEW_COUNT;
  }

  async updatePreviews(
    state: ReadonlyAppState,
    previews: ImagePreview[],
  ): Promise<void> {
    if (!state.bandLayout || state.bandLayout.type !== 'og7') {
      previews.forEach((preview) => preview.reset());
      return;
    }

    // const layoutData = state.bandLayout.data as EnhancedImage[];
    const imageIds = state.bandLayout.data;
    if (imageIds.length !== this.PREVIEW_COUNT) {
      logger.error(
        `Expected ${this.PREVIEW_COUNT} imageIds, got ${imageIds.length}`,
      );
      previews.forEach((preview) => preview.reset());
      return;
    }

    for (let i = 0; i < this.PREVIEW_COUNT; i++) {
      const preview = previews[i];
      if (!preview) continue;

      if (this.retries[i] && this.retries[i]! > 3) {
        // logger.error(`Failed to get image for bandId: ${imageIds[i]}`);
        preview.reset();
        continue;
      }

      const workflow = this.getWorkflowForIndex(state, i);
      // if (!workflow?.bandImage?.id) {
      //   continue;
      // }

      if (workflow?.stage !== 'complete' && workflow?.bandImage?.cacheKey) {
        preview.setImage(workflow.bandImage.cacheKey);
      } else {
        // const layoutImage = layoutData[i];
        const imageId = imageIds[i];
        if (!imageId) {
          // || !workflow?.originalKey) {
          // logger.error(
          //   `Invalid bandId or originalKey for imageIds: ${imageId}`,
          // );
          preview.reset();
          continue;
        }
        // Skip rerender if the image is the same
        if (this.currentIds[i] === imageId) continue;
        logger.info(`Getting image for imageId: ${imageId}`);
        const stateManager = StateManagerImpl.getInstance();
        const imageStore = await stateManager.database.imageStore();
        // const image = await imageStore.getImage(workflow.originalKey);
        const image = await imageStore.getImage(imageId);
        if (!image) {
          // logger.error(`Failed to get image for imageId: ${imageId}`);
          this.retries[i] = (this.retries[i] || 0) + 1;
          setTimeout(() => (this.retries[i] = 0), 1000000);
          preview.reset();
          continue;
        }
        this.currentIds[i] = imageId;
        image?.cacheKey ? preview.setImage(image.cacheKey) : preview.reset();
      }
    }

    this.resetElements(previews, this.PREVIEW_COUNT);
  }

  updateOverlays(
    state: ReadonlyAppState,
    overlays: ImageWorkflowOverlay[],
  ): void {
    for (let i = 0; i < this.PREVIEW_COUNT; i++) {
      const overlay = overlays[i];
      if (!overlay) continue;

      const workflow = this.getWorkflowForIndex(state, i);
      if (!workflow) {
        // Set to inactive state instead of reset
        overlay.applyState({
          ...state,
          selectedIndex: undefined,
        });
        continue;
      }

      // Update with workflow state
      overlay.applyState({
        ...state,
        selectedIndex: i,
      });
    }

    // Set remaining overlays to inactive state
    overlays.slice(this.PREVIEW_COUNT).forEach((overlay) => {
      overlay.applyState({
        ...state,
        selectedIndex: undefined,
      });
    });
  }

  updateBorders(state: ReadonlyAppState, borders: TileBorder[]): void {
    for (let i = 0; i < this.PREVIEW_COUNT; i++) {
      const border = borders[i];
      if (!border) continue;

      border.selected = i === state.selectedIndex;
      border.hovered = i === state.hoveredIndex;
      border.visible = !state.previewMode;
    }

    // Hide remaining borders
    borders.slice(this.PREVIEW_COUNT).forEach((border) => {
      border.visible = false;
      border.selected = false;
      border.hovered = false;
    });
  }

  private getWorkflowState(
    baseState: ReadonlyAppState,
    index: number,
  ): ReadonlyAppState {
    return {
      ...baseState,
      selectedIndex: index,
    };
  }
}
