export * from './image-utils/image-utils.js';
export * from './image-utils/exif/exif-utils.js';
export * from './storage-utils/index.js';

export interface ApiResponse<T> {
  data: T;
  status: number;
  message: string;
}

export function createResponse<T>(
  data: T,
  status = 200,
  message = 'Success',
): ApiResponse<T> {
  return {
    data,
    status,
    message,
  };
}

// Format date helper function
export const formatDate = (timestamp: number): string => {
  return new Date(timestamp).toLocaleString();
};

export async function sleep(ms: number) {
  await new Promise((resolve) => setTimeout(resolve, ms));
}

export function getDateTimeShortString(): string {
  const now = new Date();

  const year = now.getFullYear().toString();
  const month = (now.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are 0-indexed
  const day = now.getDate().toString().padStart(2, '0');
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const seconds = now.getSeconds().toString().padStart(2, '0');

  return `${year}${month}${day}${hours}${minutes}${seconds}`;
}

export function formatBytes(bytes: number): string {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let size = bytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return `${size.toFixed(2)} ${units[unitIndex]}`;
}

// export function sha256HashFromByteArray(byteArray: Uint8Array): Promise<string> {
//   return crypto.subtle.digest('SHA-256', byteArray).then((buffer) => arrayBufferToString(buffer));
// }

export function arrayBufferToHex(buffer: ArrayBuffer): string {
  const byteArray = new Uint8Array(buffer);
  let hexString = '';
  for (const byte of byteArray) {
    hexString += byte.toString(16).padStart(2, '0');
  }
  return hexString;
}

export async function sha256HashFromByteArray(
  byteArray: Uint8Array,
): Promise<string> {
  const buffer: ArrayBuffer = await crypto.subtle.digest('SHA-256', byteArray);
  return arrayBufferToHex(buffer);
}

export async function fetchImageAsArrayBuffer(
  imageUrl: string,
): Promise<ArrayBuffer> {
  const response = await fetch(imageUrl);
  if (!response.ok) {
    throw new Error(`Failed to fetch image: ${response.status}`);
  }
  return await response.arrayBuffer();
}

export function arrayBufferToString(
  buffer: ArrayBuffer,
  encoding: string = 'utf-8',
): string {
  const decoder = new TextDecoder(encoding);
  return decoder.decode(buffer);
}

export type Identity = string | number | symbol;

export interface IDeepClone<T> {
  deepClone(): T;
}

export interface IDeepEqual<T> {
  deepEqual(other: T): boolean;
}

export function deepCloneMap<K, V extends IDeepClone<V>>(
  t: Map<K, V>,
): Map<K, V> {
  let result = new Map<K, V>();
  t.forEach((v, k) => {
    result = result.set(k, v.deepClone());
  });
  return result;
}

export function deepEqualMaps<K, V extends IDeepEqual<V>>(
  l: Map<K, V>,
  r: Map<K, V>,
): boolean {
  if (l.size != r.size) return false;
  l.forEach((v, k) => {
    const ov = r.get(k);
    if (ov == undefined) return false;
    if (!v.deepEqual(ov)) return false;
  });
  return true;
}

export function deepClone<T>(obj: T): T {
  // Handle primitives and null
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  // Handle special objects
  if (obj instanceof Date) {
    return new Date(obj.getTime()) as T;
  }

  if (obj instanceof RegExp) {
    return new RegExp(obj.source, obj.flags) as T;
  }

  // Handle arrays
  if (Array.isArray(obj)) {
    return obj.map((item) => deepClone(item)) as T;
  }

  // Handle plain objects
  const clonedObj = Object.create(Object.getPrototypeOf(obj));

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const typedObj = obj as Record<string | number | symbol, unknown>;
      clonedObj[key] = deepClone(typedObj[key]);
    }
  }

  return clonedObj as T;
}

// Add utility functions for deep cloning at the top
// TODO(pbirch): Should use another implementation of deepClone or fully test this one
export function deepClone2<T>(obj: T): T {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  // Handle Date
  if (obj instanceof Date) {
    return new Date(obj.getTime()) as any;
  }

  // Handle Map
  if (obj instanceof Map) {
    const clone = new Map();
    for (const [key, value] of (obj as Map<any, any>).entries()) {
      clone.set(deepClone(key), deepClone(value));
    }
    return clone as any;
  }

  // Handle Set
  if (obj instanceof Set) {
    const clone = new Set();
    for (const value of (obj as Set<any>).values()) {
      clone.add(deepClone(value));
    }
    return clone as any;
  }

  // Handle Arrays
  if (Array.isArray(obj)) {
    return obj.map((item) => deepClone(item)) as any;
  }

  // Handle class instances
  if (obj.constructor !== Object) {
    const clone = Object.create(obj.constructor.prototype);
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        clone[key] = deepClone(obj[key]);
      }
    }
    return clone;
  }

  // Handle plain objects
  const clone: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      clone[key] = deepClone(obj[key]);
    }
  }
  return clone;
}
