import {
  CursorEvent,
  CursorDragEvent,
  CursorZoomEvent,
  CursorHandler,
  CursorDragHandler,
  CursorZoomHandler,
} from './index.js';
import {
  PositionEventData,
  DragEventData,
  ZoomEventData,
  EventModifiers,
} from './index.js';

export class NormalizedInputEventHandlers {
  readonly #onHoverHandler?: CursorHandler;
  readonly #onLeaveHandler?: CursorHandler;
  readonly #onSelectHandler?: CursorHandler;
  readonly #onMoveHandler?: CursorDragHandler;
  readonly #onZoomHandler?: CursorZoomHandler;

  constructor(params: {
    onHoverHandler?: CursorHandler;
    onLeaveHandler?: CursorHandler;
    onSelectHandler?: CursorHandler;
    onMoveHandler?: CursorDragHandler;
    onZoomHandler?: CursorZoomHandler;
  }) {
    this.#onHoverHandler = params.onHoverHandler;
    this.#onLeaveHandler = params.onLeaveHandler;
    this.#onSelectHandler = params.onSelectHandler;
    this.#onMoveHandler = params.onMoveHandler;
    this.#onZoomHandler = params.onZoomHandler;
  }

  handleHover(
    event: CursorEvent,
    ext: PositionEventData & EventModifiers,
  ): void {
    this.#onHoverHandler?.(event, ext);
  }

  handleLeave(
    event: CursorEvent,
    ext: PositionEventData & EventModifiers,
  ): void {
    this.#onLeaveHandler?.(event, ext);
  }

  handleSelect(
    event: CursorEvent,
    ext: PositionEventData & EventModifiers,
  ): void {
    this.#onSelectHandler?.(event, ext);
  }

  handleMove(
    event: CursorDragEvent,
    ext: DragEventData & EventModifiers,
  ): void {
    this.#onMoveHandler?.(event, ext);
  }

  handleZoom(
    event: CursorZoomEvent,
    ext: ZoomEventData & EventModifiers,
  ): void {
    this.#onZoomHandler?.(event, ext);
  }
}
