import { IRenderable } from '@repo/drawing';

export class TextBlock implements IRenderable {
  #opacity: number;
  #text: string;
  #fontSize: number;
  #fontFamily: string;

  constructor(
    params: {},
    options?: {
      initialText?: string;
      opacity?: number;
      fontSize?: number;
      fontFamily?: string;
    },
  ) {
    this.#opacity = options?.opacity ?? 1.0;
    this.#text = options?.initialText ?? '';
    this.#fontSize = options?.fontSize ?? 24;
    this.#fontFamily = options?.fontFamily ?? 'Roboto mono';
  }

  set opacity(value: number) {
    this.#opacity = value;
  }

  set text(value: string) {
    this.#text = value;
  }

  renderTo(ctx: CanvasRenderingContext2D): void {
    // Draw text to the screen
    ctx.save();
    ctx.globalAlpha = this.#opacity;
    ctx.font = `${this.#fontSize}px ${this.#fontFamily}`;
    ctx.fillStyle = 'black';
    ctx.fillText(this.#text, this.#fontSize, this.#fontSize + 30);
    ctx.restore();
  }
}
