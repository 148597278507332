import type { IDeepClone, IDeepEqual } from '@repo/shared';

export class Edges<T = number>
  implements IDeepClone<Edges<T>>, IDeepEqual<Edges<T>>
{
  #top: T;
  #right: T;
  #bottom: T;
  #left: T;

  constructor(top: T, right: T, bottom: T, left: T) {
    this.#top = top;
    this.#right = right;
    this.#bottom = bottom;
    this.#left = left;
  }

  public get top(): T {
    return this.#top;
  }

  public get right(): T {
    return this.#right;
  }

  public get bottom(): T {
    return this.#bottom;
  }

  public get left(): T {
    return this.#left;
  }

  toString(): string {
    return `Edges { t: ${this.#top}, r: ${this.#right}, b: ${this.#bottom}, l: ${this.#left} }`;
  }

  deepClone(): Edges<T> {
    return new Edges(this.top, this.right, this.bottom, this.left);
  }

  deepEqual(other: Edges<T>): boolean {
    return (
      this.top == other.top &&
      this.right == other.right &&
      this.bottom == other.bottom &&
      this.left == other.left
    );
  }
}
