import { IRenderable } from '@repo/drawing';
import { Logger } from '@repo/logger';
import { Database } from '@repo/state-manager';

export class ImagePreview implements IRenderable {
  #img: HTMLImageElement | null = null;
  #loading: boolean = false;
  #loadError: Error | null = null;
  #position: number;
  #size: number;
  #logger: Logger;
  #currentVariant?: string;
  #loadPromise?: Promise<void>;
  #mounted: boolean = true;

  constructor(params: { position: number; size: number }) {
    this.#logger = new Logger('ImagePreview');
    this.#position = params.position;
    this.#size = params.size;
  }

  get image(): HTMLImageElement | null {
    return this.#img;
  }

  get loading(): boolean {
    return this.#loading;
  }

  dispose() {
    this.#mounted = false;
    this.reset();
  }

  reset() {
    this.#img = null;
    this.#loading = false;
    this.#loadError = null;
    this.#currentVariant = undefined;
    this.#loadPromise = undefined;
  }

  setImage(cacheKey: string): void {
    if (!cacheKey) {
      this.#logger.warn('Invalid cache key provided');
      this.reset();
      return;
    }

    // If already loading this variant, don't restart
    if (this.#currentVariant === cacheKey && this.#loadPromise) {
      return;
    }

    this.#currentVariant = cacheKey;
    this.#loadError = null;
    this.#loading = true;

    // Start loading process
    this.#loadPromise = this.loadFromCache(cacheKey).catch((error) => {
      if (this.#mounted) {
        this.#logger.error('Failed to load image:', { error, cacheKey });
        this.#loadError =
          error instanceof Error ? error : new Error(String(error));
        this.#currentVariant = undefined;
      }
    });
  }

  private async loadFromCache(cacheKey: string): Promise<void> {
    try {
      if (!this.#mounted) return;

      const db = Database.getInstance();
      await db.initialize();
      const imageCache = await db.imageCache();

      // const response = await db.imageCache.getData(cacheKey);
      const response = await imageCache.getData(cacheKey);

      if (!response?.buffer || !response?.meta?.type) {
        throw new Error(`Invalid cache data for key: ${cacheKey}`);
      }

      if (!this.#mounted) return;

      const dataUrl = this.arrayBufferToDataUrl(
        response.buffer,
        response.meta.type,
      );
      await this.loadImage(dataUrl);
    } finally {
      if (this.#mounted) {
        this.#loading = false;
      }
    }
  }

  private arrayBufferToDataUrl(buffer: ArrayBuffer, mimeType: string): string {
    const bytes = new Uint8Array(buffer);
    let binary = '';
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]!);
    }
    return `data:${mimeType};base64,${btoa(binary)}`;
  }

  private loadImage(dataUrl: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const img = new Image();

      const cleanup = () => {
        img.onload = null;
        img.onerror = null;
      };

      img.onload = () => {
        if (this.#mounted) {
          this.#img = img;
          this.#loading = false;
          this.#logger.info('Image loaded successfully', {
            position: this.#position,
            width: img.width,
            height: img.height,
          });
        }
        cleanup();
        resolve();
      };

      img.onerror = (event) => {
        cleanup();
        reject(new Error('Failed to load image data'));
      };

      img.src = dataUrl;
    });
  }

  renderTo(ctx: CanvasRenderingContext2D): void {
    const top = this.#position * this.#size;

    if (this.#loadError) {
      this.renderErrorState(ctx, top);
      return;
    }

    if (this.#loading) {
      this.renderLoadingState(ctx, top);
      return;
    }

    if (!this.#img) {
      this.renderEmptyState(ctx, top);
      return;
    }

    this.drawImageTo(ctx, this.#img, top);
  }

  private renderErrorState(ctx: CanvasRenderingContext2D, top: number): void {
    ctx.save();
    ctx.fillStyle = '#FEE';
    ctx.fillRect(0, top, this.#size, this.#size);

    ctx.fillStyle = '#C00';
    ctx.font = '16px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(
      'Failed to load image',
      this.#size / 2,
      top + this.#size / 2 - 12,
    );

    if (this.#loadError?.message) {
      ctx.font = '12px Arial';
      ctx.fillText(
        this.#loadError.message,
        this.#size / 2,
        top + this.#size / 2 + 12,
      );
    }

    ctx.restore();
  }

  private renderLoadingState(ctx: CanvasRenderingContext2D, top: number): void {
    ctx.save();
    ctx.fillStyle = '#EEE';
    ctx.fillRect(0, top, this.#size, this.#size);

    ctx.fillStyle = '#999';
    ctx.font = '24px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText('Loading...', this.#size / 2, top + this.#size / 2);
    ctx.restore();
  }

  private renderEmptyState(ctx: CanvasRenderingContext2D, top: number): void {
    ctx.save();
    ctx.fillStyle = '#FFF';
    ctx.fillRect(0, top, this.#size, this.#size);
    ctx.restore();
  }

  private drawImageTo(
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    top: number,
  ): void {
    ctx.save();
    try {
      const w = img.width;
      const h = img.height;

      if (w === h) {
        ctx.drawImage(img, 0, top, this.#size, this.#size);
        return;
      }

      // Center crop to square
      if (w > h) {
        const delta = w - h;
        ctx.drawImage(
          img,
          delta / 2,
          0, // Source x, y
          h,
          h, // Source width, height
          0,
          top, // Dest x, y
          this.#size,
          this.#size, // Dest width, height
        );
      } else {
        const delta = h - w;
        ctx.drawImage(
          img,
          0,
          delta / 2, // Source x, y
          w,
          w, // Source width, height
          0,
          top, // Dest x, y
          this.#size,
          this.#size, // Dest width, height
        );
      }
    } finally {
      ctx.restore();
    }
  }
}
