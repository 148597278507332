// import { IDeepClone, IDeepEqual } from "@repo/utils";
import { Point } from './point.js';

export class Rect {
  // implements IDeepClone<Rect>, IDeepEqual<Rect>
  #top: number;
  #left: number;
  #width: number;
  #height: number;

  constructor(top: number, left: number, width: number, height: number) {
    this.#top = top;
    this.#left = left;
    this.#width = width;
    this.#height = height;
  }

  get top(): number {
    return this.#top;
  }

  get left(): number {
    return this.#left;
  }

  get width(): number {
    return this.#width;
  }

  get height(): number {
    return this.#height;
  }

  get topLeft(): Point {
    return new Point(this.left, this.top);
  }

  get bottomRight(): Point {
    return new Point(this.left + this.width, this.top + this.height);
  }

  containsPoint(point: Point): boolean {
    const outX = point.x < this.left || point.x > this.left + this.width;
    const outY = point.y < this.top || point.y > this.top + this.height;
    const inRect = !outX && !outY;
    return inRect;
  }

  toString(): string {
    return `Rect { top: ${this.#top}, left: ${this.#left}, width: ${this.#width}, height: ${this.#height} }`;
  }

  deepClone(): Rect {
    return new Rect(this.top, this.left, this.width, this.height);
  }

  deepEqual(other: Rect): boolean {
    return (
      this.top == other.top &&
      this.left == other.left &&
      this.width == other.width &&
      this.height == other.height
    );
  }
}
