import { Designer } from './Designer.js';
import { Orientation, WindowRef } from './common/index.js';
import { Point, Rect } from '@repo/drawing';
import { Logger } from '@repo/logger';

/**
 * Type-safe wrapper for designer identifiers to ensure non-empty strings
 * and provide type checking for designer references.
 */
export type DesignerId = string & { readonly __brand: unique symbol };

export class DesignerIdGenerator {
  private static validateId(id: string): boolean {
    return typeof id === 'string' && id.trim().length > 0;
  }

  public static create(id: string): DesignerId {
    if (!this.validateId(id)) {
      throw new Error('Designer ID must be a non-empty string');
    }
    return id.trim() as DesignerId;
  }

  public static createFromPrefix(prefix: string): DesignerId {
    const id = `${prefix.trim()}-${crypto.randomUUID()}`;
    return this.create(id);
  }
}

/**
 * Type-safe error class for designer registry operations
 */
export class DesignerRegistryError extends Error {
  constructor(
    message: string,
    public readonly designerId: DesignerId,
    public readonly code:
      | 'NOT_FOUND'
      | 'ALREADY_BOUND'
      | 'NOT_BOUND'
      | 'INVALID_STATE',
  ) {
    super(`Designer ${designerId}: ${message}`);
    this.name = 'DesignerRegistryError';
  }
}

export interface DesignerConfig {
  bandSize: Rect;
  orientation?: Orientation;
  dev?: boolean;
  bandId?: string;
}

export interface BindingState {
  boundToElement: boolean;
  boundToWindow: boolean;
  target?: HTMLDivElement;
  windowRef?: WindowRef;
  cleanupHandlers: Array<() => void>;
}

export interface WindowBindingConfig {
  orientation?: Orientation;
  enableOrientationLock?: boolean;
  enableVisibilityHandling?: boolean;
}

export interface RegisteredDesigner {
  id: DesignerId;
  instance: Designer;
  config: DesignerConfig;
  binding: BindingState;
}

export class DesignerRegistry {
  private static instance: DesignerRegistry;
  private designers: Map<DesignerId, RegisteredDesigner>;
  private logger: Logger;

  private constructor() {
    this.designers = new Map();
    this.logger = new Logger('DesignerRegistry');
  }

  public static getInstance(): DesignerRegistry {
    if (!DesignerRegistry.instance) {
      DesignerRegistry.instance = new DesignerRegistry();
    }
    return DesignerRegistry.instance;
  }

  public create(id: DesignerId, config: DesignerConfig): Designer {
    const registration = this.designers.get(id);
    if (registration) {
      this.logger.warn('Designer exists, returning existing instance:', { id });
      return registration.instance;
    }

    this.logger.info('Creating new designer instance:', { id, config });
    // Create designer instance without binding
    const instance = new Designer({
      bandSize: config.bandSize,
      orientation: config.orientation,
      dev: config.dev,
      // bandId: config.bandId,
    });

    const designer: RegisteredDesigner = {
      id,
      instance,
      config,
      binding: {
        boundToElement: false,
        boundToWindow: false,
        cleanupHandlers: [],
      },
    };

    this.designers.set(id, designer);
    this.logger.info('Created new designer instance:', { id });

    return instance;
  }

  public createWithPrefix(prefix: string, config: DesignerConfig): Designer {
    return this.create(DesignerIdGenerator.createFromPrefix(prefix), config);
  }

  private getRegistrationOrThrow(id: DesignerId): RegisteredDesigner {
    const registration = this.designers.get(id);
    if (!registration) {
      throw new DesignerRegistryError('Not found', id, 'NOT_FOUND');
    }
    return registration;
  }

  public async initialize(id: DesignerId): Promise<void> {
    const registration = this.getRegistrationOrThrow(id);

    try {
      await registration.instance.initialize();
      // registration.instance.controller.initialize();

      this.logger.info('Initialized designer:', { id });
    } catch (error) {
      this.logger.error('Failed to initialize designer:', { id, error });
      throw error;
    }
  }

  public bindToElement(id: DesignerId, target: HTMLDivElement): void {
    const registration = this.getRegistrationOrThrow(id);

    if (registration.binding.boundToElement) {
      throw new DesignerRegistryError(
        'Already bound to element',
        id,
        'ALREADY_BOUND',
      );
    }

    try {
      registration.instance.container.bindToElement(target);
      registration.binding.target = target;
      registration.binding.boundToElement = true;

      this.logger.info('Bound designer to element:', { id });
    } catch (error) {
      this.logger.error('Failed to bind designer to element:', { id, error });
      throw error;
    }
  }

  public bindToWindow(
    id: DesignerId,
    windowRef: WindowRef,
    config?: WindowBindingConfig,
  ): void {
    const registration = this.getRegistrationOrThrow(id);

    if (registration.binding.boundToWindow) {
      throw new DesignerRegistryError(
        'Already bound to window',
        id,
        'ALREADY_BOUND',
      );
    }

    if (!registration.binding.boundToElement) {
      throw new DesignerRegistryError(
        'Must be bound to element first',
        id,
        'INVALID_STATE',
      );
    }

    try {
      registration.instance.container.bindToWindow(windowRef, {
        enableResizeHandling: true,
        enableWindowEvents: true,
      });

      registration.binding.windowRef = windowRef;
      registration.binding.boundToWindow = true;

      this.logger.info('Bound designer to window:', { id });
    } catch (error) {
      this.logger.error('Failed to bind designer to window:', { id, error });
      throw error;
    }
  }

  public unbind(id: DesignerId): void {
    const registration = this.getRegistrationOrThrow(id);

    try {
      // Clean up all event listeners
      registration.binding.cleanupHandlers.forEach((cleanup) => {
        try {
          cleanup();
        } catch (error) {
          this.logger.warn('Cleanup handler failed:', { id, error });
        }
      });
      registration.binding.cleanupHandlers = [];

      // Reset binding state
      registration.binding.boundToElement = false;
      registration.binding.boundToWindow = false;
      registration.binding.windowRef = undefined;

      // Replace the target with a temporary div
      if (registration.binding.target) {
        const tempDiv = document.createElement('div');
        registration.binding.target.replaceWith(tempDiv);
        registration.binding.target = undefined;
      }

      this.logger.info('Unbound designer:', { id });
    } catch (error) {
      this.logger.error('Failed to unbind designer:', { id, error });
      throw new DesignerRegistryError('Failed to unbind', id, 'INVALID_STATE');
    }
  }

  public remove(id: DesignerId): void {
    const registration = this.designers.get(id);
    if (registration) {
      // Unbind first if needed
      if (
        registration.binding.boundToElement ||
        registration.binding.boundToWindow
      ) {
        this.unbind(id);
      }

      // Remove from registry
      this.designers.delete(id);
      this.logger.info('Removed designer:', { id });
    }
  }

  private setupWindowBinding(
    registration: RegisteredDesigner,
    windowRef: WindowRef,
    bindingConfig: WindowBindingConfig,
  ): void {
    const cleanupHandlers = registration.binding.cleanupHandlers;

    // Handle resize events
    const resizeHandler = () => {
      this.handleResize(registration, windowRef);
    };
    windowRef.onresize = resizeHandler;
    // windowRef.addEventListener('resize', resizeHandler);
    cleanupHandlers.push(() =>
      windowRef.removeEventListener('resize', resizeHandler),
    );

    // Handle orientation changes if supported
    if ('screen' in windowRef && windowRef.screen?.orientation) {
      try {
        const orientationHandler = (e: Event) => {
          this.handleOrientationChange(registration, windowRef);
        };
        windowRef.screen.orientation.addEventListener(
          'change',
          orientationHandler,
        );
        cleanupHandlers.push(() => {
          windowRef.screen?.orientation?.removeEventListener(
            'change',
            orientationHandler,
          );
        });

        // Initial orientation setup
        this.handleOrientationChange(registration, windowRef);

        // Lock orientation if requested
        if (bindingConfig.enableOrientationLock && bindingConfig.orientation) {
          // windowRef.screen.orientation
          //   .lock(bindingConfig.orientation)
          //   .catch(err => this.logger.warn('Failed to lock orientation:', { err }));
        }
      } catch (error) {
        this.logger.warn('Screen orientation API not available:', { error });
      }
    }

    // Handle visibility changes if enabled
    if (bindingConfig.enableVisibilityHandling) {
      const visibilityHandler = () => {
        this.handleVisibilityChange(registration, windowRef);
      };
      windowRef.document.addEventListener(
        'visibilitychange',
        visibilityHandler,
      );
      cleanupHandlers.push(() => {
        windowRef.document.removeEventListener(
          'visibilitychange',
          visibilityHandler,
        );
      });
    }
  }

  private handleOrientationChange(
    registration: RegisteredDesigner,
    windowRef: WindowRef,
  ): void {
    const orientation =
      windowRef.innerWidth > windowRef.innerHeight ? 'landscape' : 'portrait';
    registration.instance.container.orientation = orientation;
  }

  private handleResize(
    registration: RegisteredDesigner,
    windowRef: WindowRef,
  ): void {
    // TODO(pbirch): Maybe move window dep out of container up to here...?
    // registration.instance.container.handleResize(windowRef.innerWidth, windowRef.innerHeight);
  }

  private handleVisibilityChange(
    registration: RegisteredDesigner,
    windowRef: WindowRef,
  ): void {
    if (windowRef.document.hidden) {
      this.logger.debug('Document hidden, pausing designer:', {
        id: registration.id,
      });
      // Future: Add pause rendering logic
    } else {
      this.logger.debug('Document visible, resuming designer:', {
        id: registration.id,
      });
      // Future: Add resume rendering logic
    }
  }

  public getInstance(id: DesignerId): Designer | undefined {
    return this.designers.get(id)?.instance;
  }

  public getRegistration(id: DesignerId): RegisteredDesigner | undefined {
    return this.designers.get(id);
  }

  public getRegisteredIds(): DesignerId[] {
    return Array.from(this.designers.keys());
  }
}

// Export a default instance
export const designerRegistry = DesignerRegistry.getInstance();
