import { Point } from '@repo/drawing';

const DEFAULT_MAX_SIZE_MB = 20;

interface ImageSelectorParams {
  position?: Point;
  onCancel?: (event: Event) => void;
  onClose?: (event: Event) => void;
  onFileChosen?: (event: Event, file: File) => void;
  acceptedTypes?: string[];
  maxSizeMB?: number;
}

interface ImageSelectorError extends Error {
  code:
    | 'size_exceeded'
    | 'invalid_type'
    | 'no_file_selected'
    | 'selector_exists';
}

const validateFile = (
  file: File,
  acceptedTypes: string[],
  maxSizeMB: number,
): void => {
  if (!acceptedTypes.includes(file.type)) {
    const error = new Error(
      `Invalid file type. Please select: ${acceptedTypes.join(', ')}`,
    ) as ImageSelectorError;
    error.code = 'invalid_type';
    throw error;
  }

  if (file.size > maxSizeMB * 1024 * 1024) {
    const error = new Error(
      `File size must be less than ${maxSizeMB}MB`,
    ) as ImageSelectorError;
    error.code = 'size_exceeded';
    throw error;
  }
};

const createImageSelectorError = (
  message: string,
  code: ImageSelectorError['code'],
): ImageSelectorError => {
  const error = new Error(message) as ImageSelectorError;
  error.code = code;
  return error;
};

export const showImageSelector = async ({
  position,
  onCancel,
  onClose,
  onFileChosen,
  acceptedTypes = ['image/jpeg', 'image/png', 'image/gif'],
  maxSizeMB = DEFAULT_MAX_SIZE_MB,
}: ImageSelectorParams): Promise<File | undefined> => {
  const SELECTOR_ID = 'designer_image_selector';

  // Check for existing selector
  const existing = document.getElementById(SELECTOR_ID);
  if (existing) {
    existing.remove();
  }

  return new Promise<File | undefined>((resolve) => {
    const input = document.createElement('input');

    const cleanup = (value?: File) => {
      input.value = '';
      input.remove();
      resolve(value);
    };

    // Configure input
    Object.assign(input, {
      id: SELECTOR_ID,
      type: 'file',
      accept: acceptedTypes.join(','),
    });

    const p = position ?? new Point(0, 0);

    // Set styles
    Object.assign(input.style, {
      display: 'none',
      position: 'absolute',
      left: `${p.x}px`,
      top: `${p.y}px`,
    });

    // Handle cancel
    input.addEventListener('cancel', (e) => {
      console.warn('[ImageSelector] Cancel:', input.files);
      onCancel?.(e);
      cleanup();
    });

    // Handle close
    input.addEventListener('close', (e) => {
      console.warn('[ImageSelector] Close:', input.files);
      onClose?.(e);
      cleanup();
    });

    // Handle file selection
    input.addEventListener('change', (e) => {
      console.warn('[ImageSelector] Change:', input.files);

      try {
        const files = input.files;
        if (!files?.length) {
          throw createImageSelectorError(
            'No file selected',
            'no_file_selected',
          );
        }

        if (files.length === 0) return;
        const file = files[0]!;
        validateFile(file, acceptedTypes, maxSizeMB);

        onFileChosen?.(e, file);
        cleanup(file);
      } catch (error) {
        console.error('[ImageSelector] Error:', error);
        cleanup();
        throw error;
      }
    });

    document.body.appendChild(input);
    input.click();
  });
};
