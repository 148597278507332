import { Orientation } from './common/index.js';
import { Container } from './Container.js';
import { Controller } from './Controller.js';
import { Renderer } from './Renderer.js';
import {
  createAppState,
  MutableAppState,
  PersistedAppState,
} from './AppState.js';
import { Logger } from '@repo/logger';
import { ImageBufferUtils } from '@repo/shared';
import { Rect } from '@repo/drawing';

export interface DesignerParams {
  bandSize: Rect;
  orientation?: Orientation;
  // bandId?: string; // Optional band ID to load
  appState?: PersistedAppState;
  dev?: boolean;
}

export class Designer {
  #logger: Logger;
  #appState: MutableAppState;
  #renderer: Renderer;
  #controller: Controller;
  #container: Container;
  #params: DesignerParams;

  constructor(params: DesignerParams) {
    this.#params = params;
    this.#logger = new Logger('Designer');

    this.#appState = createAppState(params.bandSize);
    // const appState = createAppState(params.bandSize);

    // // Create core state with minimal configuration
    // this.#appState = {
    //   ...params.appState,
    //   ...appState,
    // };
    // this.#logger.info('Created app state:', {
    //   appState,
    //   params: this.#params.appState,
    //   combined: this.#appState,
    // });

    // Initialize container without DOM binding
    this.#container = new Container({
      bandSize: params.bandSize,
      orientation: params.orientation,
      dev: params.dev,
    });

    // Create renderer with app state
    this.#renderer = new Renderer(this.#appState);

    // Initialize controller with minimal setup
    this.#controller = new Controller({
      appState: this.#appState,
      container: this.#container,
      renderer: this.#renderer,
    });

    // Need to wait until the window ref is bound to access the performance API
    // this.#container.startRenderLoop();
  }

  get container(): Container {
    return this.#container;
  }
  get controller(): Controller {
    return this.#controller;
  }
  get renderer(): Renderer {
    return this.#renderer;
  }

  async initialize(): Promise<void> {
    this.#logger.info('Initializing designer');

    try {
      // if (!this.controller.state.band) {
      //   await this.#controller.initializeNewBand();
      // }
      // if (this.#params.appState) {
      //   this.#logger.info('Loading controller with initial state:', {
      //     appState: this.#params.appState,
      //   });
      //   // this.#controller.state = this.#params.appState;
      //   // await this.#controller.loadBand(this.#params.bandId);
      // } else {
      //   await this.#controller.initializeNewBand();
      // }
      // if (this.#params.bandId) {
      //   this.#logger.info('Loading band:', { bandId: this.#params.bandId });
      //   await this.#controller.loadBand(this.#params.bandId);
      // } else {
      //   await this.#controller.initializeNewBand();
      // }
    } catch (error) {
      this.#logger.error('Failed to initialize designer:', error);
      throw error;
    }
  }

  // async reset(): Promise<void> {
  //   this.#logger.info('Resetting designer');
  //   const state = this.#controller.state;
  //   for (var i = 0; i < state.selectedWorkflows.length; i++) {
  //     state.selectedWorkflows[i] = undefined;
  //   }
  //   this.#renderer.applyState(state);
  //   // this.#controller.state.selectedWorkflows
  //   // await AppStateHelpers.reset(this.#appState);
  //   this.#controller.render();
  //   // this.#controller.state.selectedWorkflows;
  // }

  async reset(): Promise<void> {
    await this.controller.reset();
    // this.#logger.info('Resetting designer');

    // // Clear all selected workflows
    // // await AppStateHelpers.reset(this.#appState);
    // for (var i = 0; i < this.#appState.selectedWorkflows.length; i++) {
    //   this.#appState.selectedWorkflows[i] = undefined;
    // }

    // // Clear active workflows map
    // this.#appState.activeWorkflows.clear();

    // // Reset any preview mode state
    // this.#appState.previewMode = false;

    // // Clear cursor and hover states
    // this.#appState.cursorPosition = undefined;
    // this.#appState.hoveredIndex = undefined;
    // this.#appState.selectedIndex = undefined;

    // // Clear any snap engine state
    // this.#appState.snapEngine = undefined;
    // this.#appState.snapResult = undefined;

    // // Clear any error state
    // this.#appState.lastError = undefined;

    // // Trigger re-render through controller
    // this.#controller.render();
  }

  // async reset(): Promise<void> {
  //   this.#logger.info('Resetting designer');

  //   // Create a root to batch state updates
  //   createRoot(() => {
  //     // Clear all selected workflows - ensure we modify the array reactively
  //     AppStateHelpers.reset(this.#appState);

  //     // Clear active workflows map using a fresh Map to trigger reactivity
  //     this.#appState.activeWorkflows = new Map();

  //     // Batch reset all state properties
  //     Object.assign(this.#appState, {
  //       previewMode: false,
  //       cursorPosition: undefined,
  //       hoveredIndex: undefined,
  //       selectedIndex: undefined,
  //       snapEngine: undefined,
  //       snapResult: undefined,
  //       lastError: undefined
  //     });

  //     // Force controller render to update the view
  //     this.#controller.render();
  //   });
  // }

  async getPreviewImage(): Promise<HTMLImageElement> {
    this.#logger.info('Get JPG buffer');
    if (!this.#container.buffer) {
      this.#logger.error('No buffer available');
      throw new Error('No buffer available');
    }
    this.controller.state.previewMode = true;
    this.controller.render();
    const image = await ImageBufferUtils.imageFromCanvas(
      this.#container.buffer,
    );
    this.controller.state.previewMode = false;
    this.controller.render();
    // const buffer = await canvasToJpgArrayBuffer(this.#container.buffer);
    // const image = await imageFromBuffer(buffer, 'image/jpeg');
    return image;
    // return this.#container.buffer.
    // return this.#container.getPreviewImage();
  }
}
