import { IDB, imagecache, filecache, imagestore } from '@repo/database';

export const DEFAULT_DB_NAME = 'bandstate2';
export const DEFAULT_IMAGESTORE_PREFIX = 'imagestore';

/**
 * @file Database.ts
 * Database singleton
 *  - fileCache: FileCache
 * - imageCache: ImageCache
 * - database: IDB
 * - initialized: boolean
 * @export
 * @class Database
 * @example
 * const database = Database.getInstance();
 * await database.initialize();
 * console.log(database.isInitialized);
 * // Output: true
 * console.log(database.db);
 * // Output: IDB { ... }
 * console.log(database.images);
 * // Output: ImageCache { ... }
 * console.log(database.files);
 * // Output: FileCache { ... }
 *
 */
export class Database {
  private static instance: Database;
  #database: IDB;
  #fileCache: filecache.DefaultFileCache<undefined>;
  #imageCache: imagecache.ImageCache;
  #imageStore: imagestore.ImageStore;
  private initialized: boolean = false;

  private constructor() {
    [this.#fileCache, this.#imageCache, this.#imageStore, this.#database] =
      this.configDatabase();
  }

  static getInstance(): Database {
    if (!Database.instance) {
      Database.instance = new Database();
      Database.instance.initialize();
    }
    return Database.instance;
  }

  private configDatabase(): [
    filecache.DefaultFileCache<undefined>,
    imagecache.ImageCache,
    imagestore.ImageStore,
    IDB,
  ] {
    const fileCache = new filecache.DefaultFileCache<undefined>({
      prefix: 'filecache',
      maxSizeBytes: 400 * 1024 * 1024,
      defaultExpirationDays: undefined,
      chunkSize: 512 * 1024,
      resetDatabase: false,
    });

    const imageCache = new imagecache.ImageCache({
      prefix: 'imgcache',
      maxSizeBytes: 400 * 1024 * 1024,
      defaultExpirationDays: undefined,
      chunkSize: 512 * 1024,
      resetDatabase: false,
    });

    const imageStore = new imagestore.ImageStore(
      {
        prefix: DEFAULT_IMAGESTORE_PREFIX,
      },
      imageCache,
    );

    const database = new IDB(
      {
        version: 1,
        dbName: DEFAULT_DB_NAME,
        resetOnInit: false,
      },
      [fileCache, imageCache, imageStore],
    );

    return [fileCache, imageCache, imageStore, database];
  }

  async initialize(): Promise<void> {
    if (this.initialized) return;
    await this.#database.whenReady();
    this.initialized = true;
  }

  get isInitialized(): boolean {
    return this.initialized;
  }

  get db(): IDB {
    return this.#database;
  }

  async imageCache(): Promise<imagecache.ImageCache> {
    await this.initialize();
    return this.#imageCache;
  }

  async fileCache(): Promise<filecache.DefaultFileCache<undefined>> {
    await this.initialize();
    return this.#fileCache;
  }

  async imageStore(): Promise<imagestore.ImageStore> {
    await this.initialize();
    return this.#imageStore;
  }

  // get imageCache(): imagecache.ImageCache {
  //   return this.#imageCache;
  // }

  // get fileCache(): filecache.DefaultFileCache<undefined> {
  //   return this.#fileCache;
  // }

  // get imageStore(): imagestore.ImageStore {
  //   return this.#imageStore;
  // }
}
