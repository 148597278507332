import { Point, Rect } from '@repo/drawing';
import { SnapEngine } from '@repo/snap-engine';
import { SnapResult } from '@repo/snap-engine/types';
import { ImageWorkflow } from './ImageWorkflowManager.js';
// import { DesignLayouts, SerializedLayout } from './Layouts';
import {
  // BandInstance,
  // OG7LayoutV1,
  imagestore,
} from '@repo/database';

export interface DebugFlags {
  snapVisualizerEnabled: boolean;
  showSnapPoints: boolean;
  showGrid: boolean;
  showGuides: boolean;
  showSpatialHash: boolean;
  showLabels: boolean;
  showCursor: boolean;
  showStats: boolean;
  showCacheMetrics: boolean;
  showFps: boolean;
}

const defaultDebugFlags: DebugFlags = {
  snapVisualizerEnabled: false,
  showSnapPoints: false,
  showGrid: false,
  showGuides: false,
  showSpatialHash: false,
  showLabels: false,
  showCursor: false,
  showStats: false,
  showCacheMetrics: false,
  showFps: false,
};

// function defaultBand(): imagestore.BandInstance {
//   const band = {
//     id: crypto.randomUUID(),
//     type: {
//       type: 'og7',
//       version: 1,
//       data: Array(7).fill(undefined),
//     } as imagestore.OG7LayoutV1,
//     name: '',
//     description: '',
//     secret: '',
//     created: Date.now(),
//     lastAccessed: Date.now(),
//     lastUpdated: Date.now(),
//     isPrivate: true,
//     tags: [],
//     categories: [],
//     metadata: {},
//   } as imagestore.BandInstance;
//   return band;
// }

// Base internal state structure that implements both readonly and mutable interfaces
class AppStateImpl implements ReadonlyAppState, MutableAppState {
  constructor(
    public readonly bandSize: Rect,
    // General debug flags
    public debugFlags: DebugFlags = defaultDebugFlags,
    // Interaction state
    public previewMode: boolean = false, // false,
    public cursorPosition?: Point,
    public hoveredIndex?: number,
    public selectedIndex?: number,
    // Snap engine state
    public snapEngine?: SnapEngine,
    public snapResult?: SnapResult,
    // Performance metrics
    public fps: number = 0,
    // Cache metrics
    public cachedImages: number = 0,
    public cacheSize: number = 0,
    public cacheProgress: number = 0,
    // Band state
    public band?: imagestore.BandInstance,
    public bandLayout?: imagestore.SerializedLayout,
    // public template: DesignLayouts = 'og7',
    // public bandId: string = crypto.randomUUID(),
    // public bandName: string = '',
    // public bandDescription: string = '',
    // public bandSecret: string = '',

    // Workflow state
    public activeWorkflows: Map<string, ImageWorkflow> = new Map(),
    public selectedWorkflows: Array<string | undefined> = Array(7).fill(
      undefined,
    ),
    // public selectedWorkflow: string,
    // Error reporting
    public lastError?: Error,
  ) {}
}

// Factory function that returns both readonly and mutable views of the same instance
export function createAppState(bandSize: Rect): MutableAppState {
  return new AppStateImpl(bandSize);
}

// Read-only interfaces remain the same
export interface ReadonlyDebugFlags {
  readonly debugFlags: DebugFlags;
}

export interface ReadonlyInteractionState {
  readonly previewMode: boolean;
  readonly cursorPosition?: Point;
  readonly hoveredIndex?: number;
  readonly selectedIndex?: number;
}

export interface ReadonlySnapEngineState {
  readonly snapEngine?: SnapEngine;
  readonly snapResult?: SnapResult;
}

export interface ReadonlyPerformanceMetrics {
  readonly fps: number;
}

export interface ReadonlyCacheMetrics {
  readonly cachedImages: number;
  readonly cacheSize: number;
  readonly cacheProgress: number;
}

export interface ReadonlyWorkflowState {
  // readonly template: DesignLayouts;
  readonly band?: imagestore.BandInstance;
  readonly bandLayout?: imagestore.SerializedLayout;
  readonly activeWorkflows: Map<string, ImageWorkflow>;
  readonly selectedWorkflows: Array<string | undefined>;
  // readonly selectedWorkflows?: [];
}

export interface ReadonlyErrorState {
  readonly lastError?: Error;
}

export interface ReadonlyAppState
  extends ReadonlyDebugFlags,
    ReadonlyInteractionState,
    ReadonlySnapEngineState,
    ReadonlyPerformanceMetrics,
    ReadonlyCacheMetrics,
    ReadonlyWorkflowState,
    ReadonlyErrorState {
  readonly bandSize: Rect;
}

export interface PersistedAppState {
  bandSize: Rect;
  debugFlags: DebugFlags;
  previewMode: boolean;
  selectedIndex?: number;
  band?: imagestore.BandInstance;
  bandLayout?: imagestore.SerializedLayout;
}

export function toPersistedState(state: ReadonlyAppState): PersistedAppState {
  return {
    bandSize: state.bandSize,
    debugFlags: state.debugFlags,
    previewMode: state.previewMode,
    selectedIndex: state.selectedIndex,
    band: state.band,
    bandLayout: state.bandLayout,
  };
}

// Mutable interfaces with direct property access
export interface MutableAppState extends ReadonlyAppState, PersistedAppState {
  readonly bandSize: Rect;
  // General debug flags
  debugFlags: DebugFlags;
  // Interaction state
  previewMode: boolean;
  cursorPosition?: Point;
  hoveredIndex?: number;
  selectedIndex?: number;
  // Snap engine state
  snapEngine?: SnapEngine;
  snapResult?: SnapResult;
  // Performance metrics
  fps: number;
  // Cache metrics
  cachedImages: number;
  cacheSize: number;
  cacheProgress: number;
  // Band state
  band?: imagestore.BandInstance;
  bandLayout?: imagestore.SerializedLayout;
  // Workflow state
  activeWorkflows: Map<string, ImageWorkflow>;
  selectedWorkflows: Array<string | undefined>;
  // selectedWorkflow?: string; // Currently selected workflow ID
  // Error reporting
  lastError?: Error;
}

// Helper functions now work with the unified state object
export const AppStateHelpers = {
  clearInteractionState(state: MutableAppState): void {
    // state.band = defaultBand();
    state.previewMode = false;
    state.cursorPosition = undefined;
    state.hoveredIndex = undefined;
    state.selectedIndex = undefined;
    state.snapEngine = undefined;
    state.snapResult = undefined;
  },

  updateCacheMetrics(
    state: MutableAppState,
    images: number,
    size: number,
  ): void {
    state.cachedImages = images;
    state.cacheSize = size;
  },

  // reset(state: MutableAppState) {
  //   for (var i = 0; i < state.selectedWorkflows.length; i++) {
  //     state.selectedWorkflows[i] = undefined;
  //   }
  // },

  hasSelection(state: ReadonlyAppState): boolean {
    return state.selectedIndex !== undefined;
  },

  hasHover(state: ReadonlyAppState): boolean {
    return state.hoveredIndex !== undefined;
  },

  hasCursor(state: ReadonlyAppState): boolean {
    return state.cursorPosition !== undefined;
  },
};
